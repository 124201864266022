/**
 * @fileoverview gRPC-Web generated client stub for cyberia.grpc.user
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as Common_pb from '../Common_pb';
import * as security_EmployeePositionService_pb from '../security/EmployeePositionService_pb';


export class EmployeePositionServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorgetAll = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.user.EmployeePositionService/getAll',
    grpcWeb.MethodType.UNARY,
    Common_pb.Empty,
    Common_pb.ObjectsId,
    (request: Common_pb.Empty) => {
      return request.serializeBinary();
    },
    Common_pb.ObjectsId.deserializeBinary
  );

  getAll(
    request: Common_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.ObjectsId>;

  getAll(
    request: Common_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void): grpcWeb.ClientReadableStream<Common_pb.ObjectsId>;

  getAll(
    request: Common_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.user.EmployeePositionService/getAll',
        request,
        metadata || {},
        this.methodDescriptorgetAll,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.user.EmployeePositionService/getAll',
    request,
    metadata || {},
    this.methodDescriptorgetAll);
  }

  methodDescriptorinsert = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.user.EmployeePositionService/insert',
    grpcWeb.MethodType.UNARY,
    security_EmployeePositionService_pb.EmployeePosition,
    security_EmployeePositionService_pb.EmployeePosition,
    (request: security_EmployeePositionService_pb.EmployeePosition) => {
      return request.serializeBinary();
    },
    security_EmployeePositionService_pb.EmployeePosition.deserializeBinary
  );

  insert(
    request: security_EmployeePositionService_pb.EmployeePosition,
    metadata: grpcWeb.Metadata | null): Promise<security_EmployeePositionService_pb.EmployeePosition>;

  insert(
    request: security_EmployeePositionService_pb.EmployeePosition,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: security_EmployeePositionService_pb.EmployeePosition) => void): grpcWeb.ClientReadableStream<security_EmployeePositionService_pb.EmployeePosition>;

  insert(
    request: security_EmployeePositionService_pb.EmployeePosition,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: security_EmployeePositionService_pb.EmployeePosition) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.user.EmployeePositionService/insert',
        request,
        metadata || {},
        this.methodDescriptorinsert,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.user.EmployeePositionService/insert',
    request,
    metadata || {},
    this.methodDescriptorinsert);
  }

  methodDescriptorgetInfo = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.user.EmployeePositionService/getInfo',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectsId,
    security_EmployeePositionService_pb.EmployeePositionInfo,
    (request: Common_pb.ObjectsId) => {
      return request.serializeBinary();
    },
    security_EmployeePositionService_pb.EmployeePositionInfo.deserializeBinary
  );

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null): Promise<security_EmployeePositionService_pb.EmployeePositionInfo>;

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: security_EmployeePositionService_pb.EmployeePositionInfo) => void): grpcWeb.ClientReadableStream<security_EmployeePositionService_pb.EmployeePositionInfo>;

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: security_EmployeePositionService_pb.EmployeePositionInfo) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.user.EmployeePositionService/getInfo',
        request,
        metadata || {},
        this.methodDescriptorgetInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.user.EmployeePositionService/getInfo',
    request,
    metadata || {},
    this.methodDescriptorgetInfo);
  }

  methodDescriptordelete = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.user.EmployeePositionService/delete',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectsId,
    Common_pb.ObjectsId,
    (request: Common_pb.ObjectsId) => {
      return request.serializeBinary();
    },
    Common_pb.ObjectsId.deserializeBinary
  );

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.ObjectsId>;

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void): grpcWeb.ClientReadableStream<Common_pb.ObjectsId>;

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.user.EmployeePositionService/delete',
        request,
        metadata || {},
        this.methodDescriptordelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.user.EmployeePositionService/delete',
    request,
    metadata || {},
    this.methodDescriptordelete);
  }

}

