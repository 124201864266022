import { Button, Result } from 'antd';
import { ResultStatusType } from 'antd/lib/result';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { kratosClient } from '../../shared';
import './error.page.less';

export const ErrorPage = () => {

	const [searchParams] = useSearchParams();
	const [subtitle, setSubtitle] = useState<string>('');
	const [status, setStatus] = useState<ResultStatusType>(searchParams.get('status') as ResultStatusType || 'error');
	const [title, setTitle] = useState<string>(searchParams.get('title') || '');

	const errors = [403, 404, 500];

	const id = searchParams.get('id');

	useEffect(() => {
		if (id) {
			kratosClient.getSelfServiceError(id).then(({ data }: any) => {
				console.log(data);
				setSubtitle(data?.error?.reason);
				if (errors.includes(data?.error?.code))
					setStatus(data?.error?.code as ResultStatusType);
				else
					setStatus('error');
				setTitle(data?.error?.status);
			});
		}
	}, []);

	const titles = {
		'403': {
			title: 'Доступ запрещен',
		},
		'404': {
			title: 'Страница не найдена',
		},
		'500': {
			title: 'Ошибка сервера',
		},
		'success': {
			title: 'Успешно',
		},
		'error': {
			title: 'Ошибка',
		},
		'info': {
			title: null,
		},
		'warning': {
			title: null,
		},
	};

	return (
		<div className="result-page">
			<Result
				status={status}
				title={title || titles[status]?.title}
				subTitle={subtitle}
				extra={<Button type="primary" href="/">Назад</Button>}
			/>
		</div>
	);
};