import { Button, Col, Form, Input, message, Row, Select, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { MaskedInput } from 'antd-mask-input';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import { LockIcon, UserAvatarIcon } from 'icons';
import { Credentials, Empty, File as FileObj, Time } from 'proto/Common_pb';
import { Farm } from 'proto/farm/FarmService_pb';
import { FarmServiceClient } from 'proto/farm/FarmServiceServiceClientPb';
import { EmployeePosition } from 'proto/security/EmployeePositionService_pb';
import { EmployeePositionServiceClient } from 'proto/security/EmployeePositionServiceServiceClientPb';
import { UserFarmRoles, UserFarmRolesCollection, UserRegRequest } from 'proto/security/UserRegRequestService_pb';
import { UserRegRequestServiceClient } from 'proto/security/UserRegRequestServiceServiceClientPb';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { domain, errorUrl } from 'shared';
import './registration.form.less';
import { FormValues } from './registration.form.types';

const normFile = (e: any) => {
	console.log('Upload event:', e);
	if (Array.isArray(e)) {
		return e;
	}
	return e?.fileList;
};

const getFileId = async (fileObject: any) => {
	const formData = new FormData();
	formData.append('file', fileObject);
	const response = await fetch(`https://${domain}/http/upload_file`, { method: 'POST', body: formData });
	const data = await response.json();
	if (response.status !== 200)
		throw new Error(data.message);
	return data.id;
};

const getFarms = async () => {
	const client = new FarmServiceClient(`https://${domain}/api`);
	const farmIds = await client.getAll(new Empty(), {}).catch(console.error);
	if (!farmIds)
		return [];
	const farmsInfo = await client.getInfo(farmIds, {}).catch(console.error);
	return farmsInfo?.getFarmsList() || [];
};

const getEmployeePositions = async () => {
	const client = new EmployeePositionServiceClient(`https://${domain}/api`);
	const employeePositions = await client.getAll(new Empty(), {}).catch(console.error);
	if (!employeePositions)
		return [];
	const employeePositionInfo = await client.getInfo(employeePositions, {}).catch(console.error);
	return employeePositionInfo?.getPositionsinfoList() || [];
};

export const RegistrationForm = () => {
	const [farms, setFarms] = useState<Farm[]>([]);
	const [employeePositions, setEmployeePositions] = useState<EmployeePosition[]>([]);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [farmsSelectValues, setFarmsSelectValues] = useState<number[]>([]);
	const navigate = useNavigate();

	useEffect(() => {
		getFarms().then(farms => setFarms(farms));
		getEmployeePositions().then(employeePositions => setEmployeePositions(employeePositions));
	}, []);

	const onChange = ({ fileList: newFileList }: any) => {
		setFileList(newFileList);
		console.log(newFileList);
	};

	const onPreview = async (file: UploadFile) => {
		let src = file.url as string;
		if (!src) {
			src = await new Promise(resolve => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj as RcFile);
				reader.onload = () => resolve(reader.result as string);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow?.document.write(image.outerHTML);
	};

	const onFinish = async (values: FormValues) => {
		console.log('Domain: ', domain);
		console.log('Received values of form: ', values);
		const client = new UserRegRequestServiceClient(`https://${domain}/api`);
		const userRegRequest = new UserRegRequest();

		const credentials = new Credentials();
		credentials.setUser(values.email);
		credentials.setPassword(values.password);

		userRegRequest.setCredentials(credentials);
		userRegRequest.setName(values.name);
		userRegRequest.setSecondname(values.secondName);
		userRegRequest.setThirdname(values.thirdName);
		userRegRequest.setEmail(values.email);
		userRegRequest.setPhonenumber(values.phone);
		userRegRequest.setAddress(values.address);

		const isPhoneUnique = await client.checkPhoneUniq(userRegRequest, {}).catch(console.error);
		const isEmailUnique = await client.checkEmailUniq(userRegRequest, {}).catch(console.error);

		if (!isPhoneUnique?.getSuccess() || !isEmailUnique?.getSuccess()) {
			if (!isPhoneUnique?.getSuccess())
				message.error('Такой номер телефона уже существует');
			if (!isEmailUnique?.getSuccess())
				message.error('Такой email уже существует');
			return;
		}

		const avatarFileId = await getFileId(fileList[0]?.originFileObj);
		const file = new FileObj();
		file.setId(avatarFileId);
		userRegRequest.setPhoto(file);

		const position = employeePositions.find(position => position.getId() === values.position);
		userRegRequest.setPosition(position);

		const farmsAndRoles = new UserFarmRolesCollection();
		const selectedFarms = farms.filter(farm => farmsSelectValues?.includes(farm.getId()));
		const userFarmsRoles = selectedFarms.map(farm => {
			const userFarmRoles = new UserFarmRoles();
			userFarmRoles.setFarm(farm);
			userFarmRoles.setRolesList([]);
			return userFarmRoles;
		});
		farmsAndRoles.setUserfarmrolesList(userFarmsRoles);
		userRegRequest.setFarmsandroles(farmsAndRoles);

		const currentTime = new Time();
		currentTime.setUnixtime(Date.now());
		userRegRequest.setRegdate(currentTime);

		const response = await client.insert(userRegRequest, {}).catch(console.error);
		console.log(response);
		if (response) {
			navigate(`${errorUrl}?status=success&title=Запрос на регистрацию успешно отправлен`);
		}
	};

	const phoneMask = '+7 (000) 000-00-00';

	const mask = React.useMemo(
		() => [
			{
				mask: phoneMask,
				lazy: false,
			},
		],
		[],
	);

	const selectAllFarms = () => {
		setFarmsSelectValues(farms.map(farm => farm.getId()));
	};

	useEffect(() => {
		console.log(farmsSelectValues);
	}, [farmsSelectValues]);

	const handleChange = (value: number[], option: any) => {
		console.log(value, option);
		setFarmsSelectValues(value);
		return value;
	};

	return (
		<div className="main-registration-div">
			<h2 className="dark-green-text">Запрос на регистрацию</h2>
			<Form layout="vertical" onFinish={onFinish}>
				<Row>
					<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className="col-register">
						<Form.Item name="secondName" label="Фамилия" rules={[{ required: true }]}>
							<Input/>
						</Form.Item>
						<Form.Item name="name" label="Имя" rules={[{ required: true }]}>
							<Input/>
						</Form.Item>
						<Form.Item name="thirdName" label="Отчество" rules={[{ required: true }]}>
							<Input/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className="col-register col-avatar">
						<Form.Item name="avatar" valuePropName="fileList" getValueFromEvent={normFile}>
							<ImgCrop rotate>
								<Upload name="avatar" listType="picture-card" beforeUpload={() => false}
								        onChange={onChange} onPreview={onPreview}>
									{fileList.length >= 1 ? null : <UserAvatarIcon/>}
								</Upload>
							</ImgCrop>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className="col-register">
						<Form.Item name="email" label="Email"
						           rules={[{
							           required: true,
							           message: 'Пожалуйста, введите ваш email!',
						           }, { type: 'email' }]}>
							<Input/>
						</Form.Item>
						<Form.Item name="password" label="Пароль"
						           rules={[{ required: true, message: 'Пожалуйста, введите ваш пароль!' }]}>
							<Input.Password placeholder="Пароль" prefix={<LockIcon/>}/>
						</Form.Item>
						<Form.Item name="position" label="Должность">
							<Select defaultValue="">
								{employeePositions?.map(position => (
									<Select.Option value={position.getId()}>{position.getTitle()}</Select.Option>))}
							</Select>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className="col-register">
						<Form.Item name="farms" label="Фермы">
							<Row>
								<Col xs={12} sm={12} md={20}>
									<Select value={farmsSelectValues} onChange={handleChange} mode="multiple"
									        maxTagCount="responsive">
										{farms?.map(farm => (
											<Select.Option value={farm.getId()}>{farm.getName()}</Select.Option>))}
									</Select>
								</Col>
								<Col xs={12} sm={12} md={4}>
									<Button onClick={selectAllFarms}>Выбрать все</Button>
								</Col>
							</Row>
						</Form.Item>
						<Form.Item name="phone" label="Телефон"
						           rules={[{
							           required: true,
							           pattern: new RegExp('^[^_]*$'),
							           message: 'Введите верный телефон',
						           }]}>
							<MaskedInput mask={mask} placeholder="+7 (___) ___-__-__"/>
						</Form.Item>
						<Form.Item name="address" label="Адрес">
							<Input/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<p style={{ color: '#CE3131' }}>* - поля, обязательные для заполнения</p>
						<Form.Item>
							<Button style={{ minWidth: '200px' }} size="large" type="primary" htmlType="submit"
							        className="btn-register">
								Отправить
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<p className="dark-green-text medium-text">Уже есть аккаунт? <a className="dark-green-text underlined"
			                                                                href={`https://${domain}/ory/ory-adapter/init-login`}>Войти</a>
			</p>
		</div>
	);
};