// source: event/AbstractEvent.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var Common_pb = require('../Common_pb.js');
goog.object.extend(proto, Common_pb);
var event_EventSource_pb = require('../event/EventSource_pb.js');
goog.object.extend(proto, event_EventSource_pb);
var farm_FarmStructureService_pb = require('../farm/FarmStructureService_pb.js');
goog.object.extend(proto, farm_FarmStructureService_pb);
var security_EmployeeService_pb = require('../security/EmployeeService_pb.js');
goog.object.extend(proto, security_EmployeeService_pb);
var tasks_Label_pb = require('../tasks/Label_pb.js');
goog.object.extend(proto, tasks_Label_pb);
goog.exportSymbol('proto.cyberia.grpc.event.AbstractEvent', null, global);
goog.exportSymbol('proto.cyberia.grpc.event.AbstractEventSearch', null, global);
goog.exportSymbol('proto.cyberia.grpc.event.EventField', null, global);
goog.exportSymbol('proto.cyberia.grpc.event.EventLevel', null, global);
goog.exportSymbol('proto.cyberia.grpc.event.EventList', null, global);
goog.exportSymbol('proto.cyberia.grpc.event.EventSort', null, global);
goog.exportSymbol('proto.cyberia.grpc.event.EventSubject', null, global);
goog.exportSymbol('proto.cyberia.grpc.event.EventSubjectType', null, global);
goog.exportSymbol('proto.cyberia.grpc.event.EventType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.event.AbstractEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.event.AbstractEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.event.AbstractEvent.displayName = 'proto.cyberia.grpc.event.AbstractEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.event.EventList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.event.EventList.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.event.EventList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.event.EventList.displayName = 'proto.cyberia.grpc.event.EventList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.event.EventSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.event.EventSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.event.EventSort.displayName = 'proto.cyberia.grpc.event.EventSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.event.EventSubject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.event.EventSubject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.event.EventSubject.displayName = 'proto.cyberia.grpc.event.EventSubject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.event.AbstractEventSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.event.AbstractEventSearch.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.event.AbstractEventSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.event.AbstractEventSearch.displayName = 'proto.cyberia.grpc.event.AbstractEventSearch';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.event.AbstractEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.event.AbstractEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.event.AbstractEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    time: (f = msg.getTime()) && Common_pb.Time.toObject(includeInstance, f),
    level: jspb.Message.getFieldWithDefault(msg, 3, 0),
    eventtype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    source: (f = msg.getSource()) && event_EventSource_pb.Source.toObject(includeInstance, f),
    targetusers: (f = msg.getTargetusers()) && security_EmployeeService_pb.EmployeeList.toObject(includeInstance, f),
    place: (f = msg.getPlace()) && farm_FarmStructureService_pb.FarmStructure.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    attachments: (f = msg.getAttachments()) && Common_pb.Files.toObject(includeInstance, f),
    viewedby: (f = msg.getViewedby()) && security_EmployeeService_pb.EmployeeList.toObject(includeInstance, f),
    labels: (f = msg.getLabels()) && tasks_Label_pb.LabelList.toObject(includeInstance, f),
    subeventsids: (f = msg.getSubeventsids()) && Common_pb.ObjectsId.toObject(includeInstance, f),
    historysubject: (f = msg.getHistorysubject()) && proto.cyberia.grpc.event.EventSubject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.event.AbstractEvent}
 */
proto.cyberia.grpc.event.AbstractEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.event.AbstractEvent;
  return proto.cyberia.grpc.event.AbstractEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.event.AbstractEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.event.AbstractEvent}
 */
proto.cyberia.grpc.event.AbstractEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new Common_pb.Time;
      reader.readMessage(value,Common_pb.Time.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 3:
      var value = /** @type {!proto.cyberia.grpc.event.EventLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    case 4:
      var value = /** @type {!proto.cyberia.grpc.event.EventType} */ (reader.readEnum());
      msg.setEventtype(value);
      break;
    case 5:
      var value = new event_EventSource_pb.Source;
      reader.readMessage(value,event_EventSource_pb.Source.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 6:
      var value = new security_EmployeeService_pb.EmployeeList;
      reader.readMessage(value,security_EmployeeService_pb.EmployeeList.deserializeBinaryFromReader);
      msg.setTargetusers(value);
      break;
    case 7:
      var value = new farm_FarmStructureService_pb.FarmStructure;
      reader.readMessage(value,farm_FarmStructureService_pb.FarmStructure.deserializeBinaryFromReader);
      msg.setPlace(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = new Common_pb.Files;
      reader.readMessage(value,Common_pb.Files.deserializeBinaryFromReader);
      msg.setAttachments(value);
      break;
    case 10:
      var value = new security_EmployeeService_pb.EmployeeList;
      reader.readMessage(value,security_EmployeeService_pb.EmployeeList.deserializeBinaryFromReader);
      msg.setViewedby(value);
      break;
    case 11:
      var value = new tasks_Label_pb.LabelList;
      reader.readMessage(value,tasks_Label_pb.LabelList.deserializeBinaryFromReader);
      msg.setLabels(value);
      break;
    case 12:
      var value = new Common_pb.ObjectsId;
      reader.readMessage(value,Common_pb.ObjectsId.deserializeBinaryFromReader);
      msg.setSubeventsids(value);
      break;
    case 13:
      var value = new proto.cyberia.grpc.event.EventSubject;
      reader.readMessage(value,proto.cyberia.grpc.event.EventSubject.deserializeBinaryFromReader);
      msg.setHistorysubject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.event.AbstractEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.event.AbstractEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.event.AbstractEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Common_pb.Time.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.cyberia.grpc.event.EventLevel} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {!proto.cyberia.grpc.event.EventType} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      event_EventSource_pb.Source.serializeBinaryToWriter
    );
  }
  f = message.getTargetusers();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      security_EmployeeService_pb.EmployeeList.serializeBinaryToWriter
    );
  }
  f = message.getPlace();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      farm_FarmStructureService_pb.FarmStructure.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAttachments();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Common_pb.Files.serializeBinaryToWriter
    );
  }
  f = message.getViewedby();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      security_EmployeeService_pb.EmployeeList.serializeBinaryToWriter
    );
  }
  f = message.getLabels();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      tasks_Label_pb.LabelList.serializeBinaryToWriter
    );
  }
  f = message.getSubeventsids();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Common_pb.ObjectsId.serializeBinaryToWriter
    );
  }
  f = message.getHistorysubject();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.cyberia.grpc.event.EventSubject.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.Time time = 2;
 * @return {?proto.common.Time}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.getTime = function() {
  return /** @type{?proto.common.Time} */ (
    jspb.Message.getWrapperField(this, Common_pb.Time, 2));
};


/**
 * @param {?proto.common.Time|undefined} value
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
*/
proto.cyberia.grpc.event.AbstractEvent.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.hasTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EventLevel level = 3;
 * @return {!proto.cyberia.grpc.event.EventLevel}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.getLevel = function() {
  return /** @type {!proto.cyberia.grpc.event.EventLevel} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.cyberia.grpc.event.EventLevel} value
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.setLevel = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.clearLevel = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.hasLevel = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional EventType eventType = 4;
 * @return {!proto.cyberia.grpc.event.EventType}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.getEventtype = function() {
  return /** @type {!proto.cyberia.grpc.event.EventType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.cyberia.grpc.event.EventType} value
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.setEventtype = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.clearEventtype = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.hasEventtype = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Source source = 5;
 * @return {?proto.cyberia.grpc.event.Source}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.getSource = function() {
  return /** @type{?proto.cyberia.grpc.event.Source} */ (
    jspb.Message.getWrapperField(this, event_EventSource_pb.Source, 5));
};


/**
 * @param {?proto.cyberia.grpc.event.Source|undefined} value
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
*/
proto.cyberia.grpc.event.AbstractEvent.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.hasSource = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional cyberia.grpc.user.EmployeeList targetUsers = 6;
 * @return {?proto.cyberia.grpc.user.EmployeeList}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.getTargetusers = function() {
  return /** @type{?proto.cyberia.grpc.user.EmployeeList} */ (
    jspb.Message.getWrapperField(this, security_EmployeeService_pb.EmployeeList, 6));
};


/**
 * @param {?proto.cyberia.grpc.user.EmployeeList|undefined} value
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
*/
proto.cyberia.grpc.event.AbstractEvent.prototype.setTargetusers = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.clearTargetusers = function() {
  return this.setTargetusers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.hasTargetusers = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional cyberia.grpc.farm.FarmStructure place = 7;
 * @return {?proto.cyberia.grpc.farm.FarmStructure}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.getPlace = function() {
  return /** @type{?proto.cyberia.grpc.farm.FarmStructure} */ (
    jspb.Message.getWrapperField(this, farm_FarmStructureService_pb.FarmStructure, 7));
};


/**
 * @param {?proto.cyberia.grpc.farm.FarmStructure|undefined} value
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
*/
proto.cyberia.grpc.event.AbstractEvent.prototype.setPlace = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.clearPlace = function() {
  return this.setPlace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.hasPlace = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.Files attachments = 9;
 * @return {?proto.common.Files}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.getAttachments = function() {
  return /** @type{?proto.common.Files} */ (
    jspb.Message.getWrapperField(this, Common_pb.Files, 9));
};


/**
 * @param {?proto.common.Files|undefined} value
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
*/
proto.cyberia.grpc.event.AbstractEvent.prototype.setAttachments = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.clearAttachments = function() {
  return this.setAttachments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.hasAttachments = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional cyberia.grpc.user.EmployeeList viewedBy = 10;
 * @return {?proto.cyberia.grpc.user.EmployeeList}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.getViewedby = function() {
  return /** @type{?proto.cyberia.grpc.user.EmployeeList} */ (
    jspb.Message.getWrapperField(this, security_EmployeeService_pb.EmployeeList, 10));
};


/**
 * @param {?proto.cyberia.grpc.user.EmployeeList|undefined} value
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
*/
proto.cyberia.grpc.event.AbstractEvent.prototype.setViewedby = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.clearViewedby = function() {
  return this.setViewedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.hasViewedby = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional cyberia.grpc.tasks.LabelList labels = 11;
 * @return {?proto.cyberia.grpc.tasks.LabelList}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.getLabels = function() {
  return /** @type{?proto.cyberia.grpc.tasks.LabelList} */ (
    jspb.Message.getWrapperField(this, tasks_Label_pb.LabelList, 11));
};


/**
 * @param {?proto.cyberia.grpc.tasks.LabelList|undefined} value
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
*/
proto.cyberia.grpc.event.AbstractEvent.prototype.setLabels = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.clearLabels = function() {
  return this.setLabels(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.hasLabels = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional common.ObjectsId subEventsIds = 12;
 * @return {?proto.common.ObjectsId}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.getSubeventsids = function() {
  return /** @type{?proto.common.ObjectsId} */ (
    jspb.Message.getWrapperField(this, Common_pb.ObjectsId, 12));
};


/**
 * @param {?proto.common.ObjectsId|undefined} value
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
*/
proto.cyberia.grpc.event.AbstractEvent.prototype.setSubeventsids = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.clearSubeventsids = function() {
  return this.setSubeventsids(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.hasSubeventsids = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional EventSubject historySubject = 13;
 * @return {?proto.cyberia.grpc.event.EventSubject}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.getHistorysubject = function() {
  return /** @type{?proto.cyberia.grpc.event.EventSubject} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.grpc.event.EventSubject, 13));
};


/**
 * @param {?proto.cyberia.grpc.event.EventSubject|undefined} value
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
*/
proto.cyberia.grpc.event.AbstractEvent.prototype.setHistorysubject = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.event.AbstractEvent} returns this
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.clearHistorysubject = function() {
  return this.setHistorysubject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEvent.prototype.hasHistorysubject = function() {
  return jspb.Message.getField(this, 13) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.event.EventList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.event.EventList.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.event.EventList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.event.EventList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.event.EventList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.grpc.event.AbstractEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.event.EventList}
 */
proto.cyberia.grpc.event.EventList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.event.EventList;
  return proto.cyberia.grpc.event.EventList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.event.EventList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.event.EventList}
 */
proto.cyberia.grpc.event.EventList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.event.AbstractEvent;
      reader.readMessage(value,proto.cyberia.grpc.event.AbstractEvent.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.event.EventList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.event.EventList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.event.EventList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.event.EventList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.grpc.event.AbstractEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AbstractEvent list = 1;
 * @return {!Array<!proto.cyberia.grpc.event.AbstractEvent>}
 */
proto.cyberia.grpc.event.EventList.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.event.AbstractEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.grpc.event.AbstractEvent, 1));
};


/**
 * @param {!Array<!proto.cyberia.grpc.event.AbstractEvent>} value
 * @return {!proto.cyberia.grpc.event.EventList} returns this
*/
proto.cyberia.grpc.event.EventList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.grpc.event.AbstractEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.event.AbstractEvent}
 */
proto.cyberia.grpc.event.EventList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.grpc.event.AbstractEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.event.EventList} returns this
 */
proto.cyberia.grpc.event.EventList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.event.EventSort.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.event.EventSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.event.EventSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.event.EventSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ascending: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.event.EventSort}
 */
proto.cyberia.grpc.event.EventSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.event.EventSort;
  return proto.cyberia.grpc.event.EventSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.event.EventSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.event.EventSort}
 */
proto.cyberia.grpc.event.EventSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cyberia.grpc.event.EventField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAscending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.event.EventSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.event.EventSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.event.EventSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.event.EventSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAscending();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional EventField field = 1;
 * @return {!proto.cyberia.grpc.event.EventField}
 */
proto.cyberia.grpc.event.EventSort.prototype.getField = function() {
  return /** @type {!proto.cyberia.grpc.event.EventField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.cyberia.grpc.event.EventField} value
 * @return {!proto.cyberia.grpc.event.EventSort} returns this
 */
proto.cyberia.grpc.event.EventSort.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool ascending = 2;
 * @return {boolean}
 */
proto.cyberia.grpc.event.EventSort.prototype.getAscending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cyberia.grpc.event.EventSort} returns this
 */
proto.cyberia.grpc.event.EventSort.prototype.setAscending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.event.EventSubject.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.event.EventSubject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.event.EventSubject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.event.EventSubject.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjecttype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.event.EventSubject}
 */
proto.cyberia.grpc.event.EventSubject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.event.EventSubject;
  return proto.cyberia.grpc.event.EventSubject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.event.EventSubject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.event.EventSubject}
 */
proto.cyberia.grpc.event.EventSubject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cyberia.grpc.event.EventSubjectType} */ (reader.readEnum());
      msg.setSubjecttype(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.event.EventSubject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.event.EventSubject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.event.EventSubject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.event.EventSubject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjecttype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional EventSubjectType subjectType = 1;
 * @return {!proto.cyberia.grpc.event.EventSubjectType}
 */
proto.cyberia.grpc.event.EventSubject.prototype.getSubjecttype = function() {
  return /** @type {!proto.cyberia.grpc.event.EventSubjectType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.cyberia.grpc.event.EventSubjectType} value
 * @return {!proto.cyberia.grpc.event.EventSubject} returns this
 */
proto.cyberia.grpc.event.EventSubject.prototype.setSubjecttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.cyberia.grpc.event.EventSubject.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.event.EventSubject} returns this
 */
proto.cyberia.grpc.event.EventSubject.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.event.AbstractEventSearch.repeatedFields_ = [1,4,5,6,7,9,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.event.AbstractEventSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.event.AbstractEventSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.event.AbstractEventSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    sortbyList: jspb.Message.toObjectList(msg.getSortbyList(),
    proto.cyberia.grpc.event.EventSort.toObject, includeInstance),
    fulltextsearch: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timeinterval: (f = msg.getTimeinterval()) && Common_pb.TimeInterval.toObject(includeInstance, f),
    eventlevelsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    eventtypesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    eventsourceList: jspb.Message.toObjectList(msg.getEventsourceList(),
    event_EventSource_pb.Source.toObject, includeInstance),
    placeList: jspb.Message.toObjectList(msg.getPlaceList(),
    farm_FarmStructureService_pb.FarmStructure.toObject, includeInstance),
    haveattachments: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    viewedbyList: jspb.Message.toObjectList(msg.getViewedbyList(),
    security_EmployeeService_pb.Employee.toObject, includeInstance),
    targetusersList: jspb.Message.toObjectList(msg.getTargetusersList(),
    security_EmployeeService_pb.Employee.toObject, includeInstance),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    tasks_Label_pb.Label.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch}
 */
proto.cyberia.grpc.event.AbstractEventSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.event.AbstractEventSearch;
  return proto.cyberia.grpc.event.AbstractEventSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.event.AbstractEventSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch}
 */
proto.cyberia.grpc.event.AbstractEventSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.event.EventSort;
      reader.readMessage(value,proto.cyberia.grpc.event.EventSort.deserializeBinaryFromReader);
      msg.addSortby(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFulltextsearch(value);
      break;
    case 3:
      var value = new Common_pb.TimeInterval;
      reader.readMessage(value,Common_pb.TimeInterval.deserializeBinaryFromReader);
      msg.setTimeinterval(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.cyberia.grpc.event.EventLevel>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEventlevels(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<!proto.cyberia.grpc.event.EventType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEventtypes(values[i]);
      }
      break;
    case 6:
      var value = new event_EventSource_pb.Source;
      reader.readMessage(value,event_EventSource_pb.Source.deserializeBinaryFromReader);
      msg.addEventsource(value);
      break;
    case 7:
      var value = new farm_FarmStructureService_pb.FarmStructure;
      reader.readMessage(value,farm_FarmStructureService_pb.FarmStructure.deserializeBinaryFromReader);
      msg.addPlace(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHaveattachments(value);
      break;
    case 9:
      var value = new security_EmployeeService_pb.Employee;
      reader.readMessage(value,security_EmployeeService_pb.Employee.deserializeBinaryFromReader);
      msg.addViewedby(value);
      break;
    case 10:
      var value = new security_EmployeeService_pb.Employee;
      reader.readMessage(value,security_EmployeeService_pb.Employee.deserializeBinaryFromReader);
      msg.addTargetusers(value);
      break;
    case 11:
      var value = new tasks_Label_pb.Label;
      reader.readMessage(value,tasks_Label_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.event.AbstractEventSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.event.AbstractEventSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.event.AbstractEventSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSortbyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.grpc.event.EventSort.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimeinterval();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Common_pb.TimeInterval.serializeBinaryToWriter
    );
  }
  f = message.getEventlevelsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getEventtypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getEventsourceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      event_EventSource_pb.Source.serializeBinaryToWriter
    );
  }
  f = message.getPlaceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      farm_FarmStructureService_pb.FarmStructure.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getViewedbyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      security_EmployeeService_pb.Employee.serializeBinaryToWriter
    );
  }
  f = message.getTargetusersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      security_EmployeeService_pb.Employee.serializeBinaryToWriter
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      tasks_Label_pb.Label.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventSort sortBy = 1;
 * @return {!Array<!proto.cyberia.grpc.event.EventSort>}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.getSortbyList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.event.EventSort>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.grpc.event.EventSort, 1));
};


/**
 * @param {!Array<!proto.cyberia.grpc.event.EventSort>} value
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
*/
proto.cyberia.grpc.event.AbstractEventSearch.prototype.setSortbyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.grpc.event.EventSort=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.event.EventSort}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.addSortby = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.grpc.event.EventSort, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.clearSortbyList = function() {
  return this.setSortbyList([]);
};


/**
 * optional string fullTextSearch = 2;
 * @return {string}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.getFulltextsearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.setFulltextsearch = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.clearFulltextsearch = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.hasFulltextsearch = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.TimeInterval timeInterval = 3;
 * @return {?proto.common.TimeInterval}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.getTimeinterval = function() {
  return /** @type{?proto.common.TimeInterval} */ (
    jspb.Message.getWrapperField(this, Common_pb.TimeInterval, 3));
};


/**
 * @param {?proto.common.TimeInterval|undefined} value
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
*/
proto.cyberia.grpc.event.AbstractEventSearch.prototype.setTimeinterval = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.clearTimeinterval = function() {
  return this.setTimeinterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.hasTimeinterval = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated EventLevel eventLevels = 4;
 * @return {!Array<!proto.cyberia.grpc.event.EventLevel>}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.getEventlevelsList = function() {
  return /** @type {!Array<!proto.cyberia.grpc.event.EventLevel>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.cyberia.grpc.event.EventLevel>} value
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.setEventlevelsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.cyberia.grpc.event.EventLevel} value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.addEventlevels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.clearEventlevelsList = function() {
  return this.setEventlevelsList([]);
};


/**
 * repeated EventType eventTypes = 5;
 * @return {!Array<!proto.cyberia.grpc.event.EventType>}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.getEventtypesList = function() {
  return /** @type {!Array<!proto.cyberia.grpc.event.EventType>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.cyberia.grpc.event.EventType>} value
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.setEventtypesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.cyberia.grpc.event.EventType} value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.addEventtypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.clearEventtypesList = function() {
  return this.setEventtypesList([]);
};


/**
 * repeated Source eventSource = 6;
 * @return {!Array<!proto.cyberia.grpc.event.Source>}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.getEventsourceList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.event.Source>} */ (
    jspb.Message.getRepeatedWrapperField(this, event_EventSource_pb.Source, 6));
};


/**
 * @param {!Array<!proto.cyberia.grpc.event.Source>} value
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
*/
proto.cyberia.grpc.event.AbstractEventSearch.prototype.setEventsourceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.cyberia.grpc.event.Source=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.event.Source}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.addEventsource = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.cyberia.grpc.event.Source, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.clearEventsourceList = function() {
  return this.setEventsourceList([]);
};


/**
 * repeated cyberia.grpc.farm.FarmStructure place = 7;
 * @return {!Array<!proto.cyberia.grpc.farm.FarmStructure>}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.getPlaceList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.farm.FarmStructure>} */ (
    jspb.Message.getRepeatedWrapperField(this, farm_FarmStructureService_pb.FarmStructure, 7));
};


/**
 * @param {!Array<!proto.cyberia.grpc.farm.FarmStructure>} value
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
*/
proto.cyberia.grpc.event.AbstractEventSearch.prototype.setPlaceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.cyberia.grpc.farm.FarmStructure=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.farm.FarmStructure}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.addPlace = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.cyberia.grpc.farm.FarmStructure, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.clearPlaceList = function() {
  return this.setPlaceList([]);
};


/**
 * optional bool haveAttachments = 8;
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.getHaveattachments = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.setHaveattachments = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.clearHaveattachments = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.hasHaveattachments = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated cyberia.grpc.user.Employee viewedBy = 9;
 * @return {!Array<!proto.cyberia.grpc.user.Employee>}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.getViewedbyList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.user.Employee>} */ (
    jspb.Message.getRepeatedWrapperField(this, security_EmployeeService_pb.Employee, 9));
};


/**
 * @param {!Array<!proto.cyberia.grpc.user.Employee>} value
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
*/
proto.cyberia.grpc.event.AbstractEventSearch.prototype.setViewedbyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.cyberia.grpc.user.Employee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.user.Employee}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.addViewedby = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.cyberia.grpc.user.Employee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.clearViewedbyList = function() {
  return this.setViewedbyList([]);
};


/**
 * repeated cyberia.grpc.user.Employee targetUsers = 10;
 * @return {!Array<!proto.cyberia.grpc.user.Employee>}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.getTargetusersList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.user.Employee>} */ (
    jspb.Message.getRepeatedWrapperField(this, security_EmployeeService_pb.Employee, 10));
};


/**
 * @param {!Array<!proto.cyberia.grpc.user.Employee>} value
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
*/
proto.cyberia.grpc.event.AbstractEventSearch.prototype.setTargetusersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.cyberia.grpc.user.Employee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.user.Employee}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.addTargetusers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.cyberia.grpc.user.Employee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.clearTargetusersList = function() {
  return this.setTargetusersList([]);
};


/**
 * repeated cyberia.grpc.tasks.Label labels = 11;
 * @return {!Array<!proto.cyberia.grpc.tasks.Label>}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.tasks.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, tasks_Label_pb.Label, 11));
};


/**
 * @param {!Array<!proto.cyberia.grpc.tasks.Label>} value
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
*/
proto.cyberia.grpc.event.AbstractEventSearch.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.cyberia.grpc.tasks.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.tasks.Label}
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.cyberia.grpc.tasks.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.event.AbstractEventSearch} returns this
 */
proto.cyberia.grpc.event.AbstractEventSearch.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * @enum {number}
 */
proto.cyberia.grpc.event.EventLevel = {
  NOTETYPE: 0,
  DEBUG: 1,
  INFORMATION: 2,
  WARNING: 3,
  VIOLATION: 4,
  CRITICALVIOLATION: 5
};

/**
 * @enum {number}
 */
proto.cyberia.grpc.event.EventType = {
  NONETYPE: 0,
  LOGIN: 1,
  LOGOUT: 2,
  CREATE: 3,
  UPDATE: 4,
  DELETE: 5,
  REPORT: 6,
  CLEAR: 7,
  FAILEDLOGIN: 8,
  PASSTHROUGHTERMINAL: 9,
  TASKCREATE: 10,
  TASKSTATUSCHANGE: 11,
  TASKDEADLINE: 12,
  EQUIPMENTVIOLATION: 13,
  EMPLOYEEVIOLATION: 14,
  COMMENT: 15,
  EMPLOYEEWORK: 16,
  BEEFLY: 17
};

/**
 * @enum {number}
 */
proto.cyberia.grpc.event.EventField = {
  NONEFIELD: 0,
  TIME: 1,
  LEVEL: 2,
  EVENTTYPE: 3,
  SOURCE: 4,
  TARGETUSERS: 5,
  PLACE: 6,
  VIEWEDBY: 7,
  LABELS: 8,
  DESCRIPTION: 9
};

/**
 * @enum {number}
 */
proto.cyberia.grpc.event.EventSubjectType = {
  NONESUBJ: 0,
  TASK: 1,
  EQUIPMENT: 2,
  EMPLOYEE: 3,
  PROCESSPROFILE: 4,
  FARMSTRUCTURE: 5
};

goog.object.extend(exports, proto.cyberia.grpc.event);
