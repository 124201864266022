// source: process/PhaseOfDay.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var Common_pb = require('../Common_pb.js');
goog.object.extend(proto, Common_pb);
goog.exportSymbol('proto.cyberia.grpc.process.DefaultPhases', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.PhaseOfDay', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.PhaseOfDayList', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.PhaseOfDay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.process.PhaseOfDay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.PhaseOfDay.displayName = 'proto.cyberia.grpc.process.PhaseOfDay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.DefaultPhases = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.process.DefaultPhases, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.DefaultPhases.displayName = 'proto.cyberia.grpc.process.DefaultPhases';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.PhaseOfDayList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.process.PhaseOfDayList.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.process.PhaseOfDayList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.PhaseOfDayList.displayName = 'proto.cyberia.grpc.process.PhaseOfDayList';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.PhaseOfDay.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.PhaseOfDay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.PhaseOfDay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.PhaseOfDay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.PhaseOfDay}
 */
proto.cyberia.grpc.process.PhaseOfDay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.PhaseOfDay;
  return proto.cyberia.grpc.process.PhaseOfDay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.PhaseOfDay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.PhaseOfDay}
 */
proto.cyberia.grpc.process.PhaseOfDay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.PhaseOfDay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.PhaseOfDay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.PhaseOfDay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.PhaseOfDay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cyberia.grpc.process.PhaseOfDay.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.PhaseOfDay} returns this
 */
proto.cyberia.grpc.process.PhaseOfDay.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.PhaseOfDay} returns this
 */
proto.cyberia.grpc.process.PhaseOfDay.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.PhaseOfDay.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cyberia.grpc.process.PhaseOfDay.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.process.PhaseOfDay} returns this
 */
proto.cyberia.grpc.process.PhaseOfDay.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.PhaseOfDay} returns this
 */
proto.cyberia.grpc.process.PhaseOfDay.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.PhaseOfDay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.cyberia.grpc.process.PhaseOfDay.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.PhaseOfDay} returns this
 */
proto.cyberia.grpc.process.PhaseOfDay.prototype.setOrder = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.PhaseOfDay} returns this
 */
proto.cyberia.grpc.process.PhaseOfDay.prototype.clearOrder = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.PhaseOfDay.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.DefaultPhases.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.DefaultPhases.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.DefaultPhases} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.DefaultPhases.toObject = function(includeInstance, msg) {
  var f, obj = {
    day: (f = msg.getDay()) && proto.cyberia.grpc.process.PhaseOfDay.toObject(includeInstance, f),
    night: (f = msg.getNight()) && proto.cyberia.grpc.process.PhaseOfDay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.DefaultPhases}
 */
proto.cyberia.grpc.process.DefaultPhases.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.DefaultPhases;
  return proto.cyberia.grpc.process.DefaultPhases.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.DefaultPhases} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.DefaultPhases}
 */
proto.cyberia.grpc.process.DefaultPhases.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.process.PhaseOfDay;
      reader.readMessage(value,proto.cyberia.grpc.process.PhaseOfDay.deserializeBinaryFromReader);
      msg.setDay(value);
      break;
    case 2:
      var value = new proto.cyberia.grpc.process.PhaseOfDay;
      reader.readMessage(value,proto.cyberia.grpc.process.PhaseOfDay.deserializeBinaryFromReader);
      msg.setNight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.DefaultPhases.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.DefaultPhases.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.DefaultPhases} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.DefaultPhases.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDay();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cyberia.grpc.process.PhaseOfDay.serializeBinaryToWriter
    );
  }
  f = message.getNight();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.cyberia.grpc.process.PhaseOfDay.serializeBinaryToWriter
    );
  }
};


/**
 * optional PhaseOfDay day = 1;
 * @return {?proto.cyberia.grpc.process.PhaseOfDay}
 */
proto.cyberia.grpc.process.DefaultPhases.prototype.getDay = function() {
  return /** @type{?proto.cyberia.grpc.process.PhaseOfDay} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.grpc.process.PhaseOfDay, 1));
};


/**
 * @param {?proto.cyberia.grpc.process.PhaseOfDay|undefined} value
 * @return {!proto.cyberia.grpc.process.DefaultPhases} returns this
*/
proto.cyberia.grpc.process.DefaultPhases.prototype.setDay = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.DefaultPhases} returns this
 */
proto.cyberia.grpc.process.DefaultPhases.prototype.clearDay = function() {
  return this.setDay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.DefaultPhases.prototype.hasDay = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PhaseOfDay night = 2;
 * @return {?proto.cyberia.grpc.process.PhaseOfDay}
 */
proto.cyberia.grpc.process.DefaultPhases.prototype.getNight = function() {
  return /** @type{?proto.cyberia.grpc.process.PhaseOfDay} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.grpc.process.PhaseOfDay, 2));
};


/**
 * @param {?proto.cyberia.grpc.process.PhaseOfDay|undefined} value
 * @return {!proto.cyberia.grpc.process.DefaultPhases} returns this
*/
proto.cyberia.grpc.process.DefaultPhases.prototype.setNight = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.DefaultPhases} returns this
 */
proto.cyberia.grpc.process.DefaultPhases.prototype.clearNight = function() {
  return this.setNight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.DefaultPhases.prototype.hasNight = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.process.PhaseOfDayList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.PhaseOfDayList.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.PhaseOfDayList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.PhaseOfDayList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.PhaseOfDayList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.grpc.process.PhaseOfDay.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.PhaseOfDayList}
 */
proto.cyberia.grpc.process.PhaseOfDayList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.PhaseOfDayList;
  return proto.cyberia.grpc.process.PhaseOfDayList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.PhaseOfDayList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.PhaseOfDayList}
 */
proto.cyberia.grpc.process.PhaseOfDayList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.process.PhaseOfDay;
      reader.readMessage(value,proto.cyberia.grpc.process.PhaseOfDay.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.PhaseOfDayList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.PhaseOfDayList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.PhaseOfDayList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.PhaseOfDayList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.grpc.process.PhaseOfDay.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PhaseOfDay list = 1;
 * @return {!Array<!proto.cyberia.grpc.process.PhaseOfDay>}
 */
proto.cyberia.grpc.process.PhaseOfDayList.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.process.PhaseOfDay>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.grpc.process.PhaseOfDay, 1));
};


/**
 * @param {!Array<!proto.cyberia.grpc.process.PhaseOfDay>} value
 * @return {!proto.cyberia.grpc.process.PhaseOfDayList} returns this
*/
proto.cyberia.grpc.process.PhaseOfDayList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.grpc.process.PhaseOfDay=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.process.PhaseOfDay}
 */
proto.cyberia.grpc.process.PhaseOfDayList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.grpc.process.PhaseOfDay, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.process.PhaseOfDayList} returns this
 */
proto.cyberia.grpc.process.PhaseOfDayList.prototype.clearListList = function() {
  return this.setListList([]);
};


goog.object.extend(exports, proto.cyberia.grpc.process);
