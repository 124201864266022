import { ATFLogo } from 'icons';
import { RegistrationForm } from 'shared';
import './registration.page.less';

export const RegistrationPage = () => {
	return (
		<div className="central-page">
			<div className="central-div">
				<ATFLogo/>
				<RegistrationForm/>
			</div>
		</div>
	);
};