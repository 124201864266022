// source: process/ModeCategoryPP.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var Common_pb = require('../Common_pb.js');
goog.object.extend(proto, Common_pb);
var web_equipment_ParametersService_pb = require('../web/equipment/ParametersService_pb.js');
goog.object.extend(proto, web_equipment_ParametersService_pb);
goog.exportSymbol('proto.cyberia.grpc.process.CategoryItem', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.ModeCategory', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.ModeCategoryList', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.ModeCategoryParamList', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.ModeCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.process.ModeCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.ModeCategory.displayName = 'proto.cyberia.grpc.process.ModeCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.ModeCategoryParamList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.process.ModeCategoryParamList.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.process.ModeCategoryParamList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.ModeCategoryParamList.displayName = 'proto.cyberia.grpc.process.ModeCategoryParamList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.CategoryItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.process.CategoryItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.CategoryItem.displayName = 'proto.cyberia.grpc.process.CategoryItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.ModeCategoryList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.process.ModeCategoryList.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.process.ModeCategoryList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.ModeCategoryList.displayName = 'proto.cyberia.grpc.process.ModeCategoryList';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.ModeCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.ModeCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.ModeCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ModeCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parameters: (f = msg.getParameters()) && proto.cyberia.grpc.process.ModeCategoryParamList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.ModeCategory}
 */
proto.cyberia.grpc.process.ModeCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.ModeCategory;
  return proto.cyberia.grpc.process.ModeCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.ModeCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.ModeCategory}
 */
proto.cyberia.grpc.process.ModeCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = new proto.cyberia.grpc.process.ModeCategoryParamList;
      reader.readMessage(value,proto.cyberia.grpc.process.ModeCategoryParamList.deserializeBinaryFromReader);
      msg.setParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.ModeCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.ModeCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.ModeCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ModeCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParameters();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cyberia.grpc.process.ModeCategoryParamList.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cyberia.grpc.process.ModeCategory.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.ModeCategory} returns this
 */
proto.cyberia.grpc.process.ModeCategory.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.ModeCategory} returns this
 */
proto.cyberia.grpc.process.ModeCategory.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ModeCategory.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.cyberia.grpc.process.ModeCategory.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.process.ModeCategory} returns this
 */
proto.cyberia.grpc.process.ModeCategory.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.ModeCategory} returns this
 */
proto.cyberia.grpc.process.ModeCategory.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ModeCategory.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ModeCategoryParamList parameters = 3;
 * @return {?proto.cyberia.grpc.process.ModeCategoryParamList}
 */
proto.cyberia.grpc.process.ModeCategory.prototype.getParameters = function() {
  return /** @type{?proto.cyberia.grpc.process.ModeCategoryParamList} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.grpc.process.ModeCategoryParamList, 3));
};


/**
 * @param {?proto.cyberia.grpc.process.ModeCategoryParamList|undefined} value
 * @return {!proto.cyberia.grpc.process.ModeCategory} returns this
*/
proto.cyberia.grpc.process.ModeCategory.prototype.setParameters = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.ModeCategory} returns this
 */
proto.cyberia.grpc.process.ModeCategory.prototype.clearParameters = function() {
  return this.setParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ModeCategory.prototype.hasParameters = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.process.ModeCategoryParamList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.ModeCategoryParamList.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.ModeCategoryParamList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.ModeCategoryParamList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ModeCategoryParamList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.cyberia.grpc.process.CategoryItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.ModeCategoryParamList}
 */
proto.cyberia.grpc.process.ModeCategoryParamList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.ModeCategoryParamList;
  return proto.cyberia.grpc.process.ModeCategoryParamList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.ModeCategoryParamList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.ModeCategoryParamList}
 */
proto.cyberia.grpc.process.ModeCategoryParamList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.process.CategoryItem;
      reader.readMessage(value,proto.cyberia.grpc.process.CategoryItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.ModeCategoryParamList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.ModeCategoryParamList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.ModeCategoryParamList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ModeCategoryParamList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.grpc.process.CategoryItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CategoryItem items = 1;
 * @return {!Array<!proto.cyberia.grpc.process.CategoryItem>}
 */
proto.cyberia.grpc.process.ModeCategoryParamList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.process.CategoryItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.grpc.process.CategoryItem, 1));
};


/**
 * @param {!Array<!proto.cyberia.grpc.process.CategoryItem>} value
 * @return {!proto.cyberia.grpc.process.ModeCategoryParamList} returns this
*/
proto.cyberia.grpc.process.ModeCategoryParamList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.grpc.process.CategoryItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.process.CategoryItem}
 */
proto.cyberia.grpc.process.ModeCategoryParamList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.grpc.process.CategoryItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.process.ModeCategoryParamList} returns this
 */
proto.cyberia.grpc.process.ModeCategoryParamList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.CategoryItem.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.CategoryItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.CategoryItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.CategoryItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    parameter: (f = msg.getParameter()) && web_equipment_ParametersService_pb.ParameterType.toObject(includeInstance, f),
    measureparameter: (f = msg.getMeasureparameter()) && web_equipment_ParametersService_pb.ParameterType.toObject(includeInstance, f),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.CategoryItem}
 */
proto.cyberia.grpc.process.CategoryItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.CategoryItem;
  return proto.cyberia.grpc.process.CategoryItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.CategoryItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.CategoryItem}
 */
proto.cyberia.grpc.process.CategoryItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new web_equipment_ParametersService_pb.ParameterType;
      reader.readMessage(value,web_equipment_ParametersService_pb.ParameterType.deserializeBinaryFromReader);
      msg.setParameter(value);
      break;
    case 2:
      var value = new web_equipment_ParametersService_pb.ParameterType;
      reader.readMessage(value,web_equipment_ParametersService_pb.ParameterType.deserializeBinaryFromReader);
      msg.setMeasureparameter(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.CategoryItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.CategoryItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.CategoryItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.CategoryItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParameter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      web_equipment_ParametersService_pb.ParameterType.serializeBinaryToWriter
    );
  }
  f = message.getMeasureparameter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      web_equipment_ParametersService_pb.ParameterType.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional cyberia.web.equipment.ParameterType parameter = 1;
 * @return {?proto.cyberia.web.equipment.ParameterType}
 */
proto.cyberia.grpc.process.CategoryItem.prototype.getParameter = function() {
  return /** @type{?proto.cyberia.web.equipment.ParameterType} */ (
    jspb.Message.getWrapperField(this, web_equipment_ParametersService_pb.ParameterType, 1));
};


/**
 * @param {?proto.cyberia.web.equipment.ParameterType|undefined} value
 * @return {!proto.cyberia.grpc.process.CategoryItem} returns this
*/
proto.cyberia.grpc.process.CategoryItem.prototype.setParameter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.CategoryItem} returns this
 */
proto.cyberia.grpc.process.CategoryItem.prototype.clearParameter = function() {
  return this.setParameter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.CategoryItem.prototype.hasParameter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional cyberia.web.equipment.ParameterType measureParameter = 2;
 * @return {?proto.cyberia.web.equipment.ParameterType}
 */
proto.cyberia.grpc.process.CategoryItem.prototype.getMeasureparameter = function() {
  return /** @type{?proto.cyberia.web.equipment.ParameterType} */ (
    jspb.Message.getWrapperField(this, web_equipment_ParametersService_pb.ParameterType, 2));
};


/**
 * @param {?proto.cyberia.web.equipment.ParameterType|undefined} value
 * @return {!proto.cyberia.grpc.process.CategoryItem} returns this
*/
proto.cyberia.grpc.process.CategoryItem.prototype.setMeasureparameter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.CategoryItem} returns this
 */
proto.cyberia.grpc.process.CategoryItem.prototype.clearMeasureparameter = function() {
  return this.setMeasureparameter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.CategoryItem.prototype.hasMeasureparameter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.cyberia.grpc.process.CategoryItem.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.CategoryItem} returns this
 */
proto.cyberia.grpc.process.CategoryItem.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.process.ModeCategoryList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.ModeCategoryList.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.ModeCategoryList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.ModeCategoryList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ModeCategoryList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.grpc.process.ModeCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.ModeCategoryList}
 */
proto.cyberia.grpc.process.ModeCategoryList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.ModeCategoryList;
  return proto.cyberia.grpc.process.ModeCategoryList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.ModeCategoryList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.ModeCategoryList}
 */
proto.cyberia.grpc.process.ModeCategoryList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.process.ModeCategory;
      reader.readMessage(value,proto.cyberia.grpc.process.ModeCategory.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.ModeCategoryList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.ModeCategoryList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.ModeCategoryList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ModeCategoryList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.grpc.process.ModeCategory.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ModeCategory list = 1;
 * @return {!Array<!proto.cyberia.grpc.process.ModeCategory>}
 */
proto.cyberia.grpc.process.ModeCategoryList.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.process.ModeCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.grpc.process.ModeCategory, 1));
};


/**
 * @param {!Array<!proto.cyberia.grpc.process.ModeCategory>} value
 * @return {!proto.cyberia.grpc.process.ModeCategoryList} returns this
*/
proto.cyberia.grpc.process.ModeCategoryList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.grpc.process.ModeCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.process.ModeCategory}
 */
proto.cyberia.grpc.process.ModeCategoryList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.grpc.process.ModeCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.process.ModeCategoryList} returns this
 */
proto.cyberia.grpc.process.ModeCategoryList.prototype.clearListList = function() {
  return this.setListList([]);
};


goog.object.extend(exports, proto.cyberia.grpc.process);
