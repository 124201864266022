/**
 * @fileoverview gRPC-Web generated client stub for cyberia.grpc.farm
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as Common_pb from '../Common_pb';
import * as farm_FarmService_pb from '../farm/FarmService_pb';


export class FarmServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorgetAll = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.farm.FarmService/getAll',
    grpcWeb.MethodType.UNARY,
    Common_pb.Empty,
    Common_pb.ObjectsId,
    (request: Common_pb.Empty) => {
      return request.serializeBinary();
    },
    Common_pb.ObjectsId.deserializeBinary
  );

  getAll(
    request: Common_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.ObjectsId>;

  getAll(
    request: Common_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void): grpcWeb.ClientReadableStream<Common_pb.ObjectsId>;

  getAll(
    request: Common_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.farm.FarmService/getAll',
        request,
        metadata || {},
        this.methodDescriptorgetAll,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.farm.FarmService/getAll',
    request,
    metadata || {},
    this.methodDescriptorgetAll);
  }

  methodDescriptorinsert = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.farm.FarmService/insert',
    grpcWeb.MethodType.UNARY,
    farm_FarmService_pb.Farm,
    farm_FarmService_pb.Farm,
    (request: farm_FarmService_pb.Farm) => {
      return request.serializeBinary();
    },
    farm_FarmService_pb.Farm.deserializeBinary
  );

  insert(
    request: farm_FarmService_pb.Farm,
    metadata: grpcWeb.Metadata | null): Promise<farm_FarmService_pb.Farm>;

  insert(
    request: farm_FarmService_pb.Farm,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: farm_FarmService_pb.Farm) => void): grpcWeb.ClientReadableStream<farm_FarmService_pb.Farm>;

  insert(
    request: farm_FarmService_pb.Farm,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: farm_FarmService_pb.Farm) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.farm.FarmService/insert',
        request,
        metadata || {},
        this.methodDescriptorinsert,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.farm.FarmService/insert',
    request,
    metadata || {},
    this.methodDescriptorinsert);
  }

  methodDescriptorgetInfo = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.farm.FarmService/getInfo',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectsId,
    farm_FarmService_pb.FarmsInfo,
    (request: Common_pb.ObjectsId) => {
      return request.serializeBinary();
    },
    farm_FarmService_pb.FarmsInfo.deserializeBinary
  );

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null): Promise<farm_FarmService_pb.FarmsInfo>;

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: farm_FarmService_pb.FarmsInfo) => void): grpcWeb.ClientReadableStream<farm_FarmService_pb.FarmsInfo>;

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: farm_FarmService_pb.FarmsInfo) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.farm.FarmService/getInfo',
        request,
        metadata || {},
        this.methodDescriptorgetInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.farm.FarmService/getInfo',
    request,
    metadata || {},
    this.methodDescriptorgetInfo);
  }

  methodDescriptorbyFarmId = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.farm.FarmService/byFarmId',
    grpcWeb.MethodType.UNARY,
    Common_pb.String,
    farm_FarmService_pb.Farm,
    (request: Common_pb.String) => {
      return request.serializeBinary();
    },
    farm_FarmService_pb.Farm.deserializeBinary
  );

  byFarmId(
    request: Common_pb.String,
    metadata: grpcWeb.Metadata | null): Promise<farm_FarmService_pb.Farm>;

  byFarmId(
    request: Common_pb.String,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: farm_FarmService_pb.Farm) => void): grpcWeb.ClientReadableStream<farm_FarmService_pb.Farm>;

  byFarmId(
    request: Common_pb.String,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: farm_FarmService_pb.Farm) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.farm.FarmService/byFarmId',
        request,
        metadata || {},
        this.methodDescriptorbyFarmId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.farm.FarmService/byFarmId',
    request,
    metadata || {},
    this.methodDescriptorbyFarmId);
  }

  methodDescriptordelete = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.farm.FarmService/delete',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectsId,
    Common_pb.ObjectsId,
    (request: Common_pb.ObjectsId) => {
      return request.serializeBinary();
    },
    Common_pb.ObjectsId.deserializeBinary
  );

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.ObjectsId>;

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void): grpcWeb.ClientReadableStream<Common_pb.ObjectsId>;

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.farm.FarmService/delete',
        request,
        metadata || {},
        this.methodDescriptordelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.farm.FarmService/delete',
    request,
    metadata || {},
    this.methodDescriptordelete);
  }

  methodDescriptorcheckUniqName = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.farm.FarmService/checkUniqName',
    grpcWeb.MethodType.UNARY,
    farm_FarmService_pb.Farm,
    Common_pb.Check,
    (request: farm_FarmService_pb.Farm) => {
      return request.serializeBinary();
    },
    Common_pb.Check.deserializeBinary
  );

  checkUniqName(
    request: farm_FarmService_pb.Farm,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.Check>;

  checkUniqName(
    request: farm_FarmService_pb.Farm,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void): grpcWeb.ClientReadableStream<Common_pb.Check>;

  checkUniqName(
    request: farm_FarmService_pb.Farm,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.farm.FarmService/checkUniqName',
        request,
        metadata || {},
        this.methodDescriptorcheckUniqName,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.farm.FarmService/checkUniqName',
    request,
    metadata || {},
    this.methodDescriptorcheckUniqName);
  }

  methodDescriptorgetEmployees = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.farm.FarmService/getEmployees',
    grpcWeb.MethodType.UNARY,
    farm_FarmService_pb.Farm,
    farm_FarmService_pb.EmployeeRolesCollection,
    (request: farm_FarmService_pb.Farm) => {
      return request.serializeBinary();
    },
    farm_FarmService_pb.EmployeeRolesCollection.deserializeBinary
  );

  getEmployees(
    request: farm_FarmService_pb.Farm,
    metadata: grpcWeb.Metadata | null): Promise<farm_FarmService_pb.EmployeeRolesCollection>;

  getEmployees(
    request: farm_FarmService_pb.Farm,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: farm_FarmService_pb.EmployeeRolesCollection) => void): grpcWeb.ClientReadableStream<farm_FarmService_pb.EmployeeRolesCollection>;

  getEmployees(
    request: farm_FarmService_pb.Farm,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: farm_FarmService_pb.EmployeeRolesCollection) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.farm.FarmService/getEmployees',
        request,
        metadata || {},
        this.methodDescriptorgetEmployees,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.farm.FarmService/getEmployees',
    request,
    metadata || {},
    this.methodDescriptorgetEmployees);
  }

  methodDescriptorsetEmployees = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.farm.FarmService/setEmployees',
    grpcWeb.MethodType.UNARY,
    farm_FarmService_pb.EmployeeRolesCollection,
    Common_pb.Empty,
    (request: farm_FarmService_pb.EmployeeRolesCollection) => {
      return request.serializeBinary();
    },
    Common_pb.Empty.deserializeBinary
  );

  setEmployees(
    request: farm_FarmService_pb.EmployeeRolesCollection,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.Empty>;

  setEmployees(
    request: farm_FarmService_pb.EmployeeRolesCollection,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.Empty) => void): grpcWeb.ClientReadableStream<Common_pb.Empty>;

  setEmployees(
    request: farm_FarmService_pb.EmployeeRolesCollection,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.farm.FarmService/setEmployees',
        request,
        metadata || {},
        this.methodDescriptorsetEmployees,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.farm.FarmService/setEmployees',
    request,
    metadata || {},
    this.methodDescriptorsetEmployees);
  }

  methodDescriptorgetAvailableMonitoringFolder = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.farm.FarmService/getAvailableMonitoringFolder',
    grpcWeb.MethodType.UNARY,
    Common_pb.Empty,
    Common_pb.StringList,
    (request: Common_pb.Empty) => {
      return request.serializeBinary();
    },
    Common_pb.StringList.deserializeBinary
  );

  getAvailableMonitoringFolder(
    request: Common_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.StringList>;

  getAvailableMonitoringFolder(
    request: Common_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.StringList) => void): grpcWeb.ClientReadableStream<Common_pb.StringList>;

  getAvailableMonitoringFolder(
    request: Common_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.StringList) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.farm.FarmService/getAvailableMonitoringFolder',
        request,
        metadata || {},
        this.methodDescriptorgetAvailableMonitoringFolder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.farm.FarmService/getAvailableMonitoringFolder',
    request,
    metadata || {},
    this.methodDescriptorgetAvailableMonitoringFolder);
  }

  methodDescriptorcopyFarm = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.farm.FarmService/copyFarm',
    grpcWeb.MethodType.UNARY,
    farm_FarmService_pb.Farm,
    farm_FarmService_pb.Farm,
    (request: farm_FarmService_pb.Farm) => {
      return request.serializeBinary();
    },
    farm_FarmService_pb.Farm.deserializeBinary
  );

  copyFarm(
    request: farm_FarmService_pb.Farm,
    metadata: grpcWeb.Metadata | null): Promise<farm_FarmService_pb.Farm>;

  copyFarm(
    request: farm_FarmService_pb.Farm,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: farm_FarmService_pb.Farm) => void): grpcWeb.ClientReadableStream<farm_FarmService_pb.Farm>;

  copyFarm(
    request: farm_FarmService_pb.Farm,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: farm_FarmService_pb.Farm) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.farm.FarmService/copyFarm',
        request,
        metadata || {},
        this.methodDescriptorcopyFarm,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.farm.FarmService/copyFarm',
    request,
    metadata || {},
    this.methodDescriptorcopyFarm);
  }

}

