// source: farm/FarmStructureService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var Common_pb = require('../Common_pb.js');
goog.object.extend(proto, Common_pb);
goog.exportSymbol('proto.cyberia.grpc.farm.FarmStructure', null, global);
goog.exportSymbol('proto.cyberia.grpc.farm.FarmStructureList', null, global);
goog.exportSymbol('proto.cyberia.grpc.farm.FarmStructureSearch', null, global);
goog.exportSymbol('proto.cyberia.grpc.farm.FarmStructureType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.farm.FarmStructure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.farm.FarmStructure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.farm.FarmStructure.displayName = 'proto.cyberia.grpc.farm.FarmStructure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.farm.FarmStructureList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.farm.FarmStructureList.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.farm.FarmStructureList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.farm.FarmStructureList.displayName = 'proto.cyberia.grpc.farm.FarmStructureList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.farm.FarmStructureSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.farm.FarmStructureSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.farm.FarmStructureSearch.displayName = 'proto.cyberia.grpc.farm.FarmStructureSearch';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.farm.FarmStructure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.farm.FarmStructure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.farm.FarmStructure.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    structtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    parentid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    children: (f = msg.getChildren()) && Common_pb.ObjectsId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.farm.FarmStructure}
 */
proto.cyberia.grpc.farm.FarmStructure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.farm.FarmStructure;
  return proto.cyberia.grpc.farm.FarmStructure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.farm.FarmStructure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.farm.FarmStructure}
 */
proto.cyberia.grpc.farm.FarmStructure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.cyberia.grpc.farm.FarmStructureType} */ (reader.readEnum());
      msg.setStructtype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParentid(value);
      break;
    case 5:
      var value = new Common_pb.ObjectsId;
      reader.readMessage(value,Common_pb.ObjectsId.deserializeBinaryFromReader);
      msg.setChildren(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.farm.FarmStructure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.farm.FarmStructure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.farm.FarmStructure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {!proto.cyberia.grpc.farm.FarmStructureType} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getChildren();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Common_pb.ObjectsId.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.farm.FarmStructure} returns this
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.farm.FarmStructure} returns this
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FarmStructureType structType = 2;
 * @return {!proto.cyberia.grpc.farm.FarmStructureType}
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.getStructtype = function() {
  return /** @type {!proto.cyberia.grpc.farm.FarmStructureType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cyberia.grpc.farm.FarmStructureType} value
 * @return {!proto.cyberia.grpc.farm.FarmStructure} returns this
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.setStructtype = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.farm.FarmStructure} returns this
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.clearStructtype = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.hasStructtype = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.farm.FarmStructure} returns this
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.farm.FarmStructure} returns this
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 parentId = 4;
 * @return {number}
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.getParentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.farm.FarmStructure} returns this
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.setParentid = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.farm.FarmStructure} returns this
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.clearParentid = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.hasParentid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.ObjectsId children = 5;
 * @return {?proto.common.ObjectsId}
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.getChildren = function() {
  return /** @type{?proto.common.ObjectsId} */ (
    jspb.Message.getWrapperField(this, Common_pb.ObjectsId, 5));
};


/**
 * @param {?proto.common.ObjectsId|undefined} value
 * @return {!proto.cyberia.grpc.farm.FarmStructure} returns this
*/
proto.cyberia.grpc.farm.FarmStructure.prototype.setChildren = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.farm.FarmStructure} returns this
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.clearChildren = function() {
  return this.setChildren(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.FarmStructure.prototype.hasChildren = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.farm.FarmStructureList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.farm.FarmStructureList.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.farm.FarmStructureList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.farm.FarmStructureList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.farm.FarmStructureList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.grpc.farm.FarmStructure.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.farm.FarmStructureList}
 */
proto.cyberia.grpc.farm.FarmStructureList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.farm.FarmStructureList;
  return proto.cyberia.grpc.farm.FarmStructureList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.farm.FarmStructureList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.farm.FarmStructureList}
 */
proto.cyberia.grpc.farm.FarmStructureList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.farm.FarmStructure;
      reader.readMessage(value,proto.cyberia.grpc.farm.FarmStructure.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.farm.FarmStructureList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.farm.FarmStructureList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.farm.FarmStructureList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.farm.FarmStructureList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.grpc.farm.FarmStructure.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FarmStructure list = 1;
 * @return {!Array<!proto.cyberia.grpc.farm.FarmStructure>}
 */
proto.cyberia.grpc.farm.FarmStructureList.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.farm.FarmStructure>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.grpc.farm.FarmStructure, 1));
};


/**
 * @param {!Array<!proto.cyberia.grpc.farm.FarmStructure>} value
 * @return {!proto.cyberia.grpc.farm.FarmStructureList} returns this
*/
proto.cyberia.grpc.farm.FarmStructureList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.grpc.farm.FarmStructure=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.farm.FarmStructure}
 */
proto.cyberia.grpc.farm.FarmStructureList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.grpc.farm.FarmStructure, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.farm.FarmStructureList} returns this
 */
proto.cyberia.grpc.farm.FarmStructureList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.farm.FarmStructureSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.farm.FarmStructureSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.farm.FarmStructureSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.farm.FarmStructureSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fulltextsearch: jspb.Message.getFieldWithDefault(msg, 2, ""),
    searchparentnode: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.farm.FarmStructureSearch}
 */
proto.cyberia.grpc.farm.FarmStructureSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.farm.FarmStructureSearch;
  return proto.cyberia.grpc.farm.FarmStructureSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.farm.FarmStructureSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.farm.FarmStructureSearch}
 */
proto.cyberia.grpc.farm.FarmStructureSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFulltextsearch(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSearchparentnode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.farm.FarmStructureSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.farm.FarmStructureSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.farm.FarmStructureSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.farm.FarmStructureSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 parentId = 1;
 * @return {number}
 */
proto.cyberia.grpc.farm.FarmStructureSearch.prototype.getParentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.farm.FarmStructureSearch} returns this
 */
proto.cyberia.grpc.farm.FarmStructureSearch.prototype.setParentid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.farm.FarmStructureSearch} returns this
 */
proto.cyberia.grpc.farm.FarmStructureSearch.prototype.clearParentid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.FarmStructureSearch.prototype.hasParentid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string fulltextSearch = 2;
 * @return {string}
 */
proto.cyberia.grpc.farm.FarmStructureSearch.prototype.getFulltextsearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.farm.FarmStructureSearch} returns this
 */
proto.cyberia.grpc.farm.FarmStructureSearch.prototype.setFulltextsearch = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.farm.FarmStructureSearch} returns this
 */
proto.cyberia.grpc.farm.FarmStructureSearch.prototype.clearFulltextsearch = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.FarmStructureSearch.prototype.hasFulltextsearch = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool searchParentNode = 3;
 * @return {boolean}
 */
proto.cyberia.grpc.farm.FarmStructureSearch.prototype.getSearchparentnode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cyberia.grpc.farm.FarmStructureSearch} returns this
 */
proto.cyberia.grpc.farm.FarmStructureSearch.prototype.setSearchparentnode = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.farm.FarmStructureSearch} returns this
 */
proto.cyberia.grpc.farm.FarmStructureSearch.prototype.clearSearchparentnode = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.FarmStructureSearch.prototype.hasSearchparentnode = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.cyberia.grpc.farm.FarmStructureType = {
  FARM: 0,
  CHAMBER: 1,
  SUBSTRUCTURE: 2,
  EQUIPMENT: 3,
  VIDEOCAMERA: 4,
  ACTERMINAL: 5
};

goog.object.extend(exports, proto.cyberia.grpc.farm);
