import { UiNodeInputAttributes } from '@ory/kratos-client';
import { Button, Form, Input, message } from 'antd';
import { UserIcon } from 'icons';
import { useSearchParams } from 'react-router-dom';
import { kratosClient } from 'shared';
import { domain } from 'shared';

export const RecoveryForm = () => {
	const [searchParams] = useSearchParams();
	const flow = searchParams.get('flow');

	const onFinish = async (values: any) => {
		console.log(values);
		let csrfTokenNode;

		if (flow) {
			const { data: flowData } = await kratosClient.getSelfServiceRecoveryFlow(flow);
			console.log(flowData);
			csrfTokenNode = flowData.ui.nodes[0]?.attributes as UiNodeInputAttributes;
		}

		const response = await fetch(`https://${domain}/ory/kratos/self-service/recovery?flow=${flow}`, {
				method: 'POST',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ ...values, 'method': 'link', 'csrf_token': csrfTokenNode?.value }),
			},
		);

		const statusCode = response.status;
		if (statusCode === 200) {
			message.success('Письмо отправлено на вашу почту');
			window.location.href = '/';
		} else {
			console.log('Error recovering password');
			console.log(statusCode);
			message.error(`Ошибка ${statusCode}`);
			console.log(await response.json());
		}
	};

	return (
		<Form onFinish={onFinish}>
			<h1 className="white-text login-title">Восстановление пароля</h1>
			<Form.Item name="email"
			           rules={[{ required: true, message: 'Пожалуйста, введите ваш email!' }, { type: 'email' }]}>
				<Input size="large" placeholder="E-mail" prefix={<UserIcon/>} name="email"/>
			</Form.Item>
			<Form.Item>
				<Button size="large" type="primary" htmlType="submit" className="login-button">Сбросить
					пароль</Button>
			</Form.Item>
		</Form>
	);
};