import { ATFLogo } from 'icons';
import { RecoveryForm } from 'shared';
import './recovery.page.less';

export const RecoveryPage = () => {
	return (
		<div className="central-page">
			<div className="form-div">
				<div className="div-centered">
					<ATFLogo/>
					<RecoveryForm/>
				</div>
			</div>
		</div>
	);
};