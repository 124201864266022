/**
 * @fileoverview gRPC-Web generated client stub for cyberia.grpc.event
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as event_AbstractEvent_pb from '../event/AbstractEvent_pb';
import * as event_LoginEvent_pb from '../event/LoginEvent_pb';


export class LoginEventServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorfailLogin = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.event.LoginEventService/failLogin',
    grpcWeb.MethodType.UNARY,
    event_LoginEvent_pb.FailLoginEvent,
    event_LoginEvent_pb.FailLoginEvent,
    (request: event_LoginEvent_pb.FailLoginEvent) => {
      return request.serializeBinary();
    },
    event_LoginEvent_pb.FailLoginEvent.deserializeBinary
  );

  failLogin(
    request: event_LoginEvent_pb.FailLoginEvent,
    metadata: grpcWeb.Metadata | null): Promise<event_LoginEvent_pb.FailLoginEvent>;

  failLogin(
    request: event_LoginEvent_pb.FailLoginEvent,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: event_LoginEvent_pb.FailLoginEvent) => void): grpcWeb.ClientReadableStream<event_LoginEvent_pb.FailLoginEvent>;

  failLogin(
    request: event_LoginEvent_pb.FailLoginEvent,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: event_LoginEvent_pb.FailLoginEvent) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.event.LoginEventService/failLogin',
        request,
        metadata || {},
        this.methodDescriptorfailLogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.event.LoginEventService/failLogin',
    request,
    metadata || {},
    this.methodDescriptorfailLogin);
  }

  methodDescriptorlogin = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.event.LoginEventService/login',
    grpcWeb.MethodType.UNARY,
    event_LoginEvent_pb.LoginEventNotify,
    event_AbstractEvent_pb.AbstractEvent,
    (request: event_LoginEvent_pb.LoginEventNotify) => {
      return request.serializeBinary();
    },
    event_AbstractEvent_pb.AbstractEvent.deserializeBinary
  );

  login(
    request: event_LoginEvent_pb.LoginEventNotify,
    metadata: grpcWeb.Metadata | null): Promise<event_AbstractEvent_pb.AbstractEvent>;

  login(
    request: event_LoginEvent_pb.LoginEventNotify,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: event_AbstractEvent_pb.AbstractEvent) => void): grpcWeb.ClientReadableStream<event_AbstractEvent_pb.AbstractEvent>;

  login(
    request: event_LoginEvent_pb.LoginEventNotify,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: event_AbstractEvent_pb.AbstractEvent) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.event.LoginEventService/login',
        request,
        metadata || {},
        this.methodDescriptorlogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.event.LoginEventService/login',
    request,
    metadata || {},
    this.methodDescriptorlogin);
  }

  methodDescriptorlogout = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.event.LoginEventService/logout',
    grpcWeb.MethodType.UNARY,
    event_LoginEvent_pb.LoginEventNotify,
    event_AbstractEvent_pb.AbstractEvent,
    (request: event_LoginEvent_pb.LoginEventNotify) => {
      return request.serializeBinary();
    },
    event_AbstractEvent_pb.AbstractEvent.deserializeBinary
  );

  logout(
    request: event_LoginEvent_pb.LoginEventNotify,
    metadata: grpcWeb.Metadata | null): Promise<event_AbstractEvent_pb.AbstractEvent>;

  logout(
    request: event_LoginEvent_pb.LoginEventNotify,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: event_AbstractEvent_pb.AbstractEvent) => void): grpcWeb.ClientReadableStream<event_AbstractEvent_pb.AbstractEvent>;

  logout(
    request: event_LoginEvent_pb.LoginEventNotify,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: event_AbstractEvent_pb.AbstractEvent) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.event.LoginEventService/logout',
        request,
        metadata || {},
        this.methodDescriptorlogout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.event.LoginEventService/logout',
    request,
    metadata || {},
    this.methodDescriptorlogout);
  }

}

