/**
 * @fileoverview gRPC-Web generated client stub for cyberia.grpc.user
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as Common_pb from '../Common_pb';
import * as security_UserRegRequestService_pb from '../security/UserRegRequestService_pb';


export class UserRegRequestServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorgetAvailableList = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.user.UserRegRequestService/getAvailableList',
    grpcWeb.MethodType.UNARY,
    Common_pb.Empty,
    Common_pb.ObjectsId,
    (request: Common_pb.Empty) => {
      return request.serializeBinary();
    },
    Common_pb.ObjectsId.deserializeBinary
  );

  getAvailableList(
    request: Common_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.ObjectsId>;

  getAvailableList(
    request: Common_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void): grpcWeb.ClientReadableStream<Common_pb.ObjectsId>;

  getAvailableList(
    request: Common_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.user.UserRegRequestService/getAvailableList',
        request,
        metadata || {},
        this.methodDescriptorgetAvailableList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.user.UserRegRequestService/getAvailableList',
    request,
    metadata || {},
    this.methodDescriptorgetAvailableList);
  }

  methodDescriptorgetInfo = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.user.UserRegRequestService/getInfo',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectsId,
    security_UserRegRequestService_pb.UserRegRequestInfo,
    (request: Common_pb.ObjectsId) => {
      return request.serializeBinary();
    },
    security_UserRegRequestService_pb.UserRegRequestInfo.deserializeBinary
  );

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null): Promise<security_UserRegRequestService_pb.UserRegRequestInfo>;

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: security_UserRegRequestService_pb.UserRegRequestInfo) => void): grpcWeb.ClientReadableStream<security_UserRegRequestService_pb.UserRegRequestInfo>;

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: security_UserRegRequestService_pb.UserRegRequestInfo) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.user.UserRegRequestService/getInfo',
        request,
        metadata || {},
        this.methodDescriptorgetInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.user.UserRegRequestService/getInfo',
    request,
    metadata || {},
    this.methodDescriptorgetInfo);
  }

  methodDescriptorinsert = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.user.UserRegRequestService/insert',
    grpcWeb.MethodType.UNARY,
    security_UserRegRequestService_pb.UserRegRequest,
    security_UserRegRequestService_pb.UserRegRequest,
    (request: security_UserRegRequestService_pb.UserRegRequest) => {
      return request.serializeBinary();
    },
    security_UserRegRequestService_pb.UserRegRequest.deserializeBinary
  );

  insert(
    request: security_UserRegRequestService_pb.UserRegRequest,
    metadata: grpcWeb.Metadata | null): Promise<security_UserRegRequestService_pb.UserRegRequest>;

  insert(
    request: security_UserRegRequestService_pb.UserRegRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: security_UserRegRequestService_pb.UserRegRequest) => void): grpcWeb.ClientReadableStream<security_UserRegRequestService_pb.UserRegRequest>;

  insert(
    request: security_UserRegRequestService_pb.UserRegRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: security_UserRegRequestService_pb.UserRegRequest) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.user.UserRegRequestService/insert',
        request,
        metadata || {},
        this.methodDescriptorinsert,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.user.UserRegRequestService/insert',
    request,
    metadata || {},
    this.methodDescriptorinsert);
  }

  methodDescriptordelete = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.user.UserRegRequestService/delete',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectsId,
    Common_pb.ObjectsId,
    (request: Common_pb.ObjectsId) => {
      return request.serializeBinary();
    },
    Common_pb.ObjectsId.deserializeBinary
  );

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.ObjectsId>;

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void): grpcWeb.ClientReadableStream<Common_pb.ObjectsId>;

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.user.UserRegRequestService/delete',
        request,
        metadata || {},
        this.methodDescriptordelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.user.UserRegRequestService/delete',
    request,
    metadata || {},
    this.methodDescriptordelete);
  }

  methodDescriptorcheckLoginUniq = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.user.UserRegRequestService/checkLoginUniq',
    grpcWeb.MethodType.UNARY,
    security_UserRegRequestService_pb.UserRegRequest,
    Common_pb.Check,
    (request: security_UserRegRequestService_pb.UserRegRequest) => {
      return request.serializeBinary();
    },
    Common_pb.Check.deserializeBinary
  );

  checkLoginUniq(
    request: security_UserRegRequestService_pb.UserRegRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.Check>;

  checkLoginUniq(
    request: security_UserRegRequestService_pb.UserRegRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void): grpcWeb.ClientReadableStream<Common_pb.Check>;

  checkLoginUniq(
    request: security_UserRegRequestService_pb.UserRegRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.user.UserRegRequestService/checkLoginUniq',
        request,
        metadata || {},
        this.methodDescriptorcheckLoginUniq,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.user.UserRegRequestService/checkLoginUniq',
    request,
    metadata || {},
    this.methodDescriptorcheckLoginUniq);
  }

  methodDescriptorcheckPhoneUniq = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.user.UserRegRequestService/checkPhoneUniq',
    grpcWeb.MethodType.UNARY,
    security_UserRegRequestService_pb.UserRegRequest,
    Common_pb.Check,
    (request: security_UserRegRequestService_pb.UserRegRequest) => {
      return request.serializeBinary();
    },
    Common_pb.Check.deserializeBinary
  );

  checkPhoneUniq(
    request: security_UserRegRequestService_pb.UserRegRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.Check>;

  checkPhoneUniq(
    request: security_UserRegRequestService_pb.UserRegRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void): grpcWeb.ClientReadableStream<Common_pb.Check>;

  checkPhoneUniq(
    request: security_UserRegRequestService_pb.UserRegRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.user.UserRegRequestService/checkPhoneUniq',
        request,
        metadata || {},
        this.methodDescriptorcheckPhoneUniq,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.user.UserRegRequestService/checkPhoneUniq',
    request,
    metadata || {},
    this.methodDescriptorcheckPhoneUniq);
  }

  methodDescriptorcheckEmailUniq = new grpcWeb.MethodDescriptor(
    '/cyberia.grpc.user.UserRegRequestService/checkEmailUniq',
    grpcWeb.MethodType.UNARY,
    security_UserRegRequestService_pb.UserRegRequest,
    Common_pb.Check,
    (request: security_UserRegRequestService_pb.UserRegRequest) => {
      return request.serializeBinary();
    },
    Common_pb.Check.deserializeBinary
  );

  checkEmailUniq(
    request: security_UserRegRequestService_pb.UserRegRequest,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.Check>;

  checkEmailUniq(
    request: security_UserRegRequestService_pb.UserRegRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void): grpcWeb.ClientReadableStream<Common_pb.Check>;

  checkEmailUniq(
    request: security_UserRegRequestService_pb.UserRegRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cyberia.grpc.user.UserRegRequestService/checkEmailUniq',
        request,
        metadata || {},
        this.methodDescriptorcheckEmailUniq,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cyberia.grpc.user.UserRegRequestService/checkEmailUniq',
    request,
    metadata || {},
    this.methodDescriptorcheckEmailUniq);
  }

}

