export const ATFLogo = () => (
	<svg width="217" height="83" viewBox="0 0 217 83" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M70.3164 18.2637L40.1914 0.907932C39.1606 0.316528 37.9932 0.00537109 36.8051 0.00537109C35.617 0.00537109 34.4496 0.316528 33.4188 0.907932L3.3863 18.2637C2.36599 18.8554 1.51718 19.7029 0.923426 20.7227C0.329669 21.7425 0.0114235 22.8995 1.04953e-05 24.0798V58.8653C-0.0126512 60.0704 0.294078 61.2572 0.88894 62.305C1.4838 63.3527 2.34553 64.2238 3.3863 64.8296L33.4188 82.1853C34.4513 82.773 35.6173 83.0855 36.8051 83.0929C37.9935 83.0905 39.1607 82.7777 40.1914 82.1853L70.3164 64.8296C71.3435 64.2317 72.1965 63.3751 72.7905 62.3451C73.3846 61.315 73.6991 60.1473 73.7027 58.9579V24.0798C73.6913 22.8995 73.373 21.7425 72.7793 20.7227C72.1855 19.7029 71.3367 18.8554 70.3164 18.2637ZM71.1306 25.2837V57.7169C71.1297 58.8021 70.8438 59.8679 70.3014 60.8075C69.759 61.7471 68.9792 62.5274 68.0403 63.0699L39.8953 79.2958C38.9539 79.8334 37.8889 80.1161 36.8051 80.1161C35.7213 80.1161 34.6562 79.8334 33.7149 79.2958L5.66233 63.0699C4.72347 62.5274 3.9437 61.7471 3.4013 60.8075C2.85889 59.8679 2.57293 58.8021 2.57211 57.7169V25.2837C2.57293 24.1986 2.85889 23.1327 3.4013 22.1931C3.9437 21.2535 4.72347 20.4733 5.66233 19.9307L33.7149 3.70485C34.6557 3.1667 35.7214 2.88564 36.8051 2.88986C37.8893 2.88122 38.9561 3.16258 39.8953 3.70485L67.9663 19.9307C68.9052 20.4733 69.685 21.2535 70.2274 22.1931C70.7698 23.1327 71.0557 24.1986 71.0565 25.2837H71.1306Z"
			fill="white"/>
		<path
			d="M53.2924 35.0262C58.3247 32.138 62.7288 28.2711 66.2454 23.6533C66.2454 23.6533 55.7535 25.9316 48.9994 30.303C42.2453 34.6743 38.489 38.6196 37.5638 40.8794C37.2394 41.9607 36.986 43.062 36.8051 44.1764C36.6173 43.0344 36.3702 41.903 36.0649 40.7868C35.1212 38.49 31.4943 34.8595 24.6292 30.303C17.7641 25.7464 7.40173 23.6533 7.40173 23.6533C10.9136 28.2756 15.3187 32.1433 20.3547 35.0262C24.2338 37.4199 28.3364 39.4298 32.6046 41.0276C33.3758 41.3049 34.0475 41.8049 34.535 42.4642C35.0224 43.1236 35.3037 43.9128 35.3432 44.7321L36.1759 62.1063C33.3676 58.4451 29.9346 55.3097 26.0356 52.845C21.5285 50.2405 16.6386 48.3651 11.5467 47.2882C14.4995 51.1745 18.1989 54.4304 22.4272 56.8644C25.9801 59.0764 29.7654 60.8902 33.7149 62.273C34.461 62.5209 35.1139 62.9905 35.5867 63.6193C36.0594 64.248 36.3295 65.0061 36.361 65.7924L36.8236 75.702L36.9901 71.9974L37.2677 65.7738C37.298 64.985 37.5674 64.2241 38.04 63.5922C38.5127 62.9603 39.1662 62.4873 39.9138 62.236C43.868 60.8506 47.6592 59.037 51.2199 56.8274C55.4422 54.3921 59.1353 51.1362 62.082 47.2512C56.9901 48.328 52.1002 50.2034 47.5931 52.808C43.6937 55.2792 40.2558 58.4134 37.4342 62.0693L38.2114 44.7136C38.2499 43.8914 38.533 43.0995 39.0242 42.4394C39.5155 41.7794 40.1925 41.2813 40.9685 41.009C45.2697 39.4043 49.4035 37.3819 53.3109 34.9707"
			fill="white"/>
		<path
			d="M98.6095 71.3121L101.459 64.5698H102.662L105.475 71.3121H104.309L103.717 69.8488H100.367L99.7753 71.3121H98.6095ZM100.756 68.8856H103.365L102.181 66.0146L102.051 65.6256C102.022 65.7573 101.985 65.8872 101.94 66.0146L100.756 68.8856Z"
			fill="white"/>
		<path
			d="M111.211 64.4402C111.914 64.4334 112.61 64.5854 113.246 64.8847V65.9405C112.684 65.614 112.046 65.4416 111.396 65.4404C111.053 65.4086 110.708 65.4503 110.382 65.5627C110.057 65.675 109.759 65.8555 109.508 66.0921C109.258 66.3287 109.061 66.6159 108.93 66.9347C108.799 67.2535 108.738 67.5965 108.75 67.941C108.775 68.3674 108.903 68.7815 109.121 69.1484C109.34 69.5153 109.643 69.8243 110.006 70.0494C110.368 70.2745 110.78 70.4091 111.205 70.4418C111.631 70.4746 112.058 70.4046 112.451 70.2378V67.6816H113.524V70.9787C112.848 71.2768 112.116 71.4283 111.377 71.4232C110.897 71.4776 110.411 71.4267 109.952 71.2741C109.494 71.1215 109.074 70.8709 108.722 70.5396C108.37 70.2082 108.094 69.8042 107.913 69.3555C107.733 68.9067 107.652 68.4241 107.677 67.941C107.659 67.4588 107.744 66.9783 107.926 66.5317C108.109 66.0852 108.384 65.6827 108.735 65.3513C109.085 65.0199 109.502 64.7672 109.958 64.6101C110.414 64.4529 110.898 64.395 111.377 64.4402"
			fill="white"/>
		<path
			d="M116.688 71.3121V64.5698H119.39C120.648 64.5698 121.98 65.0699 121.98 66.6629C122.001 66.9063 121.973 67.1513 121.898 67.3838C121.823 67.6163 121.703 67.8317 121.545 68.0176C121.387 68.2036 121.193 68.3563 120.976 68.4671C120.758 68.578 120.521 68.6446 120.278 68.6633L122.499 71.3121H121.166L119.149 68.7745H117.78V71.3121H116.688ZM117.78 67.8113H119.427C120.148 67.8113 120.852 67.552 120.852 66.6814C120.852 65.8108 120.148 65.57 119.427 65.57H117.78V67.8113Z"
			fill="white"/>
		<path
			d="M128.364 64.4404C129.055 64.4477 129.727 64.6592 130.297 65.0483C130.867 65.4374 131.31 65.9866 131.569 66.6269C131.828 67.2672 131.892 67.9699 131.753 68.6466C131.614 69.3232 131.278 69.9435 130.787 70.4294C130.297 70.9153 129.674 71.245 128.996 71.3771C128.319 71.5092 127.618 71.4378 126.981 71.1718C126.344 70.9057 125.8 70.4571 125.417 69.8822C125.034 69.3073 124.83 68.632 124.83 67.9412C124.809 67.4726 124.886 67.0047 125.056 66.5677C125.227 66.1308 125.487 65.7345 125.82 65.4046C126.153 65.0746 126.552 64.8183 126.99 64.6522C127.428 64.4861 127.897 64.414 128.364 64.4404ZM128.364 65.4591C127.872 65.4481 127.387 65.5842 126.972 65.8501C126.557 66.1161 126.231 66.4997 126.034 66.9522C125.838 67.4046 125.781 67.9053 125.87 68.3904C125.96 68.8755 126.191 69.3229 126.536 69.6756C126.88 70.0283 127.322 70.2703 127.804 70.3706C128.287 70.471 128.788 70.4252 129.245 70.2391C129.701 70.053 130.091 69.735 130.366 69.3257C130.641 68.9164 130.788 68.4344 130.789 67.9412C130.813 67.6138 130.767 67.2851 130.654 66.9768C130.542 66.6685 130.365 66.3876 130.136 66.1529C129.906 65.9182 129.63 65.735 129.325 65.6154C129.019 65.4957 128.692 65.4425 128.364 65.4591Z"
			fill="white"/>
		<path d="M143.778 64.5698V65.57H141.558V71.3121H140.466V65.57H138.227V64.5698H143.778Z" fill="white"/>
		<path
			d="M151.291 64.5698V65.57H147.757V67.4038H151.014V68.404H147.757V70.2933H151.347V71.3121H146.647V64.5698H151.291Z"
			fill="white"/>
		<path
			d="M157.601 64.4404C158.244 64.4443 158.877 64.5964 159.452 64.885V65.9407C158.933 65.6071 158.328 65.4332 157.712 65.4406C157.367 65.4108 157.02 65.4531 156.692 65.5648C156.365 65.6765 156.064 65.8551 155.809 66.0893C155.554 66.3236 155.35 66.6083 155.211 66.9255C155.071 67.2427 154.999 67.5854 154.999 67.9319C154.999 68.2784 155.071 68.6212 155.211 68.9384C155.35 69.2555 155.554 69.5403 155.809 69.7745C156.064 70.0088 156.365 70.1874 156.692 70.2991C157.02 70.4107 157.367 70.453 157.712 70.4232C158.369 70.4597 159.02 70.2773 159.563 69.9046V70.9789C158.954 71.2818 158.281 71.4342 157.601 71.4235C157.122 71.4584 156.64 71.3941 156.187 71.2345C155.733 71.0749 155.318 70.8234 154.966 70.4958C154.614 70.1681 154.333 69.7713 154.141 69.3302C153.949 68.8891 153.85 68.4131 153.85 67.9319C153.85 67.4508 153.949 66.9748 154.141 66.5336C154.333 66.0925 154.614 65.6958 154.966 65.3681C155.318 65.0405 155.733 64.789 156.187 64.6294C156.64 64.4698 157.122 64.4054 157.601 64.4404Z"
			fill="white"/>
		<path
			d="M168.389 64.5698V71.3121H167.297V68.4411H163.745V71.3121H162.653V64.5698H163.745V67.4223H167.297V64.5698H168.389Z"
			fill="white"/>
		<path d="M180.769 64.5698V65.57H177.234V67.552H180.491V68.5707H177.234V71.3121H176.124V64.5698H180.769Z"
		      fill="white"/>
		<path
			d="M182.582 71.3121L185.413 64.5698H186.634L189.466 71.3121H188.318L187.708 69.8488H184.34L183.748 71.3121H182.582ZM184.71 68.8856H187.319L186.153 66.0146C186.102 65.8879 186.059 65.7579 186.024 65.6256L185.894 66.0146L184.71 68.8856Z"
			fill="white"/>
		<path
			d="M192.26 71.3121V64.5698H194.943C196.201 64.5698 197.533 65.0699 197.533 66.6629C197.575 67.1428 197.429 67.6202 197.125 67.9936C196.821 68.3671 196.383 68.6073 195.905 68.6633L197.996 71.3121H196.682L194.665 68.7745H193.296V71.3121H192.26ZM193.352 67.8113H194.998C195.72 67.8113 196.423 67.552 196.423 66.6814C196.423 65.8108 195.72 65.57 194.998 65.57H193.352V67.8113Z"
			fill="white"/>
		<path
			d="M208.007 64.5698V71.3121H206.915V66.5888L204.787 69.7747H204.121L201.993 66.5703V71.3121H200.901V64.5698H201.901L204.454 68.4225L207.026 64.5698H208.007Z"
			fill="white"/>
		<path
			d="M98.6835 54.5495L112.876 19.9678C112.989 19.703 113.177 19.4774 113.418 19.3193C113.658 19.1611 113.94 19.0774 114.227 19.0787H120.944C121.23 19.0762 121.509 19.1596 121.747 19.3182C121.984 19.4767 122.169 19.703 122.277 19.9678L136.34 54.5495C136.426 54.7693 136.459 55.0068 136.434 55.2417C136.409 55.4767 136.327 55.7021 136.196 55.8986C136.065 56.0952 135.889 56.2572 135.682 56.3706C135.475 56.484 135.243 56.5456 135.007 56.55H133.416C132.077 56.5488 130.769 56.1434 129.663 55.3867C128.557 54.63 127.705 53.5572 127.217 52.3083L126.144 49.5299H109.916C109.627 49.5326 109.345 49.62 109.105 49.7813C108.864 49.9426 108.677 50.1707 108.565 50.4375L107.825 52.2898C107.333 53.5288 106.481 54.5918 105.379 55.3413C104.277 56.0908 102.976 56.4925 101.644 56.4944H100.016C99.7798 56.49 99.5484 56.4284 99.3414 56.315C99.1343 56.2016 98.9578 56.0397 98.8269 55.8431C98.6959 55.6465 98.6145 55.4211 98.5896 55.1862C98.5646 54.9512 98.5968 54.7137 98.6835 54.494V54.5495ZM113.487 42.8432H121.351C121.585 42.8377 121.814 42.7762 122.019 42.6638C122.224 42.5514 122.399 42.3914 122.53 42.1973C122.66 42.0031 122.742 41.7805 122.769 41.548C122.796 41.3156 122.767 41.0801 122.684 40.8613L118.298 29.4514C117.951 28.2972 117.679 27.1216 117.484 25.932C117.288 27.1302 116.985 28.3081 116.577 29.4514L112.155 40.9169C112.065 41.1414 112.032 41.385 112.061 41.6253C112.089 41.8656 112.177 42.0949 112.317 42.2922C112.457 42.4895 112.644 42.6484 112.862 42.7544C113.079 42.8604 113.319 42.91 113.561 42.8988L113.487 42.8432Z"
			fill="white"/>
		<path
			d="M175.162 20.8564V24.5609C175.162 25.0522 174.967 25.5233 174.62 25.8707C174.273 26.218 173.802 26.4132 173.311 26.4132H165.447C164.956 26.4132 164.486 26.6083 164.139 26.9557C163.792 27.3031 163.597 27.7742 163.597 28.2654V54.7528C163.597 55.2441 163.402 55.7152 163.055 56.0626C162.708 56.4099 162.237 56.6051 161.746 56.6051H157.49C156.999 56.6051 156.529 56.4099 156.182 56.0626C155.835 55.7152 155.64 55.2441 155.64 54.7528V28.0987C155.64 27.6075 155.445 27.1363 155.098 26.789C154.751 26.4416 154.28 26.2465 153.789 26.2465H145.98C145.49 26.2465 145.019 26.0513 144.672 25.7039C144.325 25.3566 144.13 24.8854 144.13 24.3942V20.6897C144.13 20.1984 144.325 19.7273 144.672 19.3799C145.019 19.0326 145.49 18.8374 145.98 18.8374H173.367C173.858 18.8374 174.328 19.0326 174.675 19.3799C175.022 19.7273 175.217 20.1984 175.217 20.6897"
			fill="white"/>
		<path
			d="M217 20.616V24.7651C217 25.1728 216.838 25.5638 216.55 25.8522C216.262 26.1405 215.872 26.3024 215.464 26.3024H200.143C199.735 26.3024 199.345 26.4644 199.057 26.7527C198.769 27.041 198.607 27.4321 198.607 27.8398V33.3966C198.607 33.8044 198.769 34.1954 199.057 34.4837C199.345 34.772 199.735 34.934 200.143 34.934H212.615C213.024 34.934 213.416 35.0954 213.707 35.3831C213.998 35.6709 214.164 36.0619 214.169 36.4714V37.0456C214.169 37.7291 214.034 38.4059 213.773 39.0374C213.512 39.6689 213.129 40.2427 212.646 40.726C212.163 41.2093 211.59 41.5927 210.959 41.8543C210.328 42.1158 209.652 42.2504 208.969 42.2504H200.143C199.735 42.2504 199.345 42.4124 199.057 42.7007C198.769 42.989 198.607 43.3801 198.607 43.7878V54.994C198.607 55.4035 198.446 55.7965 198.158 56.0877C197.871 56.379 197.48 56.5451 197.071 56.5499H192.112C191.703 56.5451 191.312 56.379 191.025 56.0877C190.737 55.7965 190.576 55.4035 190.576 54.994V20.616C190.576 20.2083 190.738 19.8172 191.026 19.5289C191.314 19.2406 191.704 19.0786 192.112 19.0786H215.464C215.872 19.0786 216.262 19.2406 216.55 19.5289C216.838 19.8172 217 20.2083 217 20.616Z"
			fill="white"/>
	</svg>
);