import { ATFLogo } from 'icons';
import { LoginForm } from 'shared';
import './login.page.less';

export const LoginPage = () => {
	return (
		<div className="central-page">
			<div className='form-div'>
				<div className="central-div">
					<ATFLogo/>
					<LoginForm/>
				</div>
			</div>
		</div>
	);
};
