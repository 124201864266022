// source: process/ProcessMode.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var Common_pb = require('../Common_pb.js');
goog.object.extend(proto, Common_pb);
var process_ModeCategoryPP_pb = require('../process/ModeCategoryPP_pb.js');
goog.object.extend(proto, process_ModeCategoryPP_pb);
var web_equipment_ParametersService_pb = require('../web/equipment/ParametersService_pb.js');
goog.object.extend(proto, web_equipment_ParametersService_pb);
goog.exportSymbol('proto.cyberia.grpc.process.HysteresisValue', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.ModeItem', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.ModeItemsList', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.ModeOfProcess', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.ModeOfProcessList', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.ModeSingleValue', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.PeriodicEnable', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.ModeOfProcess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.process.ModeOfProcess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.ModeOfProcess.displayName = 'proto.cyberia.grpc.process.ModeOfProcess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.ModeSingleValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.process.ModeSingleValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.ModeSingleValue.displayName = 'proto.cyberia.grpc.process.ModeSingleValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.PeriodicEnable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.process.PeriodicEnable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.PeriodicEnable.displayName = 'proto.cyberia.grpc.process.PeriodicEnable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.HysteresisValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.process.HysteresisValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.HysteresisValue.displayName = 'proto.cyberia.grpc.process.HysteresisValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.ModeItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.process.ModeItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.ModeItem.displayName = 'proto.cyberia.grpc.process.ModeItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.ModeItemsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.process.ModeItemsList.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.process.ModeItemsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.ModeItemsList.displayName = 'proto.cyberia.grpc.process.ModeItemsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.ModeOfProcessList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.process.ModeOfProcessList.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.process.ModeOfProcessList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.ModeOfProcessList.displayName = 'proto.cyberia.grpc.process.ModeOfProcessList';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.ModeOfProcess.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.ModeOfProcess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.ModeOfProcess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ModeOfProcess.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    category: (f = msg.getCategory()) && process_ModeCategoryPP_pb.ModeCategory.toObject(includeInstance, f),
    items: (f = msg.getItems()) && proto.cyberia.grpc.process.ModeItemsList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.ModeOfProcess}
 */
proto.cyberia.grpc.process.ModeOfProcess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.ModeOfProcess;
  return proto.cyberia.grpc.process.ModeOfProcess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.ModeOfProcess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.ModeOfProcess}
 */
proto.cyberia.grpc.process.ModeOfProcess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = new process_ModeCategoryPP_pb.ModeCategory;
      reader.readMessage(value,process_ModeCategoryPP_pb.ModeCategory.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    case 4:
      var value = new proto.cyberia.grpc.process.ModeItemsList;
      reader.readMessage(value,proto.cyberia.grpc.process.ModeItemsList.deserializeBinaryFromReader);
      msg.setItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.ModeOfProcess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.ModeOfProcess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.ModeOfProcess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ModeOfProcess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      process_ModeCategoryPP_pb.ModeCategory.serializeBinaryToWriter
    );
  }
  f = message.getItems();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.cyberia.grpc.process.ModeItemsList.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cyberia.grpc.process.ModeOfProcess.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.ModeOfProcess} returns this
 */
proto.cyberia.grpc.process.ModeOfProcess.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.ModeOfProcess} returns this
 */
proto.cyberia.grpc.process.ModeOfProcess.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ModeOfProcess.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.cyberia.grpc.process.ModeOfProcess.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.process.ModeOfProcess} returns this
 */
proto.cyberia.grpc.process.ModeOfProcess.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.ModeOfProcess} returns this
 */
proto.cyberia.grpc.process.ModeOfProcess.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ModeOfProcess.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ModeCategory category = 3;
 * @return {?proto.cyberia.grpc.process.ModeCategory}
 */
proto.cyberia.grpc.process.ModeOfProcess.prototype.getCategory = function() {
  return /** @type{?proto.cyberia.grpc.process.ModeCategory} */ (
    jspb.Message.getWrapperField(this, process_ModeCategoryPP_pb.ModeCategory, 3));
};


/**
 * @param {?proto.cyberia.grpc.process.ModeCategory|undefined} value
 * @return {!proto.cyberia.grpc.process.ModeOfProcess} returns this
*/
proto.cyberia.grpc.process.ModeOfProcess.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.ModeOfProcess} returns this
 */
proto.cyberia.grpc.process.ModeOfProcess.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ModeOfProcess.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ModeItemsList items = 4;
 * @return {?proto.cyberia.grpc.process.ModeItemsList}
 */
proto.cyberia.grpc.process.ModeOfProcess.prototype.getItems = function() {
  return /** @type{?proto.cyberia.grpc.process.ModeItemsList} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.grpc.process.ModeItemsList, 4));
};


/**
 * @param {?proto.cyberia.grpc.process.ModeItemsList|undefined} value
 * @return {!proto.cyberia.grpc.process.ModeOfProcess} returns this
*/
proto.cyberia.grpc.process.ModeOfProcess.prototype.setItems = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.ModeOfProcess} returns this
 */
proto.cyberia.grpc.process.ModeOfProcess.prototype.clearItems = function() {
  return this.setItems(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ModeOfProcess.prototype.hasItems = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.ModeSingleValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.ModeSingleValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ModeSingleValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    numeric: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    dateortime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    text: jspb.Message.getFieldWithDefault(msg, 3, ""),
    turnon: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.ModeSingleValue}
 */
proto.cyberia.grpc.process.ModeSingleValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.ModeSingleValue;
  return proto.cyberia.grpc.process.ModeSingleValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.ModeSingleValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.ModeSingleValue}
 */
proto.cyberia.grpc.process.ModeSingleValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNumeric(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDateortime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTurnon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.ModeSingleValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.ModeSingleValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ModeSingleValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional double numeric = 1;
 * @return {number}
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.getNumeric = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.ModeSingleValue} returns this
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.setNumeric = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.ModeSingleValue} returns this
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.clearNumeric = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.hasNumeric = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 dateOrTime = 2;
 * @return {number}
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.getDateortime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.ModeSingleValue} returns this
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.setDateortime = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.ModeSingleValue} returns this
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.clearDateortime = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.hasDateortime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string text = 3;
 * @return {string}
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.process.ModeSingleValue} returns this
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.setText = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.ModeSingleValue} returns this
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.clearText = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.hasText = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool turnOn = 4;
 * @return {boolean}
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.getTurnon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cyberia.grpc.process.ModeSingleValue} returns this
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.setTurnon = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.ModeSingleValue} returns this
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.clearTurnon = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ModeSingleValue.prototype.hasTurnon = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.PeriodicEnable.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.PeriodicEnable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.PeriodicEnable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.PeriodicEnable.toObject = function(includeInstance, msg) {
  var f, obj = {
    worktimesec: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    sleeptimesec: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.PeriodicEnable}
 */
proto.cyberia.grpc.process.PeriodicEnable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.PeriodicEnable;
  return proto.cyberia.grpc.process.PeriodicEnable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.PeriodicEnable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.PeriodicEnable}
 */
proto.cyberia.grpc.process.PeriodicEnable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWorktimesec(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSleeptimesec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.PeriodicEnable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.PeriodicEnable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.PeriodicEnable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.PeriodicEnable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorktimesec();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getSleeptimesec();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float workTimeSec = 1;
 * @return {number}
 */
proto.cyberia.grpc.process.PeriodicEnable.prototype.getWorktimesec = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.PeriodicEnable} returns this
 */
proto.cyberia.grpc.process.PeriodicEnable.prototype.setWorktimesec = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float sleepTimeSec = 2;
 * @return {number}
 */
proto.cyberia.grpc.process.PeriodicEnable.prototype.getSleeptimesec = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.PeriodicEnable} returns this
 */
proto.cyberia.grpc.process.PeriodicEnable.prototype.setSleeptimesec = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.HysteresisValue.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.HysteresisValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.HysteresisValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.HysteresisValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    minimalvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    maximalvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    work: (f = msg.getWork()) && proto.cyberia.grpc.process.PeriodicEnable.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.HysteresisValue}
 */
proto.cyberia.grpc.process.HysteresisValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.HysteresisValue;
  return proto.cyberia.grpc.process.HysteresisValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.HysteresisValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.HysteresisValue}
 */
proto.cyberia.grpc.process.HysteresisValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinimalvalue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaximalvalue(value);
      break;
    case 3:
      var value = new proto.cyberia.grpc.process.PeriodicEnable;
      reader.readMessage(value,proto.cyberia.grpc.process.PeriodicEnable.deserializeBinaryFromReader);
      msg.setWork(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.HysteresisValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.HysteresisValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.HysteresisValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.HysteresisValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinimalvalue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getMaximalvalue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getWork();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cyberia.grpc.process.PeriodicEnable.serializeBinaryToWriter
    );
  }
};


/**
 * optional double minimalValue = 1;
 * @return {number}
 */
proto.cyberia.grpc.process.HysteresisValue.prototype.getMinimalvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.HysteresisValue} returns this
 */
proto.cyberia.grpc.process.HysteresisValue.prototype.setMinimalvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double maximalValue = 2;
 * @return {number}
 */
proto.cyberia.grpc.process.HysteresisValue.prototype.getMaximalvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.HysteresisValue} returns this
 */
proto.cyberia.grpc.process.HysteresisValue.prototype.setMaximalvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional PeriodicEnable work = 3;
 * @return {?proto.cyberia.grpc.process.PeriodicEnable}
 */
proto.cyberia.grpc.process.HysteresisValue.prototype.getWork = function() {
  return /** @type{?proto.cyberia.grpc.process.PeriodicEnable} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.grpc.process.PeriodicEnable, 3));
};


/**
 * @param {?proto.cyberia.grpc.process.PeriodicEnable|undefined} value
 * @return {!proto.cyberia.grpc.process.HysteresisValue} returns this
*/
proto.cyberia.grpc.process.HysteresisValue.prototype.setWork = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.HysteresisValue} returns this
 */
proto.cyberia.grpc.process.HysteresisValue.prototype.clearWork = function() {
  return this.setWork(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.HysteresisValue.prototype.hasWork = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.ModeItem.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.ModeItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.ModeItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ModeItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    parameter: (f = msg.getParameter()) && web_equipment_ParametersService_pb.ParameterType.toObject(includeInstance, f),
    single: (f = msg.getSingle()) && proto.cyberia.grpc.process.ModeSingleValue.toObject(includeInstance, f),
    periodic: (f = msg.getPeriodic()) && proto.cyberia.grpc.process.PeriodicEnable.toObject(includeInstance, f),
    hysteresis: (f = msg.getHysteresis()) && proto.cyberia.grpc.process.HysteresisValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.ModeItem}
 */
proto.cyberia.grpc.process.ModeItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.ModeItem;
  return proto.cyberia.grpc.process.ModeItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.ModeItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.ModeItem}
 */
proto.cyberia.grpc.process.ModeItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new web_equipment_ParametersService_pb.ParameterType;
      reader.readMessage(value,web_equipment_ParametersService_pb.ParameterType.deserializeBinaryFromReader);
      msg.setParameter(value);
      break;
    case 2:
      var value = new proto.cyberia.grpc.process.ModeSingleValue;
      reader.readMessage(value,proto.cyberia.grpc.process.ModeSingleValue.deserializeBinaryFromReader);
      msg.setSingle(value);
      break;
    case 3:
      var value = new proto.cyberia.grpc.process.PeriodicEnable;
      reader.readMessage(value,proto.cyberia.grpc.process.PeriodicEnable.deserializeBinaryFromReader);
      msg.setPeriodic(value);
      break;
    case 4:
      var value = new proto.cyberia.grpc.process.HysteresisValue;
      reader.readMessage(value,proto.cyberia.grpc.process.HysteresisValue.deserializeBinaryFromReader);
      msg.setHysteresis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.ModeItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.ModeItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.ModeItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ModeItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParameter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      web_equipment_ParametersService_pb.ParameterType.serializeBinaryToWriter
    );
  }
  f = message.getSingle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.cyberia.grpc.process.ModeSingleValue.serializeBinaryToWriter
    );
  }
  f = message.getPeriodic();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cyberia.grpc.process.PeriodicEnable.serializeBinaryToWriter
    );
  }
  f = message.getHysteresis();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.cyberia.grpc.process.HysteresisValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional cyberia.web.equipment.ParameterType parameter = 1;
 * @return {?proto.cyberia.web.equipment.ParameterType}
 */
proto.cyberia.grpc.process.ModeItem.prototype.getParameter = function() {
  return /** @type{?proto.cyberia.web.equipment.ParameterType} */ (
    jspb.Message.getWrapperField(this, web_equipment_ParametersService_pb.ParameterType, 1));
};


/**
 * @param {?proto.cyberia.web.equipment.ParameterType|undefined} value
 * @return {!proto.cyberia.grpc.process.ModeItem} returns this
*/
proto.cyberia.grpc.process.ModeItem.prototype.setParameter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.ModeItem} returns this
 */
proto.cyberia.grpc.process.ModeItem.prototype.clearParameter = function() {
  return this.setParameter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ModeItem.prototype.hasParameter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ModeSingleValue single = 2;
 * @return {?proto.cyberia.grpc.process.ModeSingleValue}
 */
proto.cyberia.grpc.process.ModeItem.prototype.getSingle = function() {
  return /** @type{?proto.cyberia.grpc.process.ModeSingleValue} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.grpc.process.ModeSingleValue, 2));
};


/**
 * @param {?proto.cyberia.grpc.process.ModeSingleValue|undefined} value
 * @return {!proto.cyberia.grpc.process.ModeItem} returns this
*/
proto.cyberia.grpc.process.ModeItem.prototype.setSingle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.ModeItem} returns this
 */
proto.cyberia.grpc.process.ModeItem.prototype.clearSingle = function() {
  return this.setSingle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ModeItem.prototype.hasSingle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PeriodicEnable periodic = 3;
 * @return {?proto.cyberia.grpc.process.PeriodicEnable}
 */
proto.cyberia.grpc.process.ModeItem.prototype.getPeriodic = function() {
  return /** @type{?proto.cyberia.grpc.process.PeriodicEnable} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.grpc.process.PeriodicEnable, 3));
};


/**
 * @param {?proto.cyberia.grpc.process.PeriodicEnable|undefined} value
 * @return {!proto.cyberia.grpc.process.ModeItem} returns this
*/
proto.cyberia.grpc.process.ModeItem.prototype.setPeriodic = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.ModeItem} returns this
 */
proto.cyberia.grpc.process.ModeItem.prototype.clearPeriodic = function() {
  return this.setPeriodic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ModeItem.prototype.hasPeriodic = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional HysteresisValue hysteresis = 4;
 * @return {?proto.cyberia.grpc.process.HysteresisValue}
 */
proto.cyberia.grpc.process.ModeItem.prototype.getHysteresis = function() {
  return /** @type{?proto.cyberia.grpc.process.HysteresisValue} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.grpc.process.HysteresisValue, 4));
};


/**
 * @param {?proto.cyberia.grpc.process.HysteresisValue|undefined} value
 * @return {!proto.cyberia.grpc.process.ModeItem} returns this
*/
proto.cyberia.grpc.process.ModeItem.prototype.setHysteresis = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.ModeItem} returns this
 */
proto.cyberia.grpc.process.ModeItem.prototype.clearHysteresis = function() {
  return this.setHysteresis(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ModeItem.prototype.hasHysteresis = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.process.ModeItemsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.ModeItemsList.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.ModeItemsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.ModeItemsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ModeItemsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.cyberia.grpc.process.ModeItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.ModeItemsList}
 */
proto.cyberia.grpc.process.ModeItemsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.ModeItemsList;
  return proto.cyberia.grpc.process.ModeItemsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.ModeItemsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.ModeItemsList}
 */
proto.cyberia.grpc.process.ModeItemsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.process.ModeItem;
      reader.readMessage(value,proto.cyberia.grpc.process.ModeItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.ModeItemsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.ModeItemsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.ModeItemsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ModeItemsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.grpc.process.ModeItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ModeItem items = 1;
 * @return {!Array<!proto.cyberia.grpc.process.ModeItem>}
 */
proto.cyberia.grpc.process.ModeItemsList.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.process.ModeItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.grpc.process.ModeItem, 1));
};


/**
 * @param {!Array<!proto.cyberia.grpc.process.ModeItem>} value
 * @return {!proto.cyberia.grpc.process.ModeItemsList} returns this
*/
proto.cyberia.grpc.process.ModeItemsList.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.grpc.process.ModeItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.process.ModeItem}
 */
proto.cyberia.grpc.process.ModeItemsList.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.grpc.process.ModeItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.process.ModeItemsList} returns this
 */
proto.cyberia.grpc.process.ModeItemsList.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.process.ModeOfProcessList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.ModeOfProcessList.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.ModeOfProcessList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.ModeOfProcessList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ModeOfProcessList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.grpc.process.ModeOfProcess.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.ModeOfProcessList}
 */
proto.cyberia.grpc.process.ModeOfProcessList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.ModeOfProcessList;
  return proto.cyberia.grpc.process.ModeOfProcessList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.ModeOfProcessList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.ModeOfProcessList}
 */
proto.cyberia.grpc.process.ModeOfProcessList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.process.ModeOfProcess;
      reader.readMessage(value,proto.cyberia.grpc.process.ModeOfProcess.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.ModeOfProcessList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.ModeOfProcessList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.ModeOfProcessList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ModeOfProcessList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.grpc.process.ModeOfProcess.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ModeOfProcess list = 1;
 * @return {!Array<!proto.cyberia.grpc.process.ModeOfProcess>}
 */
proto.cyberia.grpc.process.ModeOfProcessList.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.process.ModeOfProcess>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.grpc.process.ModeOfProcess, 1));
};


/**
 * @param {!Array<!proto.cyberia.grpc.process.ModeOfProcess>} value
 * @return {!proto.cyberia.grpc.process.ModeOfProcessList} returns this
*/
proto.cyberia.grpc.process.ModeOfProcessList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.grpc.process.ModeOfProcess=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.process.ModeOfProcess}
 */
proto.cyberia.grpc.process.ModeOfProcessList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.grpc.process.ModeOfProcess, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.process.ModeOfProcessList} returns this
 */
proto.cyberia.grpc.process.ModeOfProcessList.prototype.clearListList = function() {
  return this.setListList([]);
};


goog.object.extend(exports, proto.cyberia.grpc.process);
