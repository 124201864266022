import { UiNodeInputAttributes } from '@ory/kratos-client';
import { Button, Col, Form, Input, Row } from 'antd';
import { LockIcon, UserIcon } from 'icons';
import { Exclude } from 'icons/Exclude';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { domain, kratosClient } from 'shared';
import { registrationUrl } from 'shared/lib';
import { Time } from '../../../proto/Common_pb';
import { AbstractEvent } from '../../../proto/event/AbstractEvent_pb';
import { FailLoginEvent } from '../../../proto/event/LoginEvent_pb';
import { LoginEventServiceClient } from '../../../proto/event/LoginEventServiceClientPb';
import './login.form.less';


export const LoginForm = () => {
	const [searchParams] = useSearchParams();
	const flow = searchParams.get('flow');
	const [badCredentialsError, setBadCredentialsError] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const loginEventServiceClient = new LoginEventServiceClient(`https://${domain}/api`);

	useEffect(() => {
		if (flow) {
			kratosClient.getSelfServiceLoginFlow(flow).then(({ data }) => {
				console.log(data);
			}).catch(() => {
				window.location.href = `https://${domain}/`;
			});
		}
	}, []);

	const onFinish = async (values: any) => {
		console.log('Received values of form: ', values);
		setButtonLoading(true);
		let returnUrl;

		let csrfTokenNode;
		if (flow) {
			const { data: flowData } = await kratosClient.getSelfServiceLoginFlow(flow);
			console.log(flowData);
			returnUrl = flowData.return_to;
			csrfTokenNode = flowData.ui.nodes[0]?.attributes as UiNodeInputAttributes;
		}

		const response = await fetch(`https://${domain}/ory/kratos/self-service/login?flow=${flow}`, {
				method: 'POST',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ ...values, method: 'password', 'csrf_token': csrfTokenNode?.value }),
			},
		);
		const statusCode = response.status;
		if (statusCode === 200 && returnUrl) {
			window.location.href = returnUrl;
		} else {
			if (statusCode === 400 || statusCode === 401)
				setBadCredentialsError(true);
			console.log('Error logging in');
			console.log(statusCode, badCredentialsError);
			console.log(await response.json());
			loginEventServiceClient.failLogin(new FailLoginEvent().setLogin(values.identifier).setBase(
					new AbstractEvent().setTime(new Time().setUnixtime(new Date().getTime()))),
				{}).then(console.log).catch(console.error);
		}
		setButtonLoading(false);
	};

	return (
		<div className="main-div">
			<Form className="login-form" onFinish={onFinish}>
				<h1 className="white-text login-title">Авторизация</h1>
				<Form.Item name="identifier"
				           rules={[{ required: true, message: 'Пожалуйста, введите ваш email!' }]}>
					<Input size="large" placeholder="E-mail" prefix={<UserIcon/>} name="identifier"/>
				</Form.Item>
				<Form.Item name="password" rules={[{ required: true, message: 'Пожалуйста, введите ваш пароль!' }]}>
					<Input.Password size="large" placeholder="Пароль" prefix={<LockIcon/>} name="password"/>
				</Form.Item>
				<Form.Item>
					<Row>
						<Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
							{badCredentialsError ? (
								<div className="bad-credentials">
									<Exclude/>
									<p className="dark-text bad-credentials-text">Неверный логин или пароль</p>
								</div>
							) : null}
						</Col>
						<Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} className="forget-password">
							<a className="dark-text underlined" href="/ory/kratos/self-service/recovery/browser">Забыли
								пароль?</a>
						</Col>
					</Row>
				</Form.Item>
				<Form.Item>
					<Button loading={buttonLoading} size="large" type="primary" htmlType="submit" className="login-button">Войти</Button>
				</Form.Item>
			</Form>
			<a className="dark-text underlined" href={registrationUrl}>Запрос на регистрацию</a>
		</div>
	);
};
