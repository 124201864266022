import { ATFLogo } from '../../icons';
import { SettingsForm } from '../../shared';

export const SettingsPage = () => {
	return (
		<div className="central-page">
			<div className="form-div">
				<div className="div-centered">
					<ATFLogo/>
					<SettingsForm/>
				</div>
			</div>
		</div>
	);
};