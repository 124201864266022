export const UserAvatarIcon = () => (
	<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="0.5" width="139" height="139" rx="3.5" stroke="#CECECE"/>
		<path fill-rule="evenodd" clip-rule="evenodd"
		      d="M88 100C95.732 100 102 93.732 102 86C102 78.268 95.732 72 88 72C80.268 72 74 78.268 74 86C74 93.732 80.268 100 88 100ZM96.4 87.5057V84.0664H89.9695V77.6H86.0473V84.0664H79.6V87.5057H86.0473V94.4H89.9695V87.5057H96.4Z"
		      fill="#CECECE"/>
		<path fill-rule="evenodd" clip-rule="evenodd"
		      d="M83.4297 68.5853C83.5628 68.2936 83.6889 67.998 83.8079 67.6987C85.3785 63.7499 85.6254 59.3882 84.5105 55.2835C83.3956 51.1789 80.9807 47.5583 77.6366 44.9779C74.2926 42.3975 70.2043 41 65.9998 41C61.7953 41 57.707 42.3975 54.363 44.9779C51.0189 47.5583 48.604 51.1789 47.4891 55.2835C46.3742 59.3882 46.621 63.7499 48.1917 67.6987C49.7623 71.6476 52.5699 74.9652 56.1831 77.142C48.6411 79.6118 42.267 84.8175 38.2868 91.7581C38.0992 92.0864 38.0003 92.459 38 92.8382C37.9997 93.2175 38.0981 93.5902 38.2853 93.9187C38.4724 94.2473 38.7417 94.5201 39.0661 94.7099C39.3905 94.8996 39.7585 94.9995 40.1331 94.9995L72.408 94.9998C70.8765 92.3523 70 89.2785 70 86C70 77.638 75.7019 70.6078 83.4297 68.5853Z"
		      fill="#CECECE"/>
	</svg>
);