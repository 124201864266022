import App from 'app/App';
import 'fonts/OnestRegular1602-hint.ttf';
import 'index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);
root.render(
	<React.StrictMode>
		<App/>
	</React.StrictMode>,
);