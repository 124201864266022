import { SelfServiceSettingsFlow, UiNodeInputAttributes } from '@ory/kratos-client';
import { Button, Form, Input, message } from 'antd';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { kratosClient } from '../../api';
import './settings.form.less';

export const SettingsForm = () => {
	const [searchParams] = useSearchParams();
	const flow = searchParams.get('flow');

	const [settingsFlow, setSettingsFlow] = useState<SelfServiceSettingsFlow | undefined>(undefined);

	useEffect(() => {
		if (!flow) {
			console.log('No flow');
			message.error('Ошибка: No flow');
			return;
		}

		kratosClient.getSelfServiceSettingsFlow(flow).then(({ data }) => setSettingsFlow(data));

	}, []);

	const onFinish = async (values: any) => {
		console.log(values);

		if (!flow) {
			console.log('No flow');
			message.error('Ошибка: No flow');
			return;
		}

		kratosClient.submitSelfServiceSettingsFlow(flow, values)
			.then(({ data }) => {
				setSettingsFlow(data);
				message.success('Настройки успешно сохранены');
				window.location.href = '/';
			})
			.catch(async (err: AxiosError) => {
				if (err.response?.status === 400) {
					setSettingsFlow(err.response?.data);
					return;
				}
				return Promise.reject(err);
			});
	};

	return (
		<Form onFinish={onFinish}>
			<h1 className="white-text settings-title">Восстановление пароля</h1>
			{
				settingsFlow?.ui.nodes.map((node) => {
					const attributes = node.attributes as UiNodeInputAttributes;
					const nodeMessages = node.messages;
					if (node.group as string !== 'password' && node.group as string !== 'default')
						return;
					let input;
					if (attributes.type === 'password') input =
						<Input.Password size="large" disabled={attributes.disabled}/>;
					else input = <Input size="large" disabled={attributes.disabled}/>;
					return (
						<div>
							<Form.Item name={attributes.name} label={node.meta.label?.text || ''} rules={[{
								required: attributes.required,
								message: 'Пожалуйста, введите корректное значение!',
							}]} hidden={attributes.type === 'hidden' || attributes.type == 'submit'}
							           initialValue={attributes.value}>
								{input}
							</Form.Item>
							{
								nodeMessages?.map((m) => {
									return <p style={m.type === 'error' ? { color: '#b61313' } : {}}>{m.text}</p>;
								})
							}
						</div>
					);
				})
			}
			<Form.Item>
				<Button size="large" type="primary" htmlType="submit" className="login-button">Изменить пароль</Button>
			</Form.Item>
		</Form>
	);
};