// source: web/equipment/OpcTags.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var Common_pb = require('../../Common_pb.js');
goog.object.extend(proto, Common_pb);
var web_equipment_ParametersService_pb = require('../../web/equipment/ParametersService_pb.js');
goog.object.extend(proto, web_equipment_ParametersService_pb);
goog.exportSymbol('proto.cyberia.web.equipment.ChangedTags', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.OpcDirectReference', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.OpcDirectReferenceList', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.OpcDirectReferenceListResp', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.OpcServer', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.OpcServerList', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.OpcServerListReq', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.OpcTagGroup', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.OpcTagGroupList', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.ReadOpcTag', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.ReadOpcTagList', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.ReadOpcTagListRqv', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.TagValidation', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.TagValidationList', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.TagValidationResp', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.OpcTagGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.web.equipment.OpcTagGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.OpcTagGroup.displayName = 'proto.cyberia.web.equipment.OpcTagGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.ReadOpcTagList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.web.equipment.ReadOpcTagList.repeatedFields_, null);
};
goog.inherits(proto.cyberia.web.equipment.ReadOpcTagList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.ReadOpcTagList.displayName = 'proto.cyberia.web.equipment.ReadOpcTagList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.ReadOpcTagListRqv = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.web.equipment.ReadOpcTagListRqv.repeatedFields_, null);
};
goog.inherits(proto.cyberia.web.equipment.ReadOpcTagListRqv, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.ReadOpcTagListRqv.displayName = 'proto.cyberia.web.equipment.ReadOpcTagListRqv';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.ReadOpcTag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.web.equipment.ReadOpcTag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.ReadOpcTag.displayName = 'proto.cyberia.web.equipment.ReadOpcTag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.OpcServer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.web.equipment.OpcServer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.OpcServer.displayName = 'proto.cyberia.web.equipment.OpcServer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.OpcServerList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.web.equipment.OpcServerList.repeatedFields_, null);
};
goog.inherits(proto.cyberia.web.equipment.OpcServerList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.OpcServerList.displayName = 'proto.cyberia.web.equipment.OpcServerList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.OpcServerListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.web.equipment.OpcServerListReq.repeatedFields_, null);
};
goog.inherits(proto.cyberia.web.equipment.OpcServerListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.OpcServerListReq.displayName = 'proto.cyberia.web.equipment.OpcServerListReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.OpcTagGroupList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.web.equipment.OpcTagGroupList.repeatedFields_, null);
};
goog.inherits(proto.cyberia.web.equipment.OpcTagGroupList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.OpcTagGroupList.displayName = 'proto.cyberia.web.equipment.OpcTagGroupList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.OpcDirectReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.web.equipment.OpcDirectReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.OpcDirectReference.displayName = 'proto.cyberia.web.equipment.OpcDirectReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.OpcDirectReferenceList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.web.equipment.OpcDirectReferenceList.repeatedFields_, null);
};
goog.inherits(proto.cyberia.web.equipment.OpcDirectReferenceList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.OpcDirectReferenceList.displayName = 'proto.cyberia.web.equipment.OpcDirectReferenceList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.OpcDirectReferenceListResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.web.equipment.OpcDirectReferenceListResp.repeatedFields_, null);
};
goog.inherits(proto.cyberia.web.equipment.OpcDirectReferenceListResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.OpcDirectReferenceListResp.displayName = 'proto.cyberia.web.equipment.OpcDirectReferenceListResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.TagValidation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.web.equipment.TagValidation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.TagValidation.displayName = 'proto.cyberia.web.equipment.TagValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.TagValidationList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.web.equipment.TagValidationList.repeatedFields_, null);
};
goog.inherits(proto.cyberia.web.equipment.TagValidationList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.TagValidationList.displayName = 'proto.cyberia.web.equipment.TagValidationList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.TagValidationResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.web.equipment.TagValidationResp.repeatedFields_, null);
};
goog.inherits(proto.cyberia.web.equipment.TagValidationResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.TagValidationResp.displayName = 'proto.cyberia.web.equipment.TagValidationResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.ChangedTags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.web.equipment.ChangedTags.repeatedFields_, null);
};
goog.inherits(proto.cyberia.web.equipment.ChangedTags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.ChangedTags.displayName = 'proto.cyberia.web.equipment.ChangedTags';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.OpcTagGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.OpcTagGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.OpcTagGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.OpcTagGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tags: (f = msg.getTags()) && proto.cyberia.web.equipment.ReadOpcTagList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.OpcTagGroup}
 */
proto.cyberia.web.equipment.OpcTagGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.OpcTagGroup;
  return proto.cyberia.web.equipment.OpcTagGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.OpcTagGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.OpcTagGroup}
 */
proto.cyberia.web.equipment.OpcTagGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = new proto.cyberia.web.equipment.ReadOpcTagList;
      reader.readMessage(value,proto.cyberia.web.equipment.ReadOpcTagList.deserializeBinaryFromReader);
      msg.setTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.OpcTagGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.OpcTagGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.OpcTagGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.OpcTagGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTags();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cyberia.web.equipment.ReadOpcTagList.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cyberia.web.equipment.OpcTagGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.web.equipment.OpcTagGroup} returns this
 */
proto.cyberia.web.equipment.OpcTagGroup.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.OpcTagGroup} returns this
 */
proto.cyberia.web.equipment.OpcTagGroup.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.OpcTagGroup.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.cyberia.web.equipment.OpcTagGroup.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.web.equipment.OpcTagGroup} returns this
 */
proto.cyberia.web.equipment.OpcTagGroup.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.OpcTagGroup} returns this
 */
proto.cyberia.web.equipment.OpcTagGroup.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.OpcTagGroup.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ReadOpcTagList tags = 3;
 * @return {?proto.cyberia.web.equipment.ReadOpcTagList}
 */
proto.cyberia.web.equipment.OpcTagGroup.prototype.getTags = function() {
  return /** @type{?proto.cyberia.web.equipment.ReadOpcTagList} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.web.equipment.ReadOpcTagList, 3));
};


/**
 * @param {?proto.cyberia.web.equipment.ReadOpcTagList|undefined} value
 * @return {!proto.cyberia.web.equipment.OpcTagGroup} returns this
*/
proto.cyberia.web.equipment.OpcTagGroup.prototype.setTags = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.web.equipment.OpcTagGroup} returns this
 */
proto.cyberia.web.equipment.OpcTagGroup.prototype.clearTags = function() {
  return this.setTags(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.OpcTagGroup.prototype.hasTags = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.web.equipment.ReadOpcTagList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.ReadOpcTagList.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.ReadOpcTagList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.ReadOpcTagList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.ReadOpcTagList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.web.equipment.ReadOpcTag.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.ReadOpcTagList}
 */
proto.cyberia.web.equipment.ReadOpcTagList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.ReadOpcTagList;
  return proto.cyberia.web.equipment.ReadOpcTagList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.ReadOpcTagList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.ReadOpcTagList}
 */
proto.cyberia.web.equipment.ReadOpcTagList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.web.equipment.ReadOpcTag;
      reader.readMessage(value,proto.cyberia.web.equipment.ReadOpcTag.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.ReadOpcTagList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.ReadOpcTagList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.ReadOpcTagList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.ReadOpcTagList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.web.equipment.ReadOpcTag.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReadOpcTag list = 1;
 * @return {!Array<!proto.cyberia.web.equipment.ReadOpcTag>}
 */
proto.cyberia.web.equipment.ReadOpcTagList.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.web.equipment.ReadOpcTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.web.equipment.ReadOpcTag, 1));
};


/**
 * @param {!Array<!proto.cyberia.web.equipment.ReadOpcTag>} value
 * @return {!proto.cyberia.web.equipment.ReadOpcTagList} returns this
*/
proto.cyberia.web.equipment.ReadOpcTagList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.web.equipment.ReadOpcTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.web.equipment.ReadOpcTag}
 */
proto.cyberia.web.equipment.ReadOpcTagList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.web.equipment.ReadOpcTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.web.equipment.ReadOpcTagList} returns this
 */
proto.cyberia.web.equipment.ReadOpcTagList.prototype.clearListList = function() {
  return this.setListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.web.equipment.ReadOpcTagListRqv.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.ReadOpcTagListRqv.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.ReadOpcTagListRqv.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.ReadOpcTagListRqv} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.ReadOpcTagListRqv.toObject = function(includeInstance, msg) {
  var f, obj = {
    rqvid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.web.equipment.ReadOpcTag.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.ReadOpcTagListRqv}
 */
proto.cyberia.web.equipment.ReadOpcTagListRqv.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.ReadOpcTagListRqv;
  return proto.cyberia.web.equipment.ReadOpcTagListRqv.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.ReadOpcTagListRqv} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.ReadOpcTagListRqv}
 */
proto.cyberia.web.equipment.ReadOpcTagListRqv.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRqvid(value);
      break;
    case 2:
      var value = new proto.cyberia.web.equipment.ReadOpcTag;
      reader.readMessage(value,proto.cyberia.web.equipment.ReadOpcTag.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.ReadOpcTagListRqv.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.ReadOpcTagListRqv.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.ReadOpcTagListRqv} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.ReadOpcTagListRqv.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRqvid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cyberia.web.equipment.ReadOpcTag.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 rqvId = 1;
 * @return {number}
 */
proto.cyberia.web.equipment.ReadOpcTagListRqv.prototype.getRqvid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.web.equipment.ReadOpcTagListRqv} returns this
 */
proto.cyberia.web.equipment.ReadOpcTagListRqv.prototype.setRqvid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated ReadOpcTag list = 2;
 * @return {!Array<!proto.cyberia.web.equipment.ReadOpcTag>}
 */
proto.cyberia.web.equipment.ReadOpcTagListRqv.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.web.equipment.ReadOpcTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.web.equipment.ReadOpcTag, 2));
};


/**
 * @param {!Array<!proto.cyberia.web.equipment.ReadOpcTag>} value
 * @return {!proto.cyberia.web.equipment.ReadOpcTagListRqv} returns this
*/
proto.cyberia.web.equipment.ReadOpcTagListRqv.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cyberia.web.equipment.ReadOpcTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.web.equipment.ReadOpcTag}
 */
proto.cyberia.web.equipment.ReadOpcTagListRqv.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cyberia.web.equipment.ReadOpcTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.web.equipment.ReadOpcTagListRqv} returns this
 */
proto.cyberia.web.equipment.ReadOpcTagListRqv.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.ReadOpcTag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.ReadOpcTag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.ReadOpcTag.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    columntitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    directtag: (f = msg.getDirecttag()) && proto.cyberia.web.equipment.OpcDirectReference.toObject(includeInstance, f),
    expression: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dtype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    groupid: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.ReadOpcTag}
 */
proto.cyberia.web.equipment.ReadOpcTag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.ReadOpcTag;
  return proto.cyberia.web.equipment.ReadOpcTag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.ReadOpcTag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.ReadOpcTag}
 */
proto.cyberia.web.equipment.ReadOpcTag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColumntitle(value);
      break;
    case 3:
      var value = new proto.cyberia.web.equipment.OpcDirectReference;
      reader.readMessage(value,proto.cyberia.web.equipment.OpcDirectReference.deserializeBinaryFromReader);
      msg.setDirecttag(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpression(value);
      break;
    case 5:
      var value = /** @type {!proto.cyberia.web.equipment.DataType} */ (reader.readEnum());
      msg.setDtype(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGroupid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.ReadOpcTag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.ReadOpcTag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.ReadOpcTag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDirecttag();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cyberia.web.equipment.OpcDirectReference.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {!proto.cyberia.web.equipment.DataType} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.web.equipment.ReadOpcTag} returns this
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.ReadOpcTag} returns this
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string columnTitle = 2;
 * @return {string}
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.getColumntitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.web.equipment.ReadOpcTag} returns this
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.setColumntitle = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.ReadOpcTag} returns this
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.clearColumntitle = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.hasColumntitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional OpcDirectReference directTag = 3;
 * @return {?proto.cyberia.web.equipment.OpcDirectReference}
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.getDirecttag = function() {
  return /** @type{?proto.cyberia.web.equipment.OpcDirectReference} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.web.equipment.OpcDirectReference, 3));
};


/**
 * @param {?proto.cyberia.web.equipment.OpcDirectReference|undefined} value
 * @return {!proto.cyberia.web.equipment.ReadOpcTag} returns this
*/
proto.cyberia.web.equipment.ReadOpcTag.prototype.setDirecttag = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.web.equipment.ReadOpcTag} returns this
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.clearDirecttag = function() {
  return this.setDirecttag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.hasDirecttag = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string expression = 4;
 * @return {string}
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.getExpression = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.web.equipment.ReadOpcTag} returns this
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.setExpression = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.ReadOpcTag} returns this
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.clearExpression = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.hasExpression = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DataType dType = 5;
 * @return {!proto.cyberia.web.equipment.DataType}
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.getDtype = function() {
  return /** @type {!proto.cyberia.web.equipment.DataType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.cyberia.web.equipment.DataType} value
 * @return {!proto.cyberia.web.equipment.ReadOpcTag} returns this
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.setDtype = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.ReadOpcTag} returns this
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.clearDtype = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.hasDtype = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 groupId = 6;
 * @return {number}
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.getGroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.web.equipment.ReadOpcTag} returns this
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.setGroupid = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.ReadOpcTag} returns this
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.clearGroupid = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ReadOpcTag.prototype.hasGroupid = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.OpcServer.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.OpcServer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.OpcServer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.OpcServer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    defaultserver: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.OpcServer}
 */
proto.cyberia.web.equipment.OpcServer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.OpcServer;
  return proto.cyberia.web.equipment.OpcServer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.OpcServer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.OpcServer}
 */
proto.cyberia.web.equipment.OpcServer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultserver(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.OpcServer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.OpcServer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.OpcServer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.OpcServer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cyberia.web.equipment.OpcServer.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.web.equipment.OpcServer} returns this
 */
proto.cyberia.web.equipment.OpcServer.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.OpcServer} returns this
 */
proto.cyberia.web.equipment.OpcServer.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.OpcServer.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.cyberia.web.equipment.OpcServer.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.web.equipment.OpcServer} returns this
 */
proto.cyberia.web.equipment.OpcServer.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.OpcServer} returns this
 */
proto.cyberia.web.equipment.OpcServer.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.OpcServer.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.cyberia.web.equipment.OpcServer.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.web.equipment.OpcServer} returns this
 */
proto.cyberia.web.equipment.OpcServer.prototype.setUrl = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.OpcServer} returns this
 */
proto.cyberia.web.equipment.OpcServer.prototype.clearUrl = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.OpcServer.prototype.hasUrl = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool defaultServer = 4;
 * @return {boolean}
 */
proto.cyberia.web.equipment.OpcServer.prototype.getDefaultserver = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cyberia.web.equipment.OpcServer} returns this
 */
proto.cyberia.web.equipment.OpcServer.prototype.setDefaultserver = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.OpcServer} returns this
 */
proto.cyberia.web.equipment.OpcServer.prototype.clearDefaultserver = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.OpcServer.prototype.hasDefaultserver = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.web.equipment.OpcServerList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.OpcServerList.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.OpcServerList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.OpcServerList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.OpcServerList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.web.equipment.OpcServer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.OpcServerList}
 */
proto.cyberia.web.equipment.OpcServerList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.OpcServerList;
  return proto.cyberia.web.equipment.OpcServerList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.OpcServerList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.OpcServerList}
 */
proto.cyberia.web.equipment.OpcServerList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.web.equipment.OpcServer;
      reader.readMessage(value,proto.cyberia.web.equipment.OpcServer.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.OpcServerList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.OpcServerList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.OpcServerList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.OpcServerList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.web.equipment.OpcServer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OpcServer list = 1;
 * @return {!Array<!proto.cyberia.web.equipment.OpcServer>}
 */
proto.cyberia.web.equipment.OpcServerList.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.web.equipment.OpcServer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.web.equipment.OpcServer, 1));
};


/**
 * @param {!Array<!proto.cyberia.web.equipment.OpcServer>} value
 * @return {!proto.cyberia.web.equipment.OpcServerList} returns this
*/
proto.cyberia.web.equipment.OpcServerList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.web.equipment.OpcServer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.web.equipment.OpcServer}
 */
proto.cyberia.web.equipment.OpcServerList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.web.equipment.OpcServer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.web.equipment.OpcServerList} returns this
 */
proto.cyberia.web.equipment.OpcServerList.prototype.clearListList = function() {
  return this.setListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.web.equipment.OpcServerListReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.OpcServerListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.OpcServerListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.OpcServerListReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.OpcServerListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    rqvid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.web.equipment.OpcServer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.OpcServerListReq}
 */
proto.cyberia.web.equipment.OpcServerListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.OpcServerListReq;
  return proto.cyberia.web.equipment.OpcServerListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.OpcServerListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.OpcServerListReq}
 */
proto.cyberia.web.equipment.OpcServerListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRqvid(value);
      break;
    case 2:
      var value = new proto.cyberia.web.equipment.OpcServer;
      reader.readMessage(value,proto.cyberia.web.equipment.OpcServer.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.OpcServerListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.OpcServerListReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.OpcServerListReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.OpcServerListReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRqvid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cyberia.web.equipment.OpcServer.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 rqvId = 1;
 * @return {number}
 */
proto.cyberia.web.equipment.OpcServerListReq.prototype.getRqvid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.web.equipment.OpcServerListReq} returns this
 */
proto.cyberia.web.equipment.OpcServerListReq.prototype.setRqvid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated OpcServer list = 2;
 * @return {!Array<!proto.cyberia.web.equipment.OpcServer>}
 */
proto.cyberia.web.equipment.OpcServerListReq.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.web.equipment.OpcServer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.web.equipment.OpcServer, 2));
};


/**
 * @param {!Array<!proto.cyberia.web.equipment.OpcServer>} value
 * @return {!proto.cyberia.web.equipment.OpcServerListReq} returns this
*/
proto.cyberia.web.equipment.OpcServerListReq.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cyberia.web.equipment.OpcServer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.web.equipment.OpcServer}
 */
proto.cyberia.web.equipment.OpcServerListReq.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cyberia.web.equipment.OpcServer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.web.equipment.OpcServerListReq} returns this
 */
proto.cyberia.web.equipment.OpcServerListReq.prototype.clearListList = function() {
  return this.setListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.web.equipment.OpcTagGroupList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.OpcTagGroupList.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.OpcTagGroupList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.OpcTagGroupList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.OpcTagGroupList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.web.equipment.OpcTagGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.OpcTagGroupList}
 */
proto.cyberia.web.equipment.OpcTagGroupList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.OpcTagGroupList;
  return proto.cyberia.web.equipment.OpcTagGroupList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.OpcTagGroupList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.OpcTagGroupList}
 */
proto.cyberia.web.equipment.OpcTagGroupList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.web.equipment.OpcTagGroup;
      reader.readMessage(value,proto.cyberia.web.equipment.OpcTagGroup.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.OpcTagGroupList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.OpcTagGroupList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.OpcTagGroupList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.OpcTagGroupList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.web.equipment.OpcTagGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OpcTagGroup list = 1;
 * @return {!Array<!proto.cyberia.web.equipment.OpcTagGroup>}
 */
proto.cyberia.web.equipment.OpcTagGroupList.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.web.equipment.OpcTagGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.web.equipment.OpcTagGroup, 1));
};


/**
 * @param {!Array<!proto.cyberia.web.equipment.OpcTagGroup>} value
 * @return {!proto.cyberia.web.equipment.OpcTagGroupList} returns this
*/
proto.cyberia.web.equipment.OpcTagGroupList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.web.equipment.OpcTagGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.web.equipment.OpcTagGroup}
 */
proto.cyberia.web.equipment.OpcTagGroupList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.web.equipment.OpcTagGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.web.equipment.OpcTagGroupList} returns this
 */
proto.cyberia.web.equipment.OpcTagGroupList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.OpcDirectReference.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.OpcDirectReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.OpcDirectReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.OpcDirectReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    serverid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tag: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.OpcDirectReference}
 */
proto.cyberia.web.equipment.OpcDirectReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.OpcDirectReference;
  return proto.cyberia.web.equipment.OpcDirectReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.OpcDirectReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.OpcDirectReference}
 */
proto.cyberia.web.equipment.OpcDirectReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setServerid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.OpcDirectReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.OpcDirectReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.OpcDirectReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.OpcDirectReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServerid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 serverId = 1;
 * @return {number}
 */
proto.cyberia.web.equipment.OpcDirectReference.prototype.getServerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.web.equipment.OpcDirectReference} returns this
 */
proto.cyberia.web.equipment.OpcDirectReference.prototype.setServerid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string tag = 2;
 * @return {string}
 */
proto.cyberia.web.equipment.OpcDirectReference.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.web.equipment.OpcDirectReference} returns this
 */
proto.cyberia.web.equipment.OpcDirectReference.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.web.equipment.OpcDirectReferenceList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.OpcDirectReferenceList.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.OpcDirectReferenceList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.OpcDirectReferenceList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.OpcDirectReferenceList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.web.equipment.OpcDirectReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.OpcDirectReferenceList}
 */
proto.cyberia.web.equipment.OpcDirectReferenceList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.OpcDirectReferenceList;
  return proto.cyberia.web.equipment.OpcDirectReferenceList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.OpcDirectReferenceList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.OpcDirectReferenceList}
 */
proto.cyberia.web.equipment.OpcDirectReferenceList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.web.equipment.OpcDirectReference;
      reader.readMessage(value,proto.cyberia.web.equipment.OpcDirectReference.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.OpcDirectReferenceList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.OpcDirectReferenceList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.OpcDirectReferenceList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.OpcDirectReferenceList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.web.equipment.OpcDirectReference.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OpcDirectReference list = 1;
 * @return {!Array<!proto.cyberia.web.equipment.OpcDirectReference>}
 */
proto.cyberia.web.equipment.OpcDirectReferenceList.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.web.equipment.OpcDirectReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.web.equipment.OpcDirectReference, 1));
};


/**
 * @param {!Array<!proto.cyberia.web.equipment.OpcDirectReference>} value
 * @return {!proto.cyberia.web.equipment.OpcDirectReferenceList} returns this
*/
proto.cyberia.web.equipment.OpcDirectReferenceList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.web.equipment.OpcDirectReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.web.equipment.OpcDirectReference}
 */
proto.cyberia.web.equipment.OpcDirectReferenceList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.web.equipment.OpcDirectReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.web.equipment.OpcDirectReferenceList} returns this
 */
proto.cyberia.web.equipment.OpcDirectReferenceList.prototype.clearListList = function() {
  return this.setListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.web.equipment.OpcDirectReferenceListResp.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.OpcDirectReferenceListResp.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.OpcDirectReferenceListResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.OpcDirectReferenceListResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.OpcDirectReferenceListResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    rqvid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.web.equipment.OpcDirectReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.OpcDirectReferenceListResp}
 */
proto.cyberia.web.equipment.OpcDirectReferenceListResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.OpcDirectReferenceListResp;
  return proto.cyberia.web.equipment.OpcDirectReferenceListResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.OpcDirectReferenceListResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.OpcDirectReferenceListResp}
 */
proto.cyberia.web.equipment.OpcDirectReferenceListResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRqvid(value);
      break;
    case 2:
      var value = new proto.cyberia.web.equipment.OpcDirectReference;
      reader.readMessage(value,proto.cyberia.web.equipment.OpcDirectReference.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.OpcDirectReferenceListResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.OpcDirectReferenceListResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.OpcDirectReferenceListResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.OpcDirectReferenceListResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRqvid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cyberia.web.equipment.OpcDirectReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 rqvId = 1;
 * @return {number}
 */
proto.cyberia.web.equipment.OpcDirectReferenceListResp.prototype.getRqvid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.web.equipment.OpcDirectReferenceListResp} returns this
 */
proto.cyberia.web.equipment.OpcDirectReferenceListResp.prototype.setRqvid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated OpcDirectReference list = 2;
 * @return {!Array<!proto.cyberia.web.equipment.OpcDirectReference>}
 */
proto.cyberia.web.equipment.OpcDirectReferenceListResp.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.web.equipment.OpcDirectReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.web.equipment.OpcDirectReference, 2));
};


/**
 * @param {!Array<!proto.cyberia.web.equipment.OpcDirectReference>} value
 * @return {!proto.cyberia.web.equipment.OpcDirectReferenceListResp} returns this
*/
proto.cyberia.web.equipment.OpcDirectReferenceListResp.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cyberia.web.equipment.OpcDirectReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.web.equipment.OpcDirectReference}
 */
proto.cyberia.web.equipment.OpcDirectReferenceListResp.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cyberia.web.equipment.OpcDirectReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.web.equipment.OpcDirectReferenceListResp} returns this
 */
proto.cyberia.web.equipment.OpcDirectReferenceListResp.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.TagValidation.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.TagValidation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.TagValidation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.TagValidation.toObject = function(includeInstance, msg) {
  var f, obj = {
    tag: (f = msg.getTag()) && proto.cyberia.web.equipment.ReadOpcTag.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.TagValidation}
 */
proto.cyberia.web.equipment.TagValidation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.TagValidation;
  return proto.cyberia.web.equipment.TagValidation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.TagValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.TagValidation}
 */
proto.cyberia.web.equipment.TagValidation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.web.equipment.ReadOpcTag;
      reader.readMessage(value,proto.cyberia.web.equipment.ReadOpcTag.deserializeBinaryFromReader);
      msg.setTag(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.TagValidation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.TagValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.TagValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.TagValidation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTag();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cyberia.web.equipment.ReadOpcTag.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ReadOpcTag tag = 1;
 * @return {?proto.cyberia.web.equipment.ReadOpcTag}
 */
proto.cyberia.web.equipment.TagValidation.prototype.getTag = function() {
  return /** @type{?proto.cyberia.web.equipment.ReadOpcTag} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.web.equipment.ReadOpcTag, 1));
};


/**
 * @param {?proto.cyberia.web.equipment.ReadOpcTag|undefined} value
 * @return {!proto.cyberia.web.equipment.TagValidation} returns this
*/
proto.cyberia.web.equipment.TagValidation.prototype.setTag = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.web.equipment.TagValidation} returns this
 */
proto.cyberia.web.equipment.TagValidation.prototype.clearTag = function() {
  return this.setTag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.TagValidation.prototype.hasTag = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string error = 2;
 * @return {string}
 */
proto.cyberia.web.equipment.TagValidation.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.web.equipment.TagValidation} returns this
 */
proto.cyberia.web.equipment.TagValidation.prototype.setError = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.TagValidation} returns this
 */
proto.cyberia.web.equipment.TagValidation.prototype.clearError = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.TagValidation.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.web.equipment.TagValidationList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.TagValidationList.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.TagValidationList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.TagValidationList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.TagValidationList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.web.equipment.TagValidation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.TagValidationList}
 */
proto.cyberia.web.equipment.TagValidationList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.TagValidationList;
  return proto.cyberia.web.equipment.TagValidationList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.TagValidationList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.TagValidationList}
 */
proto.cyberia.web.equipment.TagValidationList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.web.equipment.TagValidation;
      reader.readMessage(value,proto.cyberia.web.equipment.TagValidation.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.TagValidationList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.TagValidationList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.TagValidationList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.TagValidationList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.web.equipment.TagValidation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TagValidation list = 1;
 * @return {!Array<!proto.cyberia.web.equipment.TagValidation>}
 */
proto.cyberia.web.equipment.TagValidationList.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.web.equipment.TagValidation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.web.equipment.TagValidation, 1));
};


/**
 * @param {!Array<!proto.cyberia.web.equipment.TagValidation>} value
 * @return {!proto.cyberia.web.equipment.TagValidationList} returns this
*/
proto.cyberia.web.equipment.TagValidationList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.web.equipment.TagValidation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.web.equipment.TagValidation}
 */
proto.cyberia.web.equipment.TagValidationList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.web.equipment.TagValidation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.web.equipment.TagValidationList} returns this
 */
proto.cyberia.web.equipment.TagValidationList.prototype.clearListList = function() {
  return this.setListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.web.equipment.TagValidationResp.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.TagValidationResp.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.TagValidationResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.TagValidationResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.TagValidationResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    rqvid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.web.equipment.TagValidation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.TagValidationResp}
 */
proto.cyberia.web.equipment.TagValidationResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.TagValidationResp;
  return proto.cyberia.web.equipment.TagValidationResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.TagValidationResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.TagValidationResp}
 */
proto.cyberia.web.equipment.TagValidationResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRqvid(value);
      break;
    case 2:
      var value = new proto.cyberia.web.equipment.TagValidation;
      reader.readMessage(value,proto.cyberia.web.equipment.TagValidation.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.TagValidationResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.TagValidationResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.TagValidationResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.TagValidationResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRqvid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cyberia.web.equipment.TagValidation.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 rqvId = 1;
 * @return {number}
 */
proto.cyberia.web.equipment.TagValidationResp.prototype.getRqvid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.web.equipment.TagValidationResp} returns this
 */
proto.cyberia.web.equipment.TagValidationResp.prototype.setRqvid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated TagValidation list = 2;
 * @return {!Array<!proto.cyberia.web.equipment.TagValidation>}
 */
proto.cyberia.web.equipment.TagValidationResp.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.web.equipment.TagValidation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.web.equipment.TagValidation, 2));
};


/**
 * @param {!Array<!proto.cyberia.web.equipment.TagValidation>} value
 * @return {!proto.cyberia.web.equipment.TagValidationResp} returns this
*/
proto.cyberia.web.equipment.TagValidationResp.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cyberia.web.equipment.TagValidation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.web.equipment.TagValidation}
 */
proto.cyberia.web.equipment.TagValidationResp.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cyberia.web.equipment.TagValidation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.web.equipment.TagValidationResp} returns this
 */
proto.cyberia.web.equipment.TagValidationResp.prototype.clearListList = function() {
  return this.setListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.web.equipment.ChangedTags.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.ChangedTags.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.ChangedTags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.ChangedTags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.ChangedTags.toObject = function(includeInstance, msg) {
  var f, obj = {
    insertList: jspb.Message.toObjectList(msg.getInsertList(),
    proto.cyberia.web.equipment.ReadOpcTag.toObject, includeInstance),
    updatedList: jspb.Message.toObjectList(msg.getUpdatedList(),
    proto.cyberia.web.equipment.ReadOpcTag.toObject, includeInstance),
    deletedList: jspb.Message.toObjectList(msg.getDeletedList(),
    proto.cyberia.web.equipment.ReadOpcTag.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.ChangedTags}
 */
proto.cyberia.web.equipment.ChangedTags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.ChangedTags;
  return proto.cyberia.web.equipment.ChangedTags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.ChangedTags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.ChangedTags}
 */
proto.cyberia.web.equipment.ChangedTags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.web.equipment.ReadOpcTag;
      reader.readMessage(value,proto.cyberia.web.equipment.ReadOpcTag.deserializeBinaryFromReader);
      msg.addInsert(value);
      break;
    case 2:
      var value = new proto.cyberia.web.equipment.ReadOpcTag;
      reader.readMessage(value,proto.cyberia.web.equipment.ReadOpcTag.deserializeBinaryFromReader);
      msg.addUpdated(value);
      break;
    case 3:
      var value = new proto.cyberia.web.equipment.ReadOpcTag;
      reader.readMessage(value,proto.cyberia.web.equipment.ReadOpcTag.deserializeBinaryFromReader);
      msg.addDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.ChangedTags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.ChangedTags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.ChangedTags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.ChangedTags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInsertList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.web.equipment.ReadOpcTag.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cyberia.web.equipment.ReadOpcTag.serializeBinaryToWriter
    );
  }
  f = message.getDeletedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.cyberia.web.equipment.ReadOpcTag.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReadOpcTag insert = 1;
 * @return {!Array<!proto.cyberia.web.equipment.ReadOpcTag>}
 */
proto.cyberia.web.equipment.ChangedTags.prototype.getInsertList = function() {
  return /** @type{!Array<!proto.cyberia.web.equipment.ReadOpcTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.web.equipment.ReadOpcTag, 1));
};


/**
 * @param {!Array<!proto.cyberia.web.equipment.ReadOpcTag>} value
 * @return {!proto.cyberia.web.equipment.ChangedTags} returns this
*/
proto.cyberia.web.equipment.ChangedTags.prototype.setInsertList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.web.equipment.ReadOpcTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.web.equipment.ReadOpcTag}
 */
proto.cyberia.web.equipment.ChangedTags.prototype.addInsert = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.web.equipment.ReadOpcTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.web.equipment.ChangedTags} returns this
 */
proto.cyberia.web.equipment.ChangedTags.prototype.clearInsertList = function() {
  return this.setInsertList([]);
};


/**
 * repeated ReadOpcTag updated = 2;
 * @return {!Array<!proto.cyberia.web.equipment.ReadOpcTag>}
 */
proto.cyberia.web.equipment.ChangedTags.prototype.getUpdatedList = function() {
  return /** @type{!Array<!proto.cyberia.web.equipment.ReadOpcTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.web.equipment.ReadOpcTag, 2));
};


/**
 * @param {!Array<!proto.cyberia.web.equipment.ReadOpcTag>} value
 * @return {!proto.cyberia.web.equipment.ChangedTags} returns this
*/
proto.cyberia.web.equipment.ChangedTags.prototype.setUpdatedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cyberia.web.equipment.ReadOpcTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.web.equipment.ReadOpcTag}
 */
proto.cyberia.web.equipment.ChangedTags.prototype.addUpdated = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cyberia.web.equipment.ReadOpcTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.web.equipment.ChangedTags} returns this
 */
proto.cyberia.web.equipment.ChangedTags.prototype.clearUpdatedList = function() {
  return this.setUpdatedList([]);
};


/**
 * repeated ReadOpcTag deleted = 3;
 * @return {!Array<!proto.cyberia.web.equipment.ReadOpcTag>}
 */
proto.cyberia.web.equipment.ChangedTags.prototype.getDeletedList = function() {
  return /** @type{!Array<!proto.cyberia.web.equipment.ReadOpcTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.web.equipment.ReadOpcTag, 3));
};


/**
 * @param {!Array<!proto.cyberia.web.equipment.ReadOpcTag>} value
 * @return {!proto.cyberia.web.equipment.ChangedTags} returns this
*/
proto.cyberia.web.equipment.ChangedTags.prototype.setDeletedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.cyberia.web.equipment.ReadOpcTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.web.equipment.ReadOpcTag}
 */
proto.cyberia.web.equipment.ChangedTags.prototype.addDeleted = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.cyberia.web.equipment.ReadOpcTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.web.equipment.ChangedTags} returns this
 */
proto.cyberia.web.equipment.ChangedTags.prototype.clearDeletedList = function() {
  return this.setDeletedList([]);
};


goog.object.extend(exports, proto.cyberia.web.equipment);
