// source: web/equipment/ParametersService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var Common_pb = require('../../Common_pb.js');
goog.object.extend(proto, Common_pb);
var web_equipment_MUnitService_pb = require('../../web/equipment/MUnitService_pb.js');
goog.object.extend(proto, web_equipment_MUnitService_pb);
goog.exportSymbol('proto.cyberia.web.equipment.DataType', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.ParameterOrder', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.ParameterOrderFields', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.ParameterSelectionDescriptor', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.ParameterType', null, global);
goog.exportSymbol('proto.cyberia.web.equipment.ParameterTypes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.ParameterType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.web.equipment.ParameterType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.ParameterType.displayName = 'proto.cyberia.web.equipment.ParameterType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.web.equipment.ParameterSelectionDescriptor.repeatedFields_, null);
};
goog.inherits(proto.cyberia.web.equipment.ParameterSelectionDescriptor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.ParameterSelectionDescriptor.displayName = 'proto.cyberia.web.equipment.ParameterSelectionDescriptor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.ParameterOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.web.equipment.ParameterOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.ParameterOrder.displayName = 'proto.cyberia.web.equipment.ParameterOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.web.equipment.ParameterTypes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.web.equipment.ParameterTypes.repeatedFields_, null);
};
goog.inherits(proto.cyberia.web.equipment.ParameterTypes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.web.equipment.ParameterTypes.displayName = 'proto.cyberia.web.equipment.ParameterTypes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.ParameterType.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.ParameterType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.ParameterType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.ParameterType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    datatype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    otppattern: jspb.Message.getFieldWithDefault(msg, 4, ""),
    unit: (f = msg.getUnit()) && web_equipment_MUnitService_pb.MUnit.toObject(includeInstance, f),
    comment: jspb.Message.getFieldWithDefault(msg, 7, ""),
    pictogram: (f = msg.getPictogram()) && Common_pb.File.toObject(includeInstance, f),
    calcinterval: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    readwrite: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.ParameterType}
 */
proto.cyberia.web.equipment.ParameterType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.ParameterType;
  return proto.cyberia.web.equipment.ParameterType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.ParameterType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.ParameterType}
 */
proto.cyberia.web.equipment.ParameterType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.cyberia.web.equipment.DataType} */ (reader.readEnum());
      msg.setDatatype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtppattern(value);
      break;
    case 5:
      var value = new web_equipment_MUnitService_pb.MUnit;
      reader.readMessage(value,web_equipment_MUnitService_pb.MUnit.deserializeBinaryFromReader);
      msg.setUnit(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 8:
      var value = new Common_pb.File;
      reader.readMessage(value,Common_pb.File.deserializeBinaryFromReader);
      msg.setPictogram(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCalcinterval(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadwrite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.ParameterType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.ParameterType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.ParameterType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.ParameterType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {!proto.cyberia.web.equipment.DataType} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnit();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      web_equipment_MUnitService_pb.MUnit.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPictogram();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Common_pb.File.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cyberia.web.equipment.ParameterType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
 */
proto.cyberia.web.equipment.ParameterType.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
 */
proto.cyberia.web.equipment.ParameterType.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ParameterType.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cyberia.web.equipment.ParameterType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
 */
proto.cyberia.web.equipment.ParameterType.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
 */
proto.cyberia.web.equipment.ParameterType.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ParameterType.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DataType dataType = 3;
 * @return {!proto.cyberia.web.equipment.DataType}
 */
proto.cyberia.web.equipment.ParameterType.prototype.getDatatype = function() {
  return /** @type {!proto.cyberia.web.equipment.DataType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.cyberia.web.equipment.DataType} value
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
 */
proto.cyberia.web.equipment.ParameterType.prototype.setDatatype = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
 */
proto.cyberia.web.equipment.ParameterType.prototype.clearDatatype = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ParameterType.prototype.hasDatatype = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string otpPattern = 4;
 * @return {string}
 */
proto.cyberia.web.equipment.ParameterType.prototype.getOtppattern = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
 */
proto.cyberia.web.equipment.ParameterType.prototype.setOtppattern = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
 */
proto.cyberia.web.equipment.ParameterType.prototype.clearOtppattern = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ParameterType.prototype.hasOtppattern = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MUnit unit = 5;
 * @return {?proto.cyberia.web.equipment.MUnit}
 */
proto.cyberia.web.equipment.ParameterType.prototype.getUnit = function() {
  return /** @type{?proto.cyberia.web.equipment.MUnit} */ (
    jspb.Message.getWrapperField(this, web_equipment_MUnitService_pb.MUnit, 5));
};


/**
 * @param {?proto.cyberia.web.equipment.MUnit|undefined} value
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
*/
proto.cyberia.web.equipment.ParameterType.prototype.setUnit = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
 */
proto.cyberia.web.equipment.ParameterType.prototype.clearUnit = function() {
  return this.setUnit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ParameterType.prototype.hasUnit = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string comment = 7;
 * @return {string}
 */
proto.cyberia.web.equipment.ParameterType.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
 */
proto.cyberia.web.equipment.ParameterType.prototype.setComment = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
 */
proto.cyberia.web.equipment.ParameterType.prototype.clearComment = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ParameterType.prototype.hasComment = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional common.File pictogram = 8;
 * @return {?proto.common.File}
 */
proto.cyberia.web.equipment.ParameterType.prototype.getPictogram = function() {
  return /** @type{?proto.common.File} */ (
    jspb.Message.getWrapperField(this, Common_pb.File, 8));
};


/**
 * @param {?proto.common.File|undefined} value
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
*/
proto.cyberia.web.equipment.ParameterType.prototype.setPictogram = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
 */
proto.cyberia.web.equipment.ParameterType.prototype.clearPictogram = function() {
  return this.setPictogram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ParameterType.prototype.hasPictogram = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional float calcInterval = 9;
 * @return {number}
 */
proto.cyberia.web.equipment.ParameterType.prototype.getCalcinterval = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
 */
proto.cyberia.web.equipment.ParameterType.prototype.setCalcinterval = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
 */
proto.cyberia.web.equipment.ParameterType.prototype.clearCalcinterval = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ParameterType.prototype.hasCalcinterval = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool readWrite = 10;
 * @return {boolean}
 */
proto.cyberia.web.equipment.ParameterType.prototype.getReadwrite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
 */
proto.cyberia.web.equipment.ParameterType.prototype.setReadwrite = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.ParameterType} returns this
 */
proto.cyberia.web.equipment.ParameterType.prototype.clearReadwrite = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ParameterType.prototype.hasReadwrite = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.repeatedFields_ = [1,3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.ParameterSelectionDescriptor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderList: jspb.Message.toObjectList(msg.getOrderList(),
    proto.cyberia.web.equipment.ParameterOrder.toObject, includeInstance),
    namesearch: jspb.Message.getFieldWithDefault(msg, 2, ""),
    datatypevariantList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    optpatternsearch: jspb.Message.getFieldWithDefault(msg, 4, ""),
    unitvariantsList: jspb.Message.toObjectList(msg.getUnitvariantsList(),
    web_equipment_MUnitService_pb.MUnit.toObject, includeInstance),
    commentsearch: jspb.Message.getFieldWithDefault(msg, 6, ""),
    calcintervalrange: (f = msg.getCalcintervalrange()) && Common_pb.Interval.toObject(includeInstance, f),
    fulltextsearch: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.ParameterSelectionDescriptor;
  return proto.cyberia.web.equipment.ParameterSelectionDescriptor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.web.equipment.ParameterOrder;
      reader.readMessage(value,proto.cyberia.web.equipment.ParameterOrder.deserializeBinaryFromReader);
      msg.addOrder(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamesearch(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.cyberia.web.equipment.DataType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDatatypevariant(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptpatternsearch(value);
      break;
    case 5:
      var value = new web_equipment_MUnitService_pb.MUnit;
      reader.readMessage(value,web_equipment_MUnitService_pb.MUnit.deserializeBinaryFromReader);
      msg.addUnitvariants(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommentsearch(value);
      break;
    case 7:
      var value = new Common_pb.Interval;
      reader.readMessage(value,Common_pb.Interval.deserializeBinaryFromReader);
      msg.setCalcintervalrange(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFulltextsearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.ParameterSelectionDescriptor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.web.equipment.ParameterOrder.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDatatypevariantList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnitvariantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      web_equipment_MUnitService_pb.MUnit.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCalcintervalrange();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Common_pb.Interval.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * repeated ParameterOrder order = 1;
 * @return {!Array<!proto.cyberia.web.equipment.ParameterOrder>}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.getOrderList = function() {
  return /** @type{!Array<!proto.cyberia.web.equipment.ParameterOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.web.equipment.ParameterOrder, 1));
};


/**
 * @param {!Array<!proto.cyberia.web.equipment.ParameterOrder>} value
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
*/
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.setOrderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.web.equipment.ParameterOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.web.equipment.ParameterOrder}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.addOrder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.web.equipment.ParameterOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.clearOrderList = function() {
  return this.setOrderList([]);
};


/**
 * optional string nameSearch = 2;
 * @return {string}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.getNamesearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.setNamesearch = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.clearNamesearch = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.hasNamesearch = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated DataType dataTypeVariant = 3;
 * @return {!Array<!proto.cyberia.web.equipment.DataType>}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.getDatatypevariantList = function() {
  return /** @type {!Array<!proto.cyberia.web.equipment.DataType>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.cyberia.web.equipment.DataType>} value
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.setDatatypevariantList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.cyberia.web.equipment.DataType} value
 * @param {number=} opt_index
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.addDatatypevariant = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.clearDatatypevariantList = function() {
  return this.setDatatypevariantList([]);
};


/**
 * optional string optPatternSearch = 4;
 * @return {string}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.getOptpatternsearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.setOptpatternsearch = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.clearOptpatternsearch = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.hasOptpatternsearch = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated MUnit unitVariants = 5;
 * @return {!Array<!proto.cyberia.web.equipment.MUnit>}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.getUnitvariantsList = function() {
  return /** @type{!Array<!proto.cyberia.web.equipment.MUnit>} */ (
    jspb.Message.getRepeatedWrapperField(this, web_equipment_MUnitService_pb.MUnit, 5));
};


/**
 * @param {!Array<!proto.cyberia.web.equipment.MUnit>} value
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
*/
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.setUnitvariantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.cyberia.web.equipment.MUnit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.web.equipment.MUnit}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.addUnitvariants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.cyberia.web.equipment.MUnit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.clearUnitvariantsList = function() {
  return this.setUnitvariantsList([]);
};


/**
 * optional string commentSearch = 6;
 * @return {string}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.getCommentsearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.setCommentsearch = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.clearCommentsearch = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.hasCommentsearch = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.Interval calcIntervalRange = 7;
 * @return {?proto.common.Interval}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.getCalcintervalrange = function() {
  return /** @type{?proto.common.Interval} */ (
    jspb.Message.getWrapperField(this, Common_pb.Interval, 7));
};


/**
 * @param {?proto.common.Interval|undefined} value
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
*/
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.setCalcintervalrange = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.clearCalcintervalrange = function() {
  return this.setCalcintervalrange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.hasCalcintervalrange = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string fullTextSearch = 8;
 * @return {string}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.getFulltextsearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.setFulltextsearch = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.web.equipment.ParameterSelectionDescriptor} returns this
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.clearFulltextsearch = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.web.equipment.ParameterSelectionDescriptor.prototype.hasFulltextsearch = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.ParameterOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.ParameterOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.ParameterOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.ParameterOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ascending: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.ParameterOrder}
 */
proto.cyberia.web.equipment.ParameterOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.ParameterOrder;
  return proto.cyberia.web.equipment.ParameterOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.ParameterOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.ParameterOrder}
 */
proto.cyberia.web.equipment.ParameterOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cyberia.web.equipment.ParameterOrderFields} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAscending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.ParameterOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.ParameterOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.ParameterOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.ParameterOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAscending();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional ParameterOrderFields field = 1;
 * @return {!proto.cyberia.web.equipment.ParameterOrderFields}
 */
proto.cyberia.web.equipment.ParameterOrder.prototype.getField = function() {
  return /** @type {!proto.cyberia.web.equipment.ParameterOrderFields} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.cyberia.web.equipment.ParameterOrderFields} value
 * @return {!proto.cyberia.web.equipment.ParameterOrder} returns this
 */
proto.cyberia.web.equipment.ParameterOrder.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool ascending = 2;
 * @return {boolean}
 */
proto.cyberia.web.equipment.ParameterOrder.prototype.getAscending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cyberia.web.equipment.ParameterOrder} returns this
 */
proto.cyberia.web.equipment.ParameterOrder.prototype.setAscending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.web.equipment.ParameterTypes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.web.equipment.ParameterTypes.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.web.equipment.ParameterTypes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.web.equipment.ParameterTypes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.ParameterTypes.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoList: jspb.Message.toObjectList(msg.getInfoList(),
    proto.cyberia.web.equipment.ParameterType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.web.equipment.ParameterTypes}
 */
proto.cyberia.web.equipment.ParameterTypes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.web.equipment.ParameterTypes;
  return proto.cyberia.web.equipment.ParameterTypes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.web.equipment.ParameterTypes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.web.equipment.ParameterTypes}
 */
proto.cyberia.web.equipment.ParameterTypes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.web.equipment.ParameterType;
      reader.readMessage(value,proto.cyberia.web.equipment.ParameterType.deserializeBinaryFromReader);
      msg.addInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.web.equipment.ParameterTypes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.web.equipment.ParameterTypes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.web.equipment.ParameterTypes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.web.equipment.ParameterTypes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.web.equipment.ParameterType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ParameterType info = 1;
 * @return {!Array<!proto.cyberia.web.equipment.ParameterType>}
 */
proto.cyberia.web.equipment.ParameterTypes.prototype.getInfoList = function() {
  return /** @type{!Array<!proto.cyberia.web.equipment.ParameterType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.web.equipment.ParameterType, 1));
};


/**
 * @param {!Array<!proto.cyberia.web.equipment.ParameterType>} value
 * @return {!proto.cyberia.web.equipment.ParameterTypes} returns this
*/
proto.cyberia.web.equipment.ParameterTypes.prototype.setInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.web.equipment.ParameterType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.web.equipment.ParameterType}
 */
proto.cyberia.web.equipment.ParameterTypes.prototype.addInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.web.equipment.ParameterType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.web.equipment.ParameterTypes} returns this
 */
proto.cyberia.web.equipment.ParameterTypes.prototype.clearInfoList = function() {
  return this.setInfoList([]);
};


/**
 * @enum {number}
 */
proto.cyberia.web.equipment.ParameterOrderFields = {
  NAME: 0,
  DATATYPE: 1,
  OTPPATTERN: 2,
  UNIT: 3,
  COMMENT: 4,
  CALCINTERVAL: 5
};

/**
 * @enum {number}
 */
proto.cyberia.web.equipment.DataType = {
  INT: 0,
  SHORT: 1,
  LONG: 2,
  FLOAT: 3,
  DOUBLE: 4,
  BOOLEAN: 5,
  DATE: 6,
  TIME: 7,
  STRING: 8,
  BOOLEANSTRICT: 9
};

goog.object.extend(exports, proto.cyberia.web.equipment);
