// source: farm/FarmService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var Common_pb = require('../Common_pb.js');
goog.object.extend(proto, Common_pb);
var farm_CityService_pb = require('../farm/CityService_pb.js');
goog.object.extend(proto, farm_CityService_pb);
var security_EmployeeRoleService_pb = require('../security/EmployeeRoleService_pb.js');
goog.object.extend(proto, security_EmployeeRoleService_pb);
var web_equipment_OpcTags_pb = require('../web/equipment/OpcTags_pb.js');
goog.object.extend(proto, web_equipment_OpcTags_pb);
goog.exportSymbol('proto.cyberia.grpc.farm.EmployeeRoles', null, global);
goog.exportSymbol('proto.cyberia.grpc.farm.EmployeeRolesCollection', null, global);
goog.exportSymbol('proto.cyberia.grpc.farm.Farm', null, global);
goog.exportSymbol('proto.cyberia.grpc.farm.FarmsInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.farm.Farm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.farm.Farm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.farm.Farm.displayName = 'proto.cyberia.grpc.farm.Farm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.farm.EmployeeRoles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.farm.EmployeeRoles.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.farm.EmployeeRoles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.farm.EmployeeRoles.displayName = 'proto.cyberia.grpc.farm.EmployeeRoles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.farm.EmployeeRolesCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.farm.EmployeeRolesCollection.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.farm.EmployeeRolesCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.farm.EmployeeRolesCollection.displayName = 'proto.cyberia.grpc.farm.EmployeeRolesCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.farm.FarmsInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.farm.FarmsInfo.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.farm.FarmsInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.farm.FarmsInfo.displayName = 'proto.cyberia.grpc.farm.FarmsInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.farm.Farm.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.farm.Farm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.farm.Farm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.farm.Farm.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    city: (f = msg.getCity()) && farm_CityService_pb.City.toObject(includeInstance, f),
    streethouse: jspb.Message.getFieldWithDefault(msg, 3, ""),
    farmid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    chamberscount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    personcount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 9, ""),
    grafanafolder: jspb.Message.getFieldWithDefault(msg, 10, ""),
    opcservers: (f = msg.getOpcservers()) && web_equipment_OpcTags_pb.OpcServerList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.farm.Farm}
 */
proto.cyberia.grpc.farm.Farm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.farm.Farm;
  return proto.cyberia.grpc.farm.Farm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.farm.Farm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.farm.Farm}
 */
proto.cyberia.grpc.farm.Farm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new farm_CityService_pb.City;
      reader.readMessage(value,farm_CityService_pb.City.deserializeBinaryFromReader);
      msg.setCity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreethouse(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFarmid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChamberscount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPersoncount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrafanafolder(value);
      break;
    case 11:
      var value = new web_equipment_OpcTags_pb.OpcServerList;
      reader.readMessage(value,web_equipment_OpcTags_pb.OpcServerList.deserializeBinaryFromReader);
      msg.setOpcservers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.farm.Farm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.farm.Farm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.farm.Farm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.farm.Farm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      farm_CityService_pb.City.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOpcservers();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      web_equipment_OpcTags_pb.OpcServerList.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cyberia.grpc.farm.Farm.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.Farm.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional City city = 2;
 * @return {?proto.cyberia.grpc.farm.City}
 */
proto.cyberia.grpc.farm.Farm.prototype.getCity = function() {
  return /** @type{?proto.cyberia.grpc.farm.City} */ (
    jspb.Message.getWrapperField(this, farm_CityService_pb.City, 2));
};


/**
 * @param {?proto.cyberia.grpc.farm.City|undefined} value
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
*/
proto.cyberia.grpc.farm.Farm.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.Farm.prototype.hasCity = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string streetHouse = 3;
 * @return {string}
 */
proto.cyberia.grpc.farm.Farm.prototype.getStreethouse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.setStreethouse = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.clearStreethouse = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.Farm.prototype.hasStreethouse = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string farmID = 4;
 * @return {string}
 */
proto.cyberia.grpc.farm.Farm.prototype.getFarmid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.setFarmid = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.clearFarmid = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.Farm.prototype.hasFarmid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.cyberia.grpc.farm.Farm.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.setName = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.clearName = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.Farm.prototype.hasName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 chambersCount = 6;
 * @return {number}
 */
proto.cyberia.grpc.farm.Farm.prototype.getChamberscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.setChamberscount = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.clearChamberscount = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.Farm.prototype.hasChamberscount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 personCount = 7;
 * @return {number}
 */
proto.cyberia.grpc.farm.Farm.prototype.getPersoncount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.setPersoncount = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.clearPersoncount = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.Farm.prototype.hasPersoncount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string comment = 9;
 * @return {string}
 */
proto.cyberia.grpc.farm.Farm.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.setComment = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.clearComment = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.Farm.prototype.hasComment = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string grafanaFolder = 10;
 * @return {string}
 */
proto.cyberia.grpc.farm.Farm.prototype.getGrafanafolder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.setGrafanafolder = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.clearGrafanafolder = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.Farm.prototype.hasGrafanafolder = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional cyberia.web.equipment.OpcServerList opcServers = 11;
 * @return {?proto.cyberia.web.equipment.OpcServerList}
 */
proto.cyberia.grpc.farm.Farm.prototype.getOpcservers = function() {
  return /** @type{?proto.cyberia.web.equipment.OpcServerList} */ (
    jspb.Message.getWrapperField(this, web_equipment_OpcTags_pb.OpcServerList, 11));
};


/**
 * @param {?proto.cyberia.web.equipment.OpcServerList|undefined} value
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
*/
proto.cyberia.grpc.farm.Farm.prototype.setOpcservers = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.farm.Farm} returns this
 */
proto.cyberia.grpc.farm.Farm.prototype.clearOpcservers = function() {
  return this.setOpcservers(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.Farm.prototype.hasOpcservers = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.farm.EmployeeRoles.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.farm.EmployeeRoles.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.farm.EmployeeRoles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.farm.EmployeeRoles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.farm.EmployeeRoles.toObject = function(includeInstance, msg) {
  var f, obj = {
    employeeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    security_EmployeeRoleService_pb.EmployeeRole.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.farm.EmployeeRoles}
 */
proto.cyberia.grpc.farm.EmployeeRoles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.farm.EmployeeRoles;
  return proto.cyberia.grpc.farm.EmployeeRoles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.farm.EmployeeRoles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.farm.EmployeeRoles}
 */
proto.cyberia.grpc.farm.EmployeeRoles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmployeeid(value);
      break;
    case 2:
      var value = new security_EmployeeRoleService_pb.EmployeeRole;
      reader.readMessage(value,security_EmployeeRoleService_pb.EmployeeRole.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.farm.EmployeeRoles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.farm.EmployeeRoles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.farm.EmployeeRoles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.farm.EmployeeRoles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmployeeid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      security_EmployeeRoleService_pb.EmployeeRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 employeeId = 1;
 * @return {number}
 */
proto.cyberia.grpc.farm.EmployeeRoles.prototype.getEmployeeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.farm.EmployeeRoles} returns this
 */
proto.cyberia.grpc.farm.EmployeeRoles.prototype.setEmployeeid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated cyberia.grpc.user.EmployeeRole roles = 2;
 * @return {!Array<!proto.cyberia.grpc.user.EmployeeRole>}
 */
proto.cyberia.grpc.farm.EmployeeRoles.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.user.EmployeeRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, security_EmployeeRoleService_pb.EmployeeRole, 2));
};


/**
 * @param {!Array<!proto.cyberia.grpc.user.EmployeeRole>} value
 * @return {!proto.cyberia.grpc.farm.EmployeeRoles} returns this
*/
proto.cyberia.grpc.farm.EmployeeRoles.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cyberia.grpc.user.EmployeeRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.user.EmployeeRole}
 */
proto.cyberia.grpc.farm.EmployeeRoles.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cyberia.grpc.user.EmployeeRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.farm.EmployeeRoles} returns this
 */
proto.cyberia.grpc.farm.EmployeeRoles.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.farm.EmployeeRolesCollection.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.farm.EmployeeRolesCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.farm.EmployeeRolesCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.farm.EmployeeRolesCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.farm.EmployeeRolesCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    farm: (f = msg.getFarm()) && proto.cyberia.grpc.farm.Farm.toObject(includeInstance, f),
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.grpc.farm.EmployeeRoles.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.farm.EmployeeRolesCollection}
 */
proto.cyberia.grpc.farm.EmployeeRolesCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.farm.EmployeeRolesCollection;
  return proto.cyberia.grpc.farm.EmployeeRolesCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.farm.EmployeeRolesCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.farm.EmployeeRolesCollection}
 */
proto.cyberia.grpc.farm.EmployeeRolesCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.farm.Farm;
      reader.readMessage(value,proto.cyberia.grpc.farm.Farm.deserializeBinaryFromReader);
      msg.setFarm(value);
      break;
    case 2:
      var value = new proto.cyberia.grpc.farm.EmployeeRoles;
      reader.readMessage(value,proto.cyberia.grpc.farm.EmployeeRoles.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.farm.EmployeeRolesCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.farm.EmployeeRolesCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.farm.EmployeeRolesCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.farm.EmployeeRolesCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFarm();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cyberia.grpc.farm.Farm.serializeBinaryToWriter
    );
  }
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cyberia.grpc.farm.EmployeeRoles.serializeBinaryToWriter
    );
  }
};


/**
 * optional Farm farm = 1;
 * @return {?proto.cyberia.grpc.farm.Farm}
 */
proto.cyberia.grpc.farm.EmployeeRolesCollection.prototype.getFarm = function() {
  return /** @type{?proto.cyberia.grpc.farm.Farm} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.grpc.farm.Farm, 1));
};


/**
 * @param {?proto.cyberia.grpc.farm.Farm|undefined} value
 * @return {!proto.cyberia.grpc.farm.EmployeeRolesCollection} returns this
*/
proto.cyberia.grpc.farm.EmployeeRolesCollection.prototype.setFarm = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.farm.EmployeeRolesCollection} returns this
 */
proto.cyberia.grpc.farm.EmployeeRolesCollection.prototype.clearFarm = function() {
  return this.setFarm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.farm.EmployeeRolesCollection.prototype.hasFarm = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated EmployeeRoles list = 2;
 * @return {!Array<!proto.cyberia.grpc.farm.EmployeeRoles>}
 */
proto.cyberia.grpc.farm.EmployeeRolesCollection.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.farm.EmployeeRoles>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.grpc.farm.EmployeeRoles, 2));
};


/**
 * @param {!Array<!proto.cyberia.grpc.farm.EmployeeRoles>} value
 * @return {!proto.cyberia.grpc.farm.EmployeeRolesCollection} returns this
*/
proto.cyberia.grpc.farm.EmployeeRolesCollection.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cyberia.grpc.farm.EmployeeRoles=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.farm.EmployeeRoles}
 */
proto.cyberia.grpc.farm.EmployeeRolesCollection.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cyberia.grpc.farm.EmployeeRoles, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.farm.EmployeeRolesCollection} returns this
 */
proto.cyberia.grpc.farm.EmployeeRolesCollection.prototype.clearListList = function() {
  return this.setListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.farm.FarmsInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.farm.FarmsInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.farm.FarmsInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.farm.FarmsInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.farm.FarmsInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    farmsList: jspb.Message.toObjectList(msg.getFarmsList(),
    proto.cyberia.grpc.farm.Farm.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.farm.FarmsInfo}
 */
proto.cyberia.grpc.farm.FarmsInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.farm.FarmsInfo;
  return proto.cyberia.grpc.farm.FarmsInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.farm.FarmsInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.farm.FarmsInfo}
 */
proto.cyberia.grpc.farm.FarmsInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.farm.Farm;
      reader.readMessage(value,proto.cyberia.grpc.farm.Farm.deserializeBinaryFromReader);
      msg.addFarms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.farm.FarmsInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.farm.FarmsInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.farm.FarmsInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.farm.FarmsInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFarmsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.grpc.farm.Farm.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Farm farms = 1;
 * @return {!Array<!proto.cyberia.grpc.farm.Farm>}
 */
proto.cyberia.grpc.farm.FarmsInfo.prototype.getFarmsList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.farm.Farm>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.grpc.farm.Farm, 1));
};


/**
 * @param {!Array<!proto.cyberia.grpc.farm.Farm>} value
 * @return {!proto.cyberia.grpc.farm.FarmsInfo} returns this
*/
proto.cyberia.grpc.farm.FarmsInfo.prototype.setFarmsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.grpc.farm.Farm=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.farm.Farm}
 */
proto.cyberia.grpc.farm.FarmsInfo.prototype.addFarms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.grpc.farm.Farm, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.farm.FarmsInfo} returns this
 */
proto.cyberia.grpc.farm.FarmsInfo.prototype.clearFarmsList = function() {
  return this.setFarmsList([]);
};


goog.object.extend(exports, proto.cyberia.grpc.farm);
