// source: security/UserRegRequestService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var Common_pb = require('../Common_pb.js');
goog.object.extend(proto, Common_pb);
var farm_FarmService_pb = require('../farm/FarmService_pb.js');
goog.object.extend(proto, farm_FarmService_pb);
var security_EmployeePositionService_pb = require('../security/EmployeePositionService_pb.js');
goog.object.extend(proto, security_EmployeePositionService_pb);
var security_EmployeeRoleService_pb = require('../security/EmployeeRoleService_pb.js');
goog.object.extend(proto, security_EmployeeRoleService_pb);
goog.exportSymbol('proto.cyberia.grpc.user.UserFarmRoles', null, global);
goog.exportSymbol('proto.cyberia.grpc.user.UserFarmRolesCollection', null, global);
goog.exportSymbol('proto.cyberia.grpc.user.UserRegRequest', null, global);
goog.exportSymbol('proto.cyberia.grpc.user.UserRegRequestInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.user.UserRegRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.user.UserRegRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.user.UserRegRequest.displayName = 'proto.cyberia.grpc.user.UserRegRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.user.UserRegRequestInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.user.UserRegRequestInfo.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.user.UserRegRequestInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.user.UserRegRequestInfo.displayName = 'proto.cyberia.grpc.user.UserRegRequestInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.user.UserFarmRolesCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.user.UserFarmRolesCollection.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.user.UserFarmRolesCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.user.UserFarmRolesCollection.displayName = 'proto.cyberia.grpc.user.UserFarmRolesCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.user.UserFarmRoles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.user.UserFarmRoles.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.user.UserFarmRoles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.user.UserFarmRoles.displayName = 'proto.cyberia.grpc.user.UserFarmRoles';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.user.UserRegRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.user.UserRegRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.user.UserRegRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    credentials: (f = msg.getCredentials()) && Common_pb.Credentials.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    secondname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    thirdname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    position: (f = msg.getPosition()) && security_EmployeePositionService_pb.EmployeePosition.toObject(includeInstance, f),
    farmsandroles: (f = msg.getFarmsandroles()) && proto.cyberia.grpc.user.UserFarmRolesCollection.toObject(includeInstance, f),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    email: jspb.Message.getFieldWithDefault(msg, 9, ""),
    address: jspb.Message.getFieldWithDefault(msg, 10, ""),
    photo: (f = msg.getPhoto()) && Common_pb.File.toObject(includeInstance, f),
    regdate: (f = msg.getRegdate()) && Common_pb.Time.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.user.UserRegRequest}
 */
proto.cyberia.grpc.user.UserRegRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.user.UserRegRequest;
  return proto.cyberia.grpc.user.UserRegRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.user.UserRegRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.user.UserRegRequest}
 */
proto.cyberia.grpc.user.UserRegRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new Common_pb.Credentials;
      reader.readMessage(value,Common_pb.Credentials.deserializeBinaryFromReader);
      msg.setCredentials(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdname(value);
      break;
    case 6:
      var value = new security_EmployeePositionService_pb.EmployeePosition;
      reader.readMessage(value,security_EmployeePositionService_pb.EmployeePosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 7:
      var value = new proto.cyberia.grpc.user.UserFarmRolesCollection;
      reader.readMessage(value,proto.cyberia.grpc.user.UserFarmRolesCollection.deserializeBinaryFromReader);
      msg.setFarmsandroles(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 11:
      var value = new Common_pb.File;
      reader.readMessage(value,Common_pb.File.deserializeBinaryFromReader);
      msg.setPhoto(value);
      break;
    case 12:
      var value = new Common_pb.Time;
      reader.readMessage(value,Common_pb.Time.deserializeBinaryFromReader);
      msg.setRegdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.user.UserRegRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.user.UserRegRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.user.UserRegRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCredentials();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Common_pb.Credentials.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      security_EmployeePositionService_pb.EmployeePosition.serializeBinaryToWriter
    );
  }
  f = message.getFarmsandroles();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.cyberia.grpc.user.UserFarmRolesCollection.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPhoto();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Common_pb.File.serializeBinaryToWriter
    );
  }
  f = message.getRegdate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Common_pb.Time.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.Credentials credentials = 2;
 * @return {?proto.common.Credentials}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.getCredentials = function() {
  return /** @type{?proto.common.Credentials} */ (
    jspb.Message.getWrapperField(this, Common_pb.Credentials, 2));
};


/**
 * @param {?proto.common.Credentials|undefined} value
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
*/
proto.cyberia.grpc.user.UserRegRequest.prototype.setCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.clearCredentials = function() {
  return this.setCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.hasCredentials = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string secondName = 4;
 * @return {string}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.getSecondname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.setSecondname = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.clearSecondname = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.hasSecondname = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string thirdName = 5;
 * @return {string}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.getThirdname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.setThirdname = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.clearThirdname = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.hasThirdname = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional EmployeePosition position = 6;
 * @return {?proto.cyberia.grpc.user.EmployeePosition}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.getPosition = function() {
  return /** @type{?proto.cyberia.grpc.user.EmployeePosition} */ (
    jspb.Message.getWrapperField(this, security_EmployeePositionService_pb.EmployeePosition, 6));
};


/**
 * @param {?proto.cyberia.grpc.user.EmployeePosition|undefined} value
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
*/
proto.cyberia.grpc.user.UserRegRequest.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UserFarmRolesCollection farmsAndRoles = 7;
 * @return {?proto.cyberia.grpc.user.UserFarmRolesCollection}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.getFarmsandroles = function() {
  return /** @type{?proto.cyberia.grpc.user.UserFarmRolesCollection} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.grpc.user.UserFarmRolesCollection, 7));
};


/**
 * @param {?proto.cyberia.grpc.user.UserFarmRolesCollection|undefined} value
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
*/
proto.cyberia.grpc.user.UserRegRequest.prototype.setFarmsandroles = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.clearFarmsandroles = function() {
  return this.setFarmsandroles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.hasFarmsandroles = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string phoneNumber = 8;
 * @return {string}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.setPhonenumber = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.clearPhonenumber = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.hasPhonenumber = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string eMail = 9;
 * @return {string}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.setEmail = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.clearEmail = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string address = 10;
 * @return {string}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.setAddress = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.clearAddress = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional common.File photo = 11;
 * @return {?proto.common.File}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.getPhoto = function() {
  return /** @type{?proto.common.File} */ (
    jspb.Message.getWrapperField(this, Common_pb.File, 11));
};


/**
 * @param {?proto.common.File|undefined} value
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
*/
proto.cyberia.grpc.user.UserRegRequest.prototype.setPhoto = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.clearPhoto = function() {
  return this.setPhoto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.hasPhoto = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional common.Time regDate = 12;
 * @return {?proto.common.Time}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.getRegdate = function() {
  return /** @type{?proto.common.Time} */ (
    jspb.Message.getWrapperField(this, Common_pb.Time, 12));
};


/**
 * @param {?proto.common.Time|undefined} value
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
*/
proto.cyberia.grpc.user.UserRegRequest.prototype.setRegdate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.user.UserRegRequest} returns this
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.clearRegdate = function() {
  return this.setRegdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.user.UserRegRequest.prototype.hasRegdate = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.user.UserRegRequestInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.user.UserRegRequestInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.user.UserRegRequestInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.user.UserRegRequestInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.user.UserRegRequestInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestsList: jspb.Message.toObjectList(msg.getRequestsList(),
    proto.cyberia.grpc.user.UserRegRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.user.UserRegRequestInfo}
 */
proto.cyberia.grpc.user.UserRegRequestInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.user.UserRegRequestInfo;
  return proto.cyberia.grpc.user.UserRegRequestInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.user.UserRegRequestInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.user.UserRegRequestInfo}
 */
proto.cyberia.grpc.user.UserRegRequestInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.user.UserRegRequest;
      reader.readMessage(value,proto.cyberia.grpc.user.UserRegRequest.deserializeBinaryFromReader);
      msg.addRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.user.UserRegRequestInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.user.UserRegRequestInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.user.UserRegRequestInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.user.UserRegRequestInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.grpc.user.UserRegRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserRegRequest requests = 1;
 * @return {!Array<!proto.cyberia.grpc.user.UserRegRequest>}
 */
proto.cyberia.grpc.user.UserRegRequestInfo.prototype.getRequestsList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.user.UserRegRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.grpc.user.UserRegRequest, 1));
};


/**
 * @param {!Array<!proto.cyberia.grpc.user.UserRegRequest>} value
 * @return {!proto.cyberia.grpc.user.UserRegRequestInfo} returns this
*/
proto.cyberia.grpc.user.UserRegRequestInfo.prototype.setRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.grpc.user.UserRegRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.user.UserRegRequest}
 */
proto.cyberia.grpc.user.UserRegRequestInfo.prototype.addRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.grpc.user.UserRegRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.user.UserRegRequestInfo} returns this
 */
proto.cyberia.grpc.user.UserRegRequestInfo.prototype.clearRequestsList = function() {
  return this.setRequestsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.user.UserFarmRolesCollection.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.user.UserFarmRolesCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.user.UserFarmRolesCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.user.UserFarmRolesCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.user.UserFarmRolesCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    userfarmrolesList: jspb.Message.toObjectList(msg.getUserfarmrolesList(),
    proto.cyberia.grpc.user.UserFarmRoles.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.user.UserFarmRolesCollection}
 */
proto.cyberia.grpc.user.UserFarmRolesCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.user.UserFarmRolesCollection;
  return proto.cyberia.grpc.user.UserFarmRolesCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.user.UserFarmRolesCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.user.UserFarmRolesCollection}
 */
proto.cyberia.grpc.user.UserFarmRolesCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.user.UserFarmRoles;
      reader.readMessage(value,proto.cyberia.grpc.user.UserFarmRoles.deserializeBinaryFromReader);
      msg.addUserfarmroles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.user.UserFarmRolesCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.user.UserFarmRolesCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.user.UserFarmRolesCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.user.UserFarmRolesCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserfarmrolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.grpc.user.UserFarmRoles.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserFarmRoles userFarmRoles = 1;
 * @return {!Array<!proto.cyberia.grpc.user.UserFarmRoles>}
 */
proto.cyberia.grpc.user.UserFarmRolesCollection.prototype.getUserfarmrolesList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.user.UserFarmRoles>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.grpc.user.UserFarmRoles, 1));
};


/**
 * @param {!Array<!proto.cyberia.grpc.user.UserFarmRoles>} value
 * @return {!proto.cyberia.grpc.user.UserFarmRolesCollection} returns this
*/
proto.cyberia.grpc.user.UserFarmRolesCollection.prototype.setUserfarmrolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.grpc.user.UserFarmRoles=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.user.UserFarmRoles}
 */
proto.cyberia.grpc.user.UserFarmRolesCollection.prototype.addUserfarmroles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.grpc.user.UserFarmRoles, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.user.UserFarmRolesCollection} returns this
 */
proto.cyberia.grpc.user.UserFarmRolesCollection.prototype.clearUserfarmrolesList = function() {
  return this.setUserfarmrolesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.user.UserFarmRoles.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.user.UserFarmRoles.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.user.UserFarmRoles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.user.UserFarmRoles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.user.UserFarmRoles.toObject = function(includeInstance, msg) {
  var f, obj = {
    farm: (f = msg.getFarm()) && farm_FarmService_pb.Farm.toObject(includeInstance, f),
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    security_EmployeeRoleService_pb.EmployeeRole.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.user.UserFarmRoles}
 */
proto.cyberia.grpc.user.UserFarmRoles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.user.UserFarmRoles;
  return proto.cyberia.grpc.user.UserFarmRoles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.user.UserFarmRoles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.user.UserFarmRoles}
 */
proto.cyberia.grpc.user.UserFarmRoles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new farm_FarmService_pb.Farm;
      reader.readMessage(value,farm_FarmService_pb.Farm.deserializeBinaryFromReader);
      msg.setFarm(value);
      break;
    case 2:
      var value = new security_EmployeeRoleService_pb.EmployeeRole;
      reader.readMessage(value,security_EmployeeRoleService_pb.EmployeeRole.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.user.UserFarmRoles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.user.UserFarmRoles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.user.UserFarmRoles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.user.UserFarmRoles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFarm();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      farm_FarmService_pb.Farm.serializeBinaryToWriter
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      security_EmployeeRoleService_pb.EmployeeRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional cyberia.grpc.farm.Farm farm = 1;
 * @return {?proto.cyberia.grpc.farm.Farm}
 */
proto.cyberia.grpc.user.UserFarmRoles.prototype.getFarm = function() {
  return /** @type{?proto.cyberia.grpc.farm.Farm} */ (
    jspb.Message.getWrapperField(this, farm_FarmService_pb.Farm, 1));
};


/**
 * @param {?proto.cyberia.grpc.farm.Farm|undefined} value
 * @return {!proto.cyberia.grpc.user.UserFarmRoles} returns this
*/
proto.cyberia.grpc.user.UserFarmRoles.prototype.setFarm = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.user.UserFarmRoles} returns this
 */
proto.cyberia.grpc.user.UserFarmRoles.prototype.clearFarm = function() {
  return this.setFarm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.user.UserFarmRoles.prototype.hasFarm = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated EmployeeRole roles = 2;
 * @return {!Array<!proto.cyberia.grpc.user.EmployeeRole>}
 */
proto.cyberia.grpc.user.UserFarmRoles.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.user.EmployeeRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, security_EmployeeRoleService_pb.EmployeeRole, 2));
};


/**
 * @param {!Array<!proto.cyberia.grpc.user.EmployeeRole>} value
 * @return {!proto.cyberia.grpc.user.UserFarmRoles} returns this
*/
proto.cyberia.grpc.user.UserFarmRoles.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cyberia.grpc.user.EmployeeRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.user.EmployeeRole}
 */
proto.cyberia.grpc.user.UserFarmRoles.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cyberia.grpc.user.EmployeeRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.user.UserFarmRoles} returns this
 */
proto.cyberia.grpc.user.UserFarmRoles.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


goog.object.extend(exports, proto.cyberia.grpc.user);
