// source: process/TechnicalProcessProfile.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var Common_pb = require('../Common_pb.js');
goog.object.extend(proto, Common_pb);
var process_AgroCulture_pb = require('../process/AgroCulture_pb.js');
goog.object.extend(proto, process_AgroCulture_pb);
var process_PhaseOfDay_pb = require('../process/PhaseOfDay_pb.js');
goog.object.extend(proto, process_PhaseOfDay_pb);
var process_ProcessMode_pb = require('../process/ProcessMode_pb.js');
goog.object.extend(proto, process_ProcessMode_pb);
var process_StructurePP_pb = require('../process/StructurePP_pb.js');
goog.object.extend(proto, process_StructurePP_pb);
var web_equipment_ParametersService_pb = require('../web/equipment/ParametersService_pb.js');
goog.object.extend(proto, web_equipment_ParametersService_pb);
goog.exportSymbol('proto.cyberia.grpc.process.ParameterValue', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.ProcessStage', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.ProcessStages', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.StageSetting', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.StageSettingsList', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.TechnicalProcessProfile', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.TechnicalProcessProfiles', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.TimeOfPhase', null, global);
goog.exportSymbol('proto.cyberia.grpc.process.TimeOfPhaseList', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.ProcessStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.process.ProcessStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.ProcessStage.displayName = 'proto.cyberia.grpc.process.ProcessStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.ProcessStages = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.process.ProcessStages.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.process.ProcessStages, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.ProcessStages.displayName = 'proto.cyberia.grpc.process.ProcessStages';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.TimeOfPhase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.process.TimeOfPhase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.TimeOfPhase.displayName = 'proto.cyberia.grpc.process.TimeOfPhase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.TimeOfPhaseList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.process.TimeOfPhaseList.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.process.TimeOfPhaseList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.TimeOfPhaseList.displayName = 'proto.cyberia.grpc.process.TimeOfPhaseList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.ParameterValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.process.ParameterValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.ParameterValue.displayName = 'proto.cyberia.grpc.process.ParameterValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.StageSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.process.StageSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.StageSetting.displayName = 'proto.cyberia.grpc.process.StageSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.StageSettingsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.process.StageSettingsList.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.process.StageSettingsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.StageSettingsList.displayName = 'proto.cyberia.grpc.process.StageSettingsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.TechnicalProcessProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cyberia.grpc.process.TechnicalProcessProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.TechnicalProcessProfile.displayName = 'proto.cyberia.grpc.process.TechnicalProcessProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cyberia.grpc.process.TechnicalProcessProfiles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cyberia.grpc.process.TechnicalProcessProfiles.repeatedFields_, null);
};
goog.inherits(proto.cyberia.grpc.process.TechnicalProcessProfiles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cyberia.grpc.process.TechnicalProcessProfiles.displayName = 'proto.cyberia.grpc.process.TechnicalProcessProfiles';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.ProcessStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.ProcessStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ProcessStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    technicalprocessprofileid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    icon: (f = msg.getIcon()) && Common_pb.File.toObject(includeInstance, f),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0),
    nominaldurationsec: jspb.Message.getFieldWithDefault(msg, 6, 0),
    phases: (f = msg.getPhases()) && proto.cyberia.grpc.process.TimeOfPhaseList.toObject(includeInstance, f),
    settings: (f = msg.getSettings()) && proto.cyberia.grpc.process.StageSettingsList.toObject(includeInstance, f),
    countofdays: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.ProcessStage}
 */
proto.cyberia.grpc.process.ProcessStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.ProcessStage;
  return proto.cyberia.grpc.process.ProcessStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.ProcessStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.ProcessStage}
 */
proto.cyberia.grpc.process.ProcessStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTechnicalprocessprofileid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new Common_pb.File;
      reader.readMessage(value,Common_pb.File.deserializeBinaryFromReader);
      msg.setIcon(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNominaldurationsec(value);
      break;
    case 7:
      var value = new proto.cyberia.grpc.process.TimeOfPhaseList;
      reader.readMessage(value,proto.cyberia.grpc.process.TimeOfPhaseList.deserializeBinaryFromReader);
      msg.setPhases(value);
      break;
    case 8:
      var value = new proto.cyberia.grpc.process.StageSettingsList;
      reader.readMessage(value,proto.cyberia.grpc.process.StageSettingsList.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCountofdays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.ProcessStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.ProcessStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ProcessStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIcon();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Common_pb.File.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPhases();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.cyberia.grpc.process.TimeOfPhaseList.serializeBinaryToWriter
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.cyberia.grpc.process.StageSettingsList.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeFloat(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
 */
proto.cyberia.grpc.process.ProcessStage.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
 */
proto.cyberia.grpc.process.ProcessStage.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 technicalProcessProfileId = 2;
 * @return {number}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.getTechnicalprocessprofileid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
 */
proto.cyberia.grpc.process.ProcessStage.prototype.setTechnicalprocessprofileid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
 */
proto.cyberia.grpc.process.ProcessStage.prototype.clearTechnicalprocessprofileid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.hasTechnicalprocessprofileid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
 */
proto.cyberia.grpc.process.ProcessStage.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
 */
proto.cyberia.grpc.process.ProcessStage.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.File icon = 4;
 * @return {?proto.common.File}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.getIcon = function() {
  return /** @type{?proto.common.File} */ (
    jspb.Message.getWrapperField(this, Common_pb.File, 4));
};


/**
 * @param {?proto.common.File|undefined} value
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
*/
proto.cyberia.grpc.process.ProcessStage.prototype.setIcon = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
 */
proto.cyberia.grpc.process.ProcessStage.prototype.clearIcon = function() {
  return this.setIcon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.hasIcon = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 order = 5;
 * @return {number}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
 */
proto.cyberia.grpc.process.ProcessStage.prototype.setOrder = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
 */
proto.cyberia.grpc.process.ProcessStage.prototype.clearOrder = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 nominalDurationSec = 6;
 * @return {number}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.getNominaldurationsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
 */
proto.cyberia.grpc.process.ProcessStage.prototype.setNominaldurationsec = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
 */
proto.cyberia.grpc.process.ProcessStage.prototype.clearNominaldurationsec = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.hasNominaldurationsec = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TimeOfPhaseList phases = 7;
 * @return {?proto.cyberia.grpc.process.TimeOfPhaseList}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.getPhases = function() {
  return /** @type{?proto.cyberia.grpc.process.TimeOfPhaseList} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.grpc.process.TimeOfPhaseList, 7));
};


/**
 * @param {?proto.cyberia.grpc.process.TimeOfPhaseList|undefined} value
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
*/
proto.cyberia.grpc.process.ProcessStage.prototype.setPhases = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
 */
proto.cyberia.grpc.process.ProcessStage.prototype.clearPhases = function() {
  return this.setPhases(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.hasPhases = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional StageSettingsList settings = 8;
 * @return {?proto.cyberia.grpc.process.StageSettingsList}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.getSettings = function() {
  return /** @type{?proto.cyberia.grpc.process.StageSettingsList} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.grpc.process.StageSettingsList, 8));
};


/**
 * @param {?proto.cyberia.grpc.process.StageSettingsList|undefined} value
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
*/
proto.cyberia.grpc.process.ProcessStage.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
 */
proto.cyberia.grpc.process.ProcessStage.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional float countOfDays = 9;
 * @return {number}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.getCountofdays = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
 */
proto.cyberia.grpc.process.ProcessStage.prototype.setCountofdays = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.ProcessStage} returns this
 */
proto.cyberia.grpc.process.ProcessStage.prototype.clearCountofdays = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ProcessStage.prototype.hasCountofdays = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.process.ProcessStages.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.ProcessStages.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.ProcessStages.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.ProcessStages} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ProcessStages.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.grpc.process.ProcessStage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.ProcessStages}
 */
proto.cyberia.grpc.process.ProcessStages.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.ProcessStages;
  return proto.cyberia.grpc.process.ProcessStages.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.ProcessStages} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.ProcessStages}
 */
proto.cyberia.grpc.process.ProcessStages.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.process.ProcessStage;
      reader.readMessage(value,proto.cyberia.grpc.process.ProcessStage.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.ProcessStages.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.ProcessStages.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.ProcessStages} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ProcessStages.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.grpc.process.ProcessStage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProcessStage list = 1;
 * @return {!Array<!proto.cyberia.grpc.process.ProcessStage>}
 */
proto.cyberia.grpc.process.ProcessStages.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.process.ProcessStage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.grpc.process.ProcessStage, 1));
};


/**
 * @param {!Array<!proto.cyberia.grpc.process.ProcessStage>} value
 * @return {!proto.cyberia.grpc.process.ProcessStages} returns this
*/
proto.cyberia.grpc.process.ProcessStages.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.grpc.process.ProcessStage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.process.ProcessStage}
 */
proto.cyberia.grpc.process.ProcessStages.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.grpc.process.ProcessStage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.process.ProcessStages} returns this
 */
proto.cyberia.grpc.process.ProcessStages.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.TimeOfPhase.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.TimeOfPhase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.TimeOfPhase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.TimeOfPhase.toObject = function(includeInstance, msg) {
  var f, obj = {
    phase: (f = msg.getPhase()) && process_PhaseOfDay_pb.PhaseOfDay.toObject(includeInstance, f),
    durationmsec: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.TimeOfPhase}
 */
proto.cyberia.grpc.process.TimeOfPhase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.TimeOfPhase;
  return proto.cyberia.grpc.process.TimeOfPhase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.TimeOfPhase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.TimeOfPhase}
 */
proto.cyberia.grpc.process.TimeOfPhase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new process_PhaseOfDay_pb.PhaseOfDay;
      reader.readMessage(value,process_PhaseOfDay_pb.PhaseOfDay.deserializeBinaryFromReader);
      msg.setPhase(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDurationmsec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.TimeOfPhase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.TimeOfPhase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.TimeOfPhase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.TimeOfPhase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      process_PhaseOfDay_pb.PhaseOfDay.serializeBinaryToWriter
    );
  }
  f = message.getDurationmsec();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional PhaseOfDay phase = 1;
 * @return {?proto.cyberia.grpc.process.PhaseOfDay}
 */
proto.cyberia.grpc.process.TimeOfPhase.prototype.getPhase = function() {
  return /** @type{?proto.cyberia.grpc.process.PhaseOfDay} */ (
    jspb.Message.getWrapperField(this, process_PhaseOfDay_pb.PhaseOfDay, 1));
};


/**
 * @param {?proto.cyberia.grpc.process.PhaseOfDay|undefined} value
 * @return {!proto.cyberia.grpc.process.TimeOfPhase} returns this
*/
proto.cyberia.grpc.process.TimeOfPhase.prototype.setPhase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.TimeOfPhase} returns this
 */
proto.cyberia.grpc.process.TimeOfPhase.prototype.clearPhase = function() {
  return this.setPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.TimeOfPhase.prototype.hasPhase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 durationMSec = 2;
 * @return {number}
 */
proto.cyberia.grpc.process.TimeOfPhase.prototype.getDurationmsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.TimeOfPhase} returns this
 */
proto.cyberia.grpc.process.TimeOfPhase.prototype.setDurationmsec = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.process.TimeOfPhaseList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.TimeOfPhaseList.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.TimeOfPhaseList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.TimeOfPhaseList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.TimeOfPhaseList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.grpc.process.TimeOfPhase.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.TimeOfPhaseList}
 */
proto.cyberia.grpc.process.TimeOfPhaseList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.TimeOfPhaseList;
  return proto.cyberia.grpc.process.TimeOfPhaseList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.TimeOfPhaseList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.TimeOfPhaseList}
 */
proto.cyberia.grpc.process.TimeOfPhaseList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.process.TimeOfPhase;
      reader.readMessage(value,proto.cyberia.grpc.process.TimeOfPhase.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.TimeOfPhaseList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.TimeOfPhaseList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.TimeOfPhaseList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.TimeOfPhaseList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.grpc.process.TimeOfPhase.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TimeOfPhase list = 1;
 * @return {!Array<!proto.cyberia.grpc.process.TimeOfPhase>}
 */
proto.cyberia.grpc.process.TimeOfPhaseList.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.process.TimeOfPhase>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.grpc.process.TimeOfPhase, 1));
};


/**
 * @param {!Array<!proto.cyberia.grpc.process.TimeOfPhase>} value
 * @return {!proto.cyberia.grpc.process.TimeOfPhaseList} returns this
*/
proto.cyberia.grpc.process.TimeOfPhaseList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.grpc.process.TimeOfPhase=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.process.TimeOfPhase}
 */
proto.cyberia.grpc.process.TimeOfPhaseList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.grpc.process.TimeOfPhase, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.process.TimeOfPhaseList} returns this
 */
proto.cyberia.grpc.process.TimeOfPhaseList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.ParameterValue.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.ParameterValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.ParameterValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ParameterValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && process_ProcessMode_pb.ModeSingleValue.toObject(includeInstance, f),
    parameter: (f = msg.getParameter()) && web_equipment_ParametersService_pb.ParameterType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.ParameterValue}
 */
proto.cyberia.grpc.process.ParameterValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.ParameterValue;
  return proto.cyberia.grpc.process.ParameterValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.ParameterValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.ParameterValue}
 */
proto.cyberia.grpc.process.ParameterValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new process_ProcessMode_pb.ModeSingleValue;
      reader.readMessage(value,process_ProcessMode_pb.ModeSingleValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = new web_equipment_ParametersService_pb.ParameterType;
      reader.readMessage(value,web_equipment_ParametersService_pb.ParameterType.deserializeBinaryFromReader);
      msg.setParameter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.ParameterValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.ParameterValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.ParameterValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.ParameterValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      process_ProcessMode_pb.ModeSingleValue.serializeBinaryToWriter
    );
  }
  f = message.getParameter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      web_equipment_ParametersService_pb.ParameterType.serializeBinaryToWriter
    );
  }
};


/**
 * optional ModeSingleValue value = 1;
 * @return {?proto.cyberia.grpc.process.ModeSingleValue}
 */
proto.cyberia.grpc.process.ParameterValue.prototype.getValue = function() {
  return /** @type{?proto.cyberia.grpc.process.ModeSingleValue} */ (
    jspb.Message.getWrapperField(this, process_ProcessMode_pb.ModeSingleValue, 1));
};


/**
 * @param {?proto.cyberia.grpc.process.ModeSingleValue|undefined} value
 * @return {!proto.cyberia.grpc.process.ParameterValue} returns this
*/
proto.cyberia.grpc.process.ParameterValue.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.ParameterValue} returns this
 */
proto.cyberia.grpc.process.ParameterValue.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ParameterValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional cyberia.web.equipment.ParameterType parameter = 2;
 * @return {?proto.cyberia.web.equipment.ParameterType}
 */
proto.cyberia.grpc.process.ParameterValue.prototype.getParameter = function() {
  return /** @type{?proto.cyberia.web.equipment.ParameterType} */ (
    jspb.Message.getWrapperField(this, web_equipment_ParametersService_pb.ParameterType, 2));
};


/**
 * @param {?proto.cyberia.web.equipment.ParameterType|undefined} value
 * @return {!proto.cyberia.grpc.process.ParameterValue} returns this
*/
proto.cyberia.grpc.process.ParameterValue.prototype.setParameter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.ParameterValue} returns this
 */
proto.cyberia.grpc.process.ParameterValue.prototype.clearParameter = function() {
  return this.setParameter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.ParameterValue.prototype.hasParameter = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.StageSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.StageSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.StageSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.StageSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    phase: (f = msg.getPhase()) && process_PhaseOfDay_pb.PhaseOfDay.toObject(includeInstance, f),
    parameter: (f = msg.getParameter()) && proto.cyberia.grpc.process.ParameterValue.toObject(includeInstance, f),
    mode: (f = msg.getMode()) && process_ProcessMode_pb.ModeOfProcess.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.StageSetting}
 */
proto.cyberia.grpc.process.StageSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.StageSetting;
  return proto.cyberia.grpc.process.StageSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.StageSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.StageSetting}
 */
proto.cyberia.grpc.process.StageSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new process_PhaseOfDay_pb.PhaseOfDay;
      reader.readMessage(value,process_PhaseOfDay_pb.PhaseOfDay.deserializeBinaryFromReader);
      msg.setPhase(value);
      break;
    case 2:
      var value = new proto.cyberia.grpc.process.ParameterValue;
      reader.readMessage(value,proto.cyberia.grpc.process.ParameterValue.deserializeBinaryFromReader);
      msg.setParameter(value);
      break;
    case 3:
      var value = new process_ProcessMode_pb.ModeOfProcess;
      reader.readMessage(value,process_ProcessMode_pb.ModeOfProcess.deserializeBinaryFromReader);
      msg.setMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.StageSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.StageSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.StageSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.StageSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhase();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      process_PhaseOfDay_pb.PhaseOfDay.serializeBinaryToWriter
    );
  }
  f = message.getParameter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.cyberia.grpc.process.ParameterValue.serializeBinaryToWriter
    );
  }
  f = message.getMode();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      process_ProcessMode_pb.ModeOfProcess.serializeBinaryToWriter
    );
  }
};


/**
 * optional PhaseOfDay phase = 1;
 * @return {?proto.cyberia.grpc.process.PhaseOfDay}
 */
proto.cyberia.grpc.process.StageSetting.prototype.getPhase = function() {
  return /** @type{?proto.cyberia.grpc.process.PhaseOfDay} */ (
    jspb.Message.getWrapperField(this, process_PhaseOfDay_pb.PhaseOfDay, 1));
};


/**
 * @param {?proto.cyberia.grpc.process.PhaseOfDay|undefined} value
 * @return {!proto.cyberia.grpc.process.StageSetting} returns this
*/
proto.cyberia.grpc.process.StageSetting.prototype.setPhase = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.StageSetting} returns this
 */
proto.cyberia.grpc.process.StageSetting.prototype.clearPhase = function() {
  return this.setPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.StageSetting.prototype.hasPhase = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ParameterValue parameter = 2;
 * @return {?proto.cyberia.grpc.process.ParameterValue}
 */
proto.cyberia.grpc.process.StageSetting.prototype.getParameter = function() {
  return /** @type{?proto.cyberia.grpc.process.ParameterValue} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.grpc.process.ParameterValue, 2));
};


/**
 * @param {?proto.cyberia.grpc.process.ParameterValue|undefined} value
 * @return {!proto.cyberia.grpc.process.StageSetting} returns this
*/
proto.cyberia.grpc.process.StageSetting.prototype.setParameter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.StageSetting} returns this
 */
proto.cyberia.grpc.process.StageSetting.prototype.clearParameter = function() {
  return this.setParameter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.StageSetting.prototype.hasParameter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ModeOfProcess mode = 3;
 * @return {?proto.cyberia.grpc.process.ModeOfProcess}
 */
proto.cyberia.grpc.process.StageSetting.prototype.getMode = function() {
  return /** @type{?proto.cyberia.grpc.process.ModeOfProcess} */ (
    jspb.Message.getWrapperField(this, process_ProcessMode_pb.ModeOfProcess, 3));
};


/**
 * @param {?proto.cyberia.grpc.process.ModeOfProcess|undefined} value
 * @return {!proto.cyberia.grpc.process.StageSetting} returns this
*/
proto.cyberia.grpc.process.StageSetting.prototype.setMode = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.StageSetting} returns this
 */
proto.cyberia.grpc.process.StageSetting.prototype.clearMode = function() {
  return this.setMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.StageSetting.prototype.hasMode = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.process.StageSettingsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.StageSettingsList.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.StageSettingsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.StageSettingsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.StageSettingsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.grpc.process.StageSetting.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.StageSettingsList}
 */
proto.cyberia.grpc.process.StageSettingsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.StageSettingsList;
  return proto.cyberia.grpc.process.StageSettingsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.StageSettingsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.StageSettingsList}
 */
proto.cyberia.grpc.process.StageSettingsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.process.StageSetting;
      reader.readMessage(value,proto.cyberia.grpc.process.StageSetting.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.StageSettingsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.StageSettingsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.StageSettingsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.StageSettingsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.grpc.process.StageSetting.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StageSetting list = 1;
 * @return {!Array<!proto.cyberia.grpc.process.StageSetting>}
 */
proto.cyberia.grpc.process.StageSettingsList.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.process.StageSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.grpc.process.StageSetting, 1));
};


/**
 * @param {!Array<!proto.cyberia.grpc.process.StageSetting>} value
 * @return {!proto.cyberia.grpc.process.StageSettingsList} returns this
*/
proto.cyberia.grpc.process.StageSettingsList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.grpc.process.StageSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.process.StageSetting}
 */
proto.cyberia.grpc.process.StageSettingsList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.grpc.process.StageSetting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.process.StageSettingsList} returns this
 */
proto.cyberia.grpc.process.StageSettingsList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.TechnicalProcessProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.TechnicalProcessProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    culture: (f = msg.getCulture()) && process_AgroCulture_pb.AgroCulture.toObject(includeInstance, f),
    stages: (f = msg.getStages()) && proto.cyberia.grpc.process.ProcessStages.toObject(includeInstance, f),
    defaultstage: (f = msg.getDefaultstage()) && proto.cyberia.grpc.process.ProcessStage.toObject(includeInstance, f),
    structure: (f = msg.getStructure()) && process_StructurePP_pb.StructurePP.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfile}
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.TechnicalProcessProfile;
  return proto.cyberia.grpc.process.TechnicalProcessProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.TechnicalProcessProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfile}
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = new process_AgroCulture_pb.AgroCulture;
      reader.readMessage(value,process_AgroCulture_pb.AgroCulture.deserializeBinaryFromReader);
      msg.setCulture(value);
      break;
    case 4:
      var value = new proto.cyberia.grpc.process.ProcessStages;
      reader.readMessage(value,proto.cyberia.grpc.process.ProcessStages.deserializeBinaryFromReader);
      msg.setStages(value);
      break;
    case 5:
      var value = new proto.cyberia.grpc.process.ProcessStage;
      reader.readMessage(value,proto.cyberia.grpc.process.ProcessStage.deserializeBinaryFromReader);
      msg.setDefaultstage(value);
      break;
    case 6:
      var value = new process_StructurePP_pb.StructurePP;
      reader.readMessage(value,process_StructurePP_pb.StructurePP.deserializeBinaryFromReader);
      msg.setStructure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.TechnicalProcessProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.TechnicalProcessProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCulture();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      process_AgroCulture_pb.AgroCulture.serializeBinaryToWriter
    );
  }
  f = message.getStages();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.cyberia.grpc.process.ProcessStages.serializeBinaryToWriter
    );
  }
  f = message.getDefaultstage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.cyberia.grpc.process.ProcessStage.serializeBinaryToWriter
    );
  }
  f = message.getStructure();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      process_StructurePP_pb.StructurePP.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfile} returns this
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfile} returns this
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfile} returns this
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfile} returns this
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AgroCulture culture = 3;
 * @return {?proto.cyberia.grpc.process.AgroCulture}
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.getCulture = function() {
  return /** @type{?proto.cyberia.grpc.process.AgroCulture} */ (
    jspb.Message.getWrapperField(this, process_AgroCulture_pb.AgroCulture, 3));
};


/**
 * @param {?proto.cyberia.grpc.process.AgroCulture|undefined} value
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfile} returns this
*/
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.setCulture = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfile} returns this
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.clearCulture = function() {
  return this.setCulture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.hasCulture = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProcessStages stages = 4;
 * @return {?proto.cyberia.grpc.process.ProcessStages}
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.getStages = function() {
  return /** @type{?proto.cyberia.grpc.process.ProcessStages} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.grpc.process.ProcessStages, 4));
};


/**
 * @param {?proto.cyberia.grpc.process.ProcessStages|undefined} value
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfile} returns this
*/
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.setStages = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfile} returns this
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.clearStages = function() {
  return this.setStages(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.hasStages = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ProcessStage defaultStage = 5;
 * @return {?proto.cyberia.grpc.process.ProcessStage}
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.getDefaultstage = function() {
  return /** @type{?proto.cyberia.grpc.process.ProcessStage} */ (
    jspb.Message.getWrapperField(this, proto.cyberia.grpc.process.ProcessStage, 5));
};


/**
 * @param {?proto.cyberia.grpc.process.ProcessStage|undefined} value
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfile} returns this
*/
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.setDefaultstage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfile} returns this
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.clearDefaultstage = function() {
  return this.setDefaultstage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.hasDefaultstage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional StructurePP structure = 6;
 * @return {?proto.cyberia.grpc.process.StructurePP}
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.getStructure = function() {
  return /** @type{?proto.cyberia.grpc.process.StructurePP} */ (
    jspb.Message.getWrapperField(this, process_StructurePP_pb.StructurePP, 6));
};


/**
 * @param {?proto.cyberia.grpc.process.StructurePP|undefined} value
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfile} returns this
*/
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.setStructure = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfile} returns this
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.clearStructure = function() {
  return this.setStructure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cyberia.grpc.process.TechnicalProcessProfile.prototype.hasStructure = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cyberia.grpc.process.TechnicalProcessProfiles.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cyberia.grpc.process.TechnicalProcessProfiles.prototype.toObject = function(opt_includeInstance) {
  return proto.cyberia.grpc.process.TechnicalProcessProfiles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cyberia.grpc.process.TechnicalProcessProfiles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.TechnicalProcessProfiles.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.cyberia.grpc.process.TechnicalProcessProfile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfiles}
 */
proto.cyberia.grpc.process.TechnicalProcessProfiles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cyberia.grpc.process.TechnicalProcessProfiles;
  return proto.cyberia.grpc.process.TechnicalProcessProfiles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cyberia.grpc.process.TechnicalProcessProfiles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfiles}
 */
proto.cyberia.grpc.process.TechnicalProcessProfiles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cyberia.grpc.process.TechnicalProcessProfile;
      reader.readMessage(value,proto.cyberia.grpc.process.TechnicalProcessProfile.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cyberia.grpc.process.TechnicalProcessProfiles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cyberia.grpc.process.TechnicalProcessProfiles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cyberia.grpc.process.TechnicalProcessProfiles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cyberia.grpc.process.TechnicalProcessProfiles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cyberia.grpc.process.TechnicalProcessProfile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TechnicalProcessProfile list = 1;
 * @return {!Array<!proto.cyberia.grpc.process.TechnicalProcessProfile>}
 */
proto.cyberia.grpc.process.TechnicalProcessProfiles.prototype.getListList = function() {
  return /** @type{!Array<!proto.cyberia.grpc.process.TechnicalProcessProfile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cyberia.grpc.process.TechnicalProcessProfile, 1));
};


/**
 * @param {!Array<!proto.cyberia.grpc.process.TechnicalProcessProfile>} value
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfiles} returns this
*/
proto.cyberia.grpc.process.TechnicalProcessProfiles.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cyberia.grpc.process.TechnicalProcessProfile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfile}
 */
proto.cyberia.grpc.process.TechnicalProcessProfiles.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cyberia.grpc.process.TechnicalProcessProfile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cyberia.grpc.process.TechnicalProcessProfiles} returns this
 */
proto.cyberia.grpc.process.TechnicalProcessProfiles.prototype.clearListList = function() {
  return this.setListList([]);
};


goog.object.extend(exports, proto.cyberia.grpc.process);
