import { Route, Routes } from 'react-router-dom';
import { loginUrl, recoveryUrl, registrationUrl, errorUrl, settingsUrl } from 'shared';
import { ErrorPage } from './error';
import { LoginPage } from './login';
import { RecoveryPage } from './recovery';
import { RegistrationPage } from './registration';
import { SettingsPage } from './settings';

export const Routing = () => {
	return (
		<Routes>
			<Route path={loginUrl} element={<LoginPage/>}/>
			<Route path={registrationUrl} element={<RegistrationPage/>}/>
			<Route path={recoveryUrl} element={<RecoveryPage/>}/>
			<Route path={errorUrl} element={<ErrorPage/>}/>
			<Route path={settingsUrl} element={<SettingsPage/>}/>
		</Routes>
	);
};